import { render, staticRenderFns } from "./comp_ShareProduct.vue?vue&type=template&id=62927e31&scoped=true&"
import script from "./comp_ShareProduct.vue?vue&type=script&lang=js&"
export * from "./comp_ShareProduct.vue?vue&type=script&lang=js&"
import style0 from "./comp_ShareProduct.vue?vue&type=style&index=0&id=62927e31&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62927e31",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VIcon,VList,VMenu})
