<script>
export default {
	data() {
		return {
			eventIcon: this.$srv('collectionEventIcon', []),
		}
	},
	props: {
		product: Object,
		multiple: {
			type: Boolean,
			default: false,
		},
		imgWidth: {
			type: String,
			default: '40px',
		},
		limitAmount: Number,
	},
	computed: {
		eventIcons() {
			return this.eventIcon?.filter((icon) =>
				this.product.collectionsIds.includes(Number(icon.collectionId))
			)
		},
		hasEventIcon() {
			return !!this.eventIcons?.length
		},
		icon() {
			return this.eventIcons[0].image
		},
		allIcons() {
			if (this.limitAmount) {
				return this.eventIcons.slice(0, Number(this.limitAmount))
			} else return this.eventIcons
		},
	},
}
</script>

<template>
	<div v-if="hasEventIcon">
		<div v-if="multiple" class="d-flex">
			<Media
				v-for="(i, n) in allIcons"
				:key="n"
				:src="i.image"
				as="img"
				style="width: auto; height: 40px"
				class="mr-3"
			/>
		</div>
		<Media v-else :src="icon" as="img" :width="imgWidth" class="mr-3" />
	</div>
</template>
