<template>
	<div class="d-flex justify-space-around align-end gap">
		<PriceText
			v-if="opt.discount"
			:amount="opt.cost"
			:currency="currency"
			style="text-decoration: line-through"
		/>
		<PriceText v-if="opt.total !== 0" :amount="opt.total" :currency="currency" />
		<div v-else class="font-1 font-weight-bold">Gratis</div>
	</div>
</template>

<script>
export default {
	props: {
		opt: Object,
		currency: String,
	},
}
</script>

<style scoped lang="scss">
.gap {
	gap: 10px;
}
</style>
