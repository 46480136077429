<script>
export default {
	props: {
		data: Object,
	},
	computed: {
		devicePasses() {
			const device = this.data?.device
			return device === 'x' || this.$b[device]
		},
	},
	render() {
		return this.devicePasses ? this.$slots.default : null
	},
}
</script>
