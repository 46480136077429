<script>
export default {
	props: {
		products: Array,
		listId: String,
		offer: Object,
		loop: { type: Boolean, default: false },
		dotContainerFullWidthMobile: { type: Boolean, default: false },
		arrowsBgHalfRounded: { type: Boolean, default: false },
	},
	mounted() {
		if (this.products.length && this.listId) {
			this.$eventer().trigger('product-list:view', {
				products: this.products,
				listId: this.listId,
			})
		}
	},
}
</script>

<template>
	<Carousel
		:dotContainerFullWidthMobile="dotContainerFullWidthMobile"
		class="products-carousel"
		:loop="loop"
		:showDots="$b.mt"
		:arrowsBgHalfRounded="arrowsBgHalfRounded"
		v-bind="{
			...$b.props({
				slidesPerPage: { m: 1, t: 3, d: 4 },
				showArrows: { d: true },
				center: { m: true },
			}),
			...this.$attrs,
		}"
		:id="listId"
	>
		<div class="d-flex justify-center" v-for="(product, i) of products" :key="i">
			<ProductCard :product="product" :list-id="listId" :list-index="i" :offer="offer" />
		</div>
	</Carousel>
</template>
