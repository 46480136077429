<script>
export default {
	props: {
		scaleDown: {
			type: Boolean,
			default: true,
		},
		indexValue: Number,
		product: Object,
	},
	data() {
		return {
			showDialog: false,
			dragging: false,
			currentIndex: this.indexValue || 0,
			maxProductItems: 6,
			// carouselHeight: 0,
		}
	},
	model: {
		prop: 'indexValue',
		event: 'updateIndexValue',
	},
	watch: {
		currentIndex(value) {
			this.$emit('updateIndexValue', value)
		},
		indexValue(value) {
			this.currentIndex = value
		},
	},
	methods: {
		moveTo(index, duration) {
			//this.$refs.carousel.moveTo(index, duration)
		},
		onLastImageClick(i) {
			this.currentIndex = i
			this.showDialog = true
		},
	},
	computed: {
		productItemsLeft() {
			return this.product.images.length - this.maxProductItems
		},
	},
}
</script>

<template>
	<div :class="$bem('product-images-carousel')">
		<div class="d-flex">
			<div :class="$bem('__thumbs-cont', 'flex-shrink-1 mr-4')" v-if="$b.td">
				<div
					v-for="(image, i) of product.images.slice(0, maxProductItems)"
					:key="i"
					class="pb-3"
					@mouseover="currentIndex = i"
				>
					<Media
						:src="image.thumb"
						:class="{
							[$bem('__thumb', 'rounded-lg')]: true,
							[$bem('__thumb--active')]: currentIndex == i,
						}"
						img-class="rounded-lg"
						aspect-ratio="1"
						loader
					>
						<div
							v-if="product.images.length > maxProductItems && i === maxProductItems - 1"
							style="height: 100%; width: 100%; background-color: rgba(0, 0, 0, 0.6)"
							class="d-flex justify-center align-center rounded"
							@click="onLastImageClick(i)"
						>
							<span style="color: white" class="font-10">+{{ productItemsLeft }}</span>
						</div>
					</Media>
				</div>
			</div>
			<div :class="$bem('__carousel-cont')" class="flex-grow-1 pa-sm-4">
				<Carousel
					v-model="currentIndex"
					:class="$bem('__carousel')"
					v-bind="
						$b.props({
							center: true,
							showDots: { m: true },
						})
					"
					@drag:start="dragging = true"
					@press="dragging = false"
					@release="showDialog = !dragging"
				>
					<div v-for="(image, i) of product.images" :key="i">
						<Media
							:src="image.medium"
							:class="$bem('__image')"
							:scale-down="scaleDown"
							width="100%"
							aspect-ratio="1"
							loader
							@click="product.images.length == 1 ? (showDialog = true) : null"
						/>
					</div>
				</Carousel>
			</div>
		</div>
		<ProductImagesDialog v-model="currentIndex" :product="product" :show-dialog.sync="showDialog" />
	</div>
</template>

<style scoped lang="scss">
.ssr-carousel ::v-deep .ssr-carousel-track {
	justify-content: center;
}
.product-images-carousel {
	&__thumb {
		cursor: pointer;
		border: 1px solid #eee;
		padding: 3px;
		&--active {
			border: 2px solid #00001d;
			padding: 2px;
		}
	}
}
</style>
