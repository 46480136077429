<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],

	hooksKey: 'ProductCard',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div
		class="product-card-shadow-small-wrapper h100"
		:class="{ 'product-card-shadow-small-wrapper-mobile': $b.mt }"
		v-if="verticalCard"
	>
		<v-hover v-slot="{ hover }">
			<v-card
				:hover="!outOfStock ? true : false"
				@click="cardClick"
				@mouseover="firstImageHover = true"
				:ripple="false"
				:class="$bem('product-card-shadow-small')"
				class="h100 d-flex flex-column base rounded-md overflow-hidden justify-space-between product-card"
			>
				<div :class="$bem('__image-cont')" v-if="!outOfStock">
					<hook zone="image">
						<Media
							cover
							:class="$bem('__main-image')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1"
							:imgClass="{
								'product-card-shadow-small__image--smallWidth': $b.d,
								'product-card-shadow-small__image--smallWidth-mobile': $b.mt,
							}"
						/>
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont')">
							<Media
								cover
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
								:imgClass="{
									'product-card-shadow-small__image--smallWidth': $b.d,
									'product-card-shadow-small__image--smallWidth-mobile': $b.mt,
								}"
							/>
						</div>
					</hook>

					<hook zone="offer">
						<LimitedTimeOffer :product="product" :class="$bem('__offer')" />
					</hook>
					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
				</div>

				<div :class="$bem('__image-cont', 'no-stock')" v-else>
					<hook zone="image">
						<Media
							:class="$bem('__main-image')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1"
							:imgClass="{
								'product-card-shadow-small__image--smallWidth': $b.d,
								'product-card-shadow-small__image--smallWidth-mobile': $b.mt,
							}"
						/>
					</hook>
					<hook zone="offer">
						<LimitedTimeOffer :product="product" :class="$bem('__offer')" />
					</hook>
					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
				</div>

				<hook zone="prevPrice" v-if="discountPct" :class="{ 'no-stock': outOfStock }">
					<del class="grey--text">
						<PriceText
							:amount="prevPrice"
							:currency="currency"
							:currencyMargin="0"
							:class="$bem('__prev-price')"
							class="grey--text mr-2 font-0 px-3 mt-2"
							priceClass="grey--text"
						/>
					</del>
				</hook>

				<div class="d-flex align-center px-3" :class="{ 'mt-4': !discountPct, 'no-stock': outOfStock }">
					<hook zone="price">
						<PriceText
							:amount="price"
							:currency="currency"
							:class="$bem('__price')"
							class="price font-2 font-weight-bold"
						/>
					</hook>

					<hook zone="discount" v-if="discountPct > 0">
						<span :class="$bem('__discount')" class="discount font-weight-medium px-2 py-1">
							{{ discountPct }}% OFF
						</span>
					</hook>
				</div>
				<v-spacer />

				<hook zone="attrs&financingTags" v-if="financingTagText" :class="{ 'no-stock': outOfStock }">
					<div :class="$bem('__financing')" class="font-0 line-clamp-1 px-3 font-weight-bold primary">
						{{ financingTagText }}
					</div>
				</hook>

				<v-spacer v-if="financingTagText" />

				<router-link class="card-anchor header" :to="productRoute">
					<hook zone="name" :class="{ 'no-stock': outOfStock }">
						<div
							:class="[$bem('__name'), { 'mb-2': !brand }]"
							class="line-clamp-2 font-1 font-weight-bold px-3 mt-3"
						>
							{{ product.name }}
						</div>
					</hook>
				</router-link>

				<hook zone="brand" v-if="brand" :class="{ 'no-stock': outOfStock }">
					<div :class="$bem('__brand')" class="font-0 line-clamp-1 px-3 mb-2">
						{{ brand }}
					</div>
				</hook>
				<hook zone="end" />
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD -->
	<div class="w100" v-else>
		<v-hover v-slot="{ hover }">
			<v-card
				@mouseover="firstImageHover = true"
				:hover="!outOfStock ? true : false"
				outlined
				@click="cardClick"
				:ripple="false"
				:class="$bem('product-card-shadow-small-horizontal')"
				class="d-flex overflow-hidden base product-card py-2"
			>
				<div v-if="!outOfStock" :class="$bem('__image-cont', 'col col-5 pa-0')">
					<hook zone="imageHorizontal" class="h100">
						<Media
							:class="$bem('__main-image', 'px-1 h100')"
							:src="mainImage"
							width="100%"
							imgClass="product-card-shadow-small-horizontal__image--smallWidth"
						/>
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont', 'px-1')">
							<Media
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '', 'h100']"
								imgClass="product-card-shadow-small-horizontal__image--smallWidth"
							/>
						</div>
					</hook>
					<hook zone="offerHorizontal">
						<LimitedTimeOffer
							:product="product"
							v-if="hasLimitedTimeOffer"
							:class="[$bem('__offer'), { 'pr-2': hasEventIcon }]"
							:textAlign="hasEventIcon ? 'end' : 'center'"
						/>
					</hook>
					<hook zone="event-iconHorizontal">
						<CollectionEventIcon
							:product="product"
							:class="[
								$bem('__event-icon'),
								{ 'product-card-shadow-small-horizontal__event-icon--w25': hasLimitedTimeOffer },
							]"
							:imgWidth="hasLimitedTimeOffer ? '25px' : '30px'"
						/>
					</hook>
				</div>

				<div
					:class="$bem('__image-cont', 'no-stock col col-5 pa-0 d-flex flex-column justify-center')"
					v-else
				>
					<hook zone="imageHorizontal">
						<Media
							:class="$bem('__main-image', 'px-1')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1"
							imgClass="product-card-shadow-small-horizontal__image--smallWidth"
						/>
					</hook>
					<hook zone="offerHorizontal">
						<LimitedTimeOffer
							:product="product"
							v-if="hasLimitedTimeOffer"
							:class="[$bem('__offer'), { 'pr-2': hasEventIcon }]"
							:textAlign="hasEventIcon ? 'end' : 'center'"
						/>
					</hook>
					<hook zone="event-iconHorizontal">
						<CollectionEventIcon
							:product="product"
							:class="[
								$bem('__event-icon'),
								{ 'product-card-shadow-small-horizontal__event-icon--w25': hasLimitedTimeOffer },
							]"
							:imgWidth="hasLimitedTimeOffer ? '25px' : '30px'"
						/>
					</hook>
				</div>

				<div
					class="col col-7 py-0 pl-1 d-flex flex-column justify-center"
					:class="{ 'no-stock': outOfStock }"
				>
					<hook zone="prevPriceHorizontal" v-if="discountPct">
						<del class="grey--text">
							<PriceText
								:amount="prevPrice"
								:currency="currency"
								:currencyMargin="0"
								:class="$bem('__prev-price', 'px-2')"
								priceClass="grey--text font-0"
							/>
						</del>
					</hook>

					<hook zone="priceHorizontal">
						<PriceText
							:amount="price"
							:currency="currency"
							:class="[$bem('__price'), 'px-2']"
							class="price font-4 line-clamp-1"
						/>
					</hook>

					<hook zone="discountHorizontal" v-if="discountPct > 0">
						<span
							:class="$bem('__discount')"
							class="d-inline-block font-weight-medium discount px-2"
							v-if="discountPct > 0"
						>
							{{ discountPct }}% OFF
						</span>
					</hook>

					<hook
						zone="attrs&financingTagsHorizontal"
						v-if="financingTagText"
						:class="{ 'no-stock': outOfStock }"
					>
						<div :class="$bem('__financing', 'font-0 line-clamp-1 mx-2 font-weight-bold primary')">
							{{ financingTagText }}
						</div>
					</hook>

					<router-link class="card-anchor header" :to="productRoute">
						<hook zone="nameHorizontal" :class="{ 'no-stock': outOfStock }">
							<div :class="$bem('__name')" class="line-clamp-2 px-2 header font-weight-regular font-2">
								{{ product.name }}
							</div>
						</hook>
					</router-link>
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF HORIZONTAL CARD -->
</template>

<style lang="scss" scoped>
.product-card-shadow-small-wrapper {
	min-height: 270px;
	width: 180px;
	min-width: 100px;
}

.product-card-shadow-small-wrapper-mobile {
	width: 170px;
}

.product-card-shadow-small {
	position: relative;
	cursor: pointer;
	text-align: left;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__offer {
		position: absolute;
		bottom: 0;
		z-index: 1;
	}

	&__event-icon {
		position: absolute;
		bottom: 5%;
		left: 6px;
		width: 25px;
		z-index: 1;
	}

	&__name {
		line-height: 1.3rem !important;
	}

	&__prev-price {
		line-height: normal;
	}

	&__image-cont {
		border-bottom: 1px solid rgb(170, 170, 170);
	}

	&__brand {
		line-height: 1rem;
	}

	&__discount {
		font-size: 0.9rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}
}

.product-card-shadow-small-horizontal {
	min-height: 170px;

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__main-image {
		left: 50%;
		transform: translateX(-50%);
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__event-icon {
		position: absolute;
		bottom: 0px;
		left: 6px;
		width: 30px;
		z-index: 2;

		&--w25 {
			width: 25px;
		}
	}

	&__offer {
		position: absolute;
		bottom: 0;
		z-index: 1;
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__discount {
		font-size: 0.9rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}

	&__name {
		line-height: 1.7rem !important;
	}
}

::v-deep .product-card-shadow-small__image--smallWidth {
	max-width: 180px;
	max-height: 180px;
	left: 50% !important;
	transform: translateX(-50%) !important;
}

::v-deep .product-card-shadow-small__image--smallWidth-mobile {
	max-width: 170px;
	max-height: 170px;
	left: 50% !important;
	transform: translateX(-50%) !important;
}

::v-deep div.product-card-shadow-small-horizontal__image--smallWidth {
	width: 80%;
	left: 50%;
	transform: translateX(-50%);
	height: auto;
}

.no-stock {
	opacity: 0.4;
}
a.card-anchor:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 2;
	left: 0;
}
</style>
