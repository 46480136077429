<template>
	<div>
		<div class="font-0" style="max-width: 400px" v-if="hasShippingOptions">
			<!-- Default Shipping -->
			<div class="d-flex justify-space-between my-2 pa-2 bordered" v-if="hasShipping">
				<div class="grey--text">
					{{ defaultShipping.methodName }}<a @click="doShowOptions('shipping')">{{ ' Ver Opciones' }}</a>
				</div>
				<ProductShipping-Price
					v-if="defaultShipping.hasCost"
					:opt="defaultShipping"
					:currency="currency"
				/>
			</div>

			<!-- Default Pickup -->
			<div class="d-flex justify-space-between my-2 pa-2 bordered" v-if="hasPickup">
				<div class="grey--text">
					{{ defaultPickupOption.methodName || 'Entrega' }} {{ defaultPickupOption.carrierName }}
					<a @click="doShowOptions('pickupPoint')">{{ ' Ver Opciones' }}</a>
				</div>
				<ProductShipping-Price
					v-if="defaultPickupOption.hasCost"
					:opt="defaultPickupOption"
					:currency="currency"
				/>
			</div>
		</div>

		<!-- No Options -->
		<div v-else class="my-2">No encontramos opciones de envío para ese código postal</div>

		<!-- Options Dialog -->
		<v-dialog v-model="showOptions" width="500">
			<v-card>
				<v-card-title> {{ optionTitle }} </v-card-title>

				<v-card-text>
					<div v-for="option in selectedOptions" :key="option.hash" class="my-2 pa-2 bordered">
						<div class="d-flex justify-space-between">
							<div class="primary--text font-1">{{ option.optionName }}</div>
							<ProductShipping-Price v-if="option.hasCost" :opt="option" :currency="currency" />
						</div>
						<div>{{ option.message }}</div>
						<div>{{ option.etaMessage }}</div>
						<div>
							<ProductShippingOptions v-if="Object.keys(option.data).length" :option="option" />
						</div>
					</div>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="base" text @click="showOptions = false"> cerrar </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: {
		shippingOptions: Object,
		currency: String,
	},
	data() {
		return {
			showOptions: false,
			optionType: null, // pickupPoint or shipping
		}
	},
	methods: {
		doShowOptions(type) {
			this.optionType = type
			this.showOptions = true
		},
	},
	computed: {
		pickupPoint() {
			return this.shippingOptions.pickupPoint
		},
		shipping() {
			return this.shippingOptions.shipping
		},
		selectedOptions() {
			return this[this.optionType]
		},
		defaultShipping() {
			return this.shipping[0]
		},
		/*
		 * Return the option with the lowest cost
		 */
		defaultPickupOption() {
			if (this.pickupPoint.length === 1) return this.pickupPoint[0]
			return this.pickupPoint.reduce((prev, curr) => (prev.total < curr.total ? prev : curr))
		},
		hasManyPickupOptions() {
			return this.pickupPoint.length > 1
		},
		hasManyShippingOptions() {
			return this.shipping.length > 1
		},
		hasShipping() {
			return this.shipping.length > 0
		},
		hasPickup() {
			return this.pickupPoint.length > 0
		},
		hasShippingOptions() {
			return this.shipping.length > 0 || this.pickupPoint.length > 0
		},
		optionTitle() {
			return this.optionType === 'shipping' ? 'Envíos' : 'Puntos de entrega'
		},
	},
}
</script>

<style lang="scss" scoped>
.bordered {
	border: 1px solid #eee;
}
</style>
