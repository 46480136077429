<script>
import SmartPopupBase from '#/v-shop/vue-src/v-shop/common/comp_SmartPopupCenter.vue'

export default {
	extends: SmartPopupBase,
	computed: {
		style() {
			return {
				'background-image': `url(${
					this.$media(
						this.$b({ d: this.popupConfig.bgImageBottom, m: this.popupConfig.bgImageBottomMobile })
					).src
				})!important`,
				'background-position': 'center center!important',
				'background-size': 'cover!important',
			}
		},
		styleFullBg() {
			return {
				'background-image': `url(${
					this.$media(
						this.$b({ d: this.popupConfig.bgImageBottom, m: this.popupConfig.bgImageBottomMobile })
					).src
				})!important`,
				'background-position': 'center center!important',
				'background-size': 'cover!important',
			}
		},
	},
}
</script>

<template>
	<transition name="fade">
		<div
			id="smart-popup"
			class="smart-popup fixed-bottom d-flex flex-wrap justify-center text-left"
			:class="storageKey"
			v-if="showTemplate && show"
		>
			<div v-if="!popupConfig.useBgFullImage" class="py-8 px-10 base relative" :style="style">
				<Button class="close" :color="$vars.secondary" icon @click="show = false">
					<v-icon>mdi-close</v-icon>
				</Button>
				<v-row wrap justify="center" class="base--text">
					<v-col class="py-0" cols="12" :sm="popupConfig.enableCta && 'auto'">
						<h1 :class="$b.m ? 'font-5' : 'font-12'" class="header">{{ popupConfig.title }}</h1>
						<p class="font-1">{{ popupConfig.text }}</p>
					</v-col>
					<v-col class="py-0 w100" style="max-width: 490px" cols="12" sm="auto">
						<SmartPopup-Form
							v-if="popupConfig.enableCta"
							:popupConfig="popupConfig"
							@ctaClick="ctaClick"
						/>
					</v-col>
				</v-row>
			</div>
			<div v-else class="d-flex justify-center align-baseline">
				<div style="position: relative">
					<Button class="close" style="right: 0" :color="$vars.secondary" icon @click="show = false">
						<v-icon>mdi-close</v-icon>
					</Button>
					<SafeLink :to="popupConfig.ctaUrl">
						<img
							:src="
								$media(
									$b({ d: popupConfig.bgFullImageBottom, m: popupConfig.bgFullImageBottomMobile })
								).src
							"
							class="responsive"
							:alt="
								$media(
									$b({ d: popupConfig.bgFullImageBottom, m: popupConfig.bgFullImageBottomMobile })
								).alt
							"
							@click="ctaClick"
						/>
						<!-- <Media
							width="100%"
							:src="$b({ d: popupConfig.bgFullImageBottom, m: popupConfig.bgFullImageBottomMobile })"
						>
						</Media> -->
					</SafeLink>
				</div>
			</div>

			<!--			<div v-else :style="styleFullBg" style="position: relative; height: 100px; width: 100%">-->
			<!--				<Button class="close" style="right: 0px" :color="$vars.secondary" icon @click="show = false">-->
			<!--					<v-icon>mdi-close</v-icon>-->
			<!--				</Button>-->
			<!--				<SafeLink :to="popupConfig.bgFullImageLink" />-->
			<!--			</div>-->
		</div>
	</transition>
</template>

<style scoped>
.relative {
	position: relative;
}

.responsive {
	height: auto;
	width: 100%;
}

::v-deep .v-label,
.v-input ::v-deep input {
	color: var(--primarytext);
}

.smart-popup {
	z-index: 1000;
}

.fade-enter-active,
.fade-leave-active {
	transition: all 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	transform: translateY(40px);
	opacity: 0;
}

.fixed-bottom {
	position: fixed;
	right: 0;
	left: 0;
	bottom: -6px;
}

.close {
	position: absolute;
	top: calc(50% - 15px);
	right: 10px;
	font-size: 24px;
	z-index: 999;
}
</style>
