var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.megaMenuCategories.length)?_c('div',{ref:"megaMenuHorizontal",staticClass:"appbar__mega-menu d-flex"},_vm._l((_vm.megaMenuCategories),function(item,index){return _c('div',{key:index,staticClass:"mega-menu px-0 d-flex",attrs:{"min-width":"200px"}},[_c('Hover',{attrs:{"unhover-on-route-loading":"","open-delay":_vm.$b.d ? 250 : 0,"close-delay":_vm.$b.d ? 250 : 0,"value":_vm.globalHover},on:{"updateModel":function($event){return _vm.updateHover($event, index)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
var toggle = ref.toggle;
return [_c('div',[_c('SafeLink',{staticClass:"d-inline-block pt-3"},[_c('v-btn',{staticClass:"h100 underline",class:{ 'underline--permanent': hover || _vm.forceHover == index },attrs:{"color":_vm.$vars.basetext,"text":"","depressed":"","small":""},on:{"click":function($event){return _vm.doToggle(hover, toggle)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.category.name)+" ")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.close && (hover || _vm.forceHover === index)),expression:"!close && (hover || forceHover === index)"}],staticClass:"mega-menu__submenu px-6 py-1 pb-6 rounded",class:[_vm.submenuClass],style:([_vm.bgImageStyle(item), { 'max-height': (_vm.cMaxHeight + "px!important") }, _vm.widthStyle])},[_c('CategoriesMegaMenu-CategoryTitle',{attrs:{"item":item},nativeOn:{"click":function($event){return toggle.apply(null, arguments)}}}),_c('v-row',{staticClass:"pl-4 pt-0",attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"pt-0",attrs:{"id":"menu"}},[_vm._l((item.links),function(child,i){return _c('div',{key:i,staticClass:"category mega-menu__children mb-4 pr-2"},[_c('div',[_c('router-link',{staticClass:"font-1 font-weight-bold pb-1 link",style:(_vm.linkTextStyle),attrs:{"to":child.link}},[_c('div',{staticClass:"ellipsis-text"},[_vm._v(_vm._s(child.name))])])],1),_c('div',{staticClass:"subcategories"},_vm._l((_vm.subChild(child)),function(subChild,i){return _c('div',{key:i},[_c('router-link',{staticClass:"subchild-font",style:(_vm.linkTextStyle),attrs:{"to":subChild.link}},[_c('div',[_vm._v(_vm._s(subChild.name))])])],1)}),0),_c('div',[(_vm.hasManySubChildren(child))?_c('router-link',{staticClass:"subchild-font font-weight-bold link",style:(_vm.linkTextStyle),attrs:{"to":_vm.$shop.getShopRoute({
													categories: [item.category.urlName, child.urlName],
												})}},[_c('span',[_vm._v("Ver más categorías >>")])]):_vm._e()],1)])}),_vm._l((item.category.children),function(child){return _c('div',{key:child.id,staticClass:"category mb-4 mega-menu__children pr-2"},[_c('div',[_c('router-link',{staticClass:"font-1 font-weight-bold pb-1 link",style:(_vm.linkTextStyle),attrs:{"to":_vm.$shop.getShopRoute({
													categories: [item.category.urlName, child.urlName],
												})}},[_c('div',{staticClass:"ellipsis-text"},[_vm._v(_vm._s(child.name))])])],1),_c('div',{staticClass:"subcategories"},_vm._l((_vm.subChild(child)),function(subChild,i){return _c('router-link',{key:i,staticClass:"subchild-font link",style:(_vm.linkTextStyle),attrs:{"to":_vm.$shop.getShopRoute({
													categories: [
														item.category.urlName,
														child.urlName,
														subChild.urlName ],
												})}},[_c('div',[_vm._v(_vm._s(subChild.name))])])}),1),_c('div',[(_vm.hasManySubChildren(child))?_c('router-link',{staticClass:"subchild-font mt-2 font-weight-bold link",style:(_vm.linkTextStyle),attrs:{"to":_vm.$shop.getShopRoute({
													categories: [item.category.urlName, child.urlName],
												})}},[_c('span',[_vm._v("Ver más categorías >>")])]):_vm._e()],1)])}),_c('v-col',[_c('SafeLink',{attrs:{"to":item.link}},[_c('div',{staticClass:"clickable-img"})])],1)],2)]),_c('v-col',[_c('SafeLink',{attrs:{"to":item.link}},[_c('div',{staticClass:"clickable-img"})])],1)],1)],1)],1)]}}],null,true)})],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }