<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],

	hooksKey: 'ProductCard',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<div
		class="h100"
		:class="['product-card-left-aligned-sq-wrapper', { 'product-card-sq-wrapper-mobile': $b.mt }]"
	>
		<v-hover v-slot="{ hover }">
			<v-card
				@mouseover="firstImageHover = true"
				:hover="!outOfStock ? true : false"
				outlined
				@click="cardClick"
				:ripple="false"
				:class="$bem('product-card-left-aligned-sq')"
				class="h100 d-flex flex-column overflow-hidden justify-space-between base product-card"
			>
				<div :class="$bem('__image-cont mx-2')" v-if="!outOfStock">
					<hook zone="image">
						<Media
							cover
							:class="$bem('__main-image')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1.5"
							imgClass="product-card-left-aligned-sq__image--smallWidth"
						/>
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont')">
							<Media
								cover
								:src="secondImage"
								width="100%"
								aspect-ratio="1.5"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
								imgClass="product-card-left-aligned-sq__image--smallWidth"
							/>
						</div>
					</hook>
					<CollectionEventIcon
						:product="product"
						:class="[$bem('__event-icon'), { 'product-card-sq__event-icon-mobile': $b.mt }]"
						imgWidth="25px"
					/>
				</div>

				<div :class="$bem('__image-cont mx-2', 'no-stock')" v-else>
					<hook zone="image">
						<Media
							:class="$bem('__main-image')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1.5"
							imgClass="product-card-left-aligned-sq__image--smallWidth"
						/>
					</hook>
					<CollectionEventIcon
						:product="product"
						:class="[$bem('__event-icon'), { 'product-card-sq__event-icon-mobile': $b.mt }]"
						imgWidth="25px"
					/>
				</div>

				<hook zone="price" :class="{ 'no-stock': outOfStock }">
					<PriceText
						:amount="price"
						:currency="currency"
						:class="[$bem('__price'), { 'product-card-sq__price-mobile': $b.mt }]"
						class="price font-2 font-weight-bold line-clamp-1 mt-1 px-2"
					/>
				</hook>

				<div
					class="d-flex align-baseline px-2"
					v-if="prevPrice"
					:class="[
						$bem('__prev-price'),
						{ 'no-stock': outOfStock },
						{ 'product-card-sq__prev-prices-mobile': $b.mt },
					]"
				>
					<hook zone="prevPrice">
						<del class="grey--text">
							<PriceText
								:amount="prevPrice"
								:currency="currency"
								:currencyMargin="0"
								:class="{ 'product-card-sq__prev-price-mobile': $b.mt }"
								priceClass="grey--text font-0"
							/>
						</del>
					</hook>

					<hook zone="discount">
						<span
							v-if="discountPct > 0"
							:class="[$bem('__discount'), { 'product-card-sq__discount-mobile': $b.mt }]"
							class="d-inline-block discount font-0 ml-2"
						>
							{{ discountPct }}% OFF
						</span>
					</hook>
				</div>

				<v-spacer :class="{ 'product-card-left-aligned-sq__spacer--flex-grow': !discountPct }" />

				<router-link class="card-anchor header" :to="productRoute">
					<hook zone="name" :class="{ 'no-stock': outOfStock }">
						<div
							:class="[$bem('__name'), { 'product-card-sq__name-mobile': $b.mt }]"
							class="line-clamp-2 header mb-2 px-2 font-weight-regular font-1"
						>
							{{ product.name }}
						</div>
					</hook>
				</router-link>
			</v-card>
		</v-hover>
	</div>
</template>

<style lang="scss" scoped>
.product-card-left-aligned-sq-wrapper {
	height: 240px;
	width: 224px;
}

.product-card-left-aligned-sq {
	position: relative;
	cursor: pointer;
	border: 1px solid #eaeaea;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__event-icon {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 25px;
	}

	&__spacer--flex-grow {
		flex-grow: 0.75 !important;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}
}

.no-stock {
	opacity: 0.4;
}

a.card-anchor:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 2;
	left: 0;
}

::v-deep .product-card-left-aligned-sq__image--smallWidth {
	max-width: 140px;
	max-height: 180px;
	left: 50% !important;
	translate: -50%;
}
</style>
