<script>
import BaseProductActions from './comp_ProductActions.vue'
export default {
	lang: 'shop',
	extends: BaseProductActions,
	data() {
		return {
			initialProd: null,
			nextProd: null,
		}
	},
	mounted() {
		this.initialProd = this.product.id
	},
	updated() {
		if (this.initialProd != this.product.id) {
			if (this.nextProd != this.product.id) {
				this.initialProd = null
				this.nextProd = this.product.id
				this.qty = 1
			} else return this.qty

			this.qty = 1
		} else return this.qty
	},
}
</script>

<template>
	<div class="d-flex mt-6" v-if="$b.d">
		<div>
			<QtyInput
				v-if="variant.type == 'physical' && !outOfStock"
				v-model="qty"
				:max-qty="maxStockQty"
				:unit-metric="product.unitMetric"
				:pack-metric="product.packMetric"
				:step="variant.metricFactor"
			/>
			<ProductStockQtyMessage
				v-if="variant.type == 'physical'"
				:stock="variant.stock"
				:metric="product.unitMetric"
				class="pt-2 grey--text text-center"
				style="font-size: 0.9rem"
			/>
		</div>

		<Button
			class="py-5 mx-12 btn-square"
			color="cta"
			:disabled="outOfStock"
			:loading="ctaIsLoading"
			@click="ctaClicked"
			large
		>
			<v-icon class="font-0 mr-2">mdi-cart-outline</v-icon>
			<span class="font-2">{{ ctaText || $lang('Comprar ahora') }}</span>
		</Button>
	</div>

	<div v-else>
		<QtyInput
			v-if="variant.type == 'physical' && !outOfStock"
			v-model="qty"
			:max-qty="maxStockQty"
			:unit-metric="product.unitMetric"
			:pack-metric="product.packMetric"
			:step="variant.metricFactor"
		/>
		<ProductStockQtyMessage
			v-if="variant.type == 'physical'"
			:stock="variant.stock"
			:metric="product.unitMetric"
			class="pt-2 grey--text"
			style="font-size: 0.9rem"
		/>
		<div class="pt-4">
			<Button
				class="full-width py-5 btn-buy"
				color="cta"
				:disabled="outOfStock"
				:loading="ctaIsLoading"
				@click="ctaClicked"
				large
			>
				<v-icon class="font-0 mr-2">mdi-cart-outline</v-icon>
				<span class="font-2">{{ ctaText || $lang('Comprar ahora') }}</span>
			</Button>
		</div>
	</div>
</template>

<style scoped>
.btn-square {
	border-radius: 0;
}
</style>
