<template>
	<div class="product-page__shipment-calculator">
		<ShipmentCalculator
			v-if="showShipmentInput"
			:loading="loading"
			storePostalCode
			@calculate="calculate"
		/>
		<div v-if="!error && showShipmentInfo">
			<div class="d-flex justify-space-between align-center" style="max-width: 430px">
				<div class="font-1">Entregas a CP: {{ zipcode }}</div>
				<Button @click="changePostalCode" color="grey" class="white--text">Cambiar CP</Button>
			</div>
			<ProductShippingOptionsList :currency="currency" :shippingOptions="shippingOptions" />
		</div>
		<div v-if="error">Ocurrió un error, intentalo nuevamente</div>
	</div>
</template>

<script>
export default {
	props: {
		selectedVariantId: String,
		currency: String,
	},
	data() {
		return {
			loading: false,
			zipcode: null,
			showShipmentInfo: false,
			showShipmentInput: true,
			shippingOptions: null,
			error: false,
		}
	},
	methods: {
		changePostalCode() {
			this.showShipmentInfo = false
			this.showShipmentInput = true
		},
		calculate(zipcode) {
			if (zipcode <= 0) return

			this.error = false

			const data = {
				items: [{ refId: this.selectedVariantId, refType: 'product', qty: 1 }],
				zipcode,
			}
			this.zipcode = zipcode
			this.loading = true
			this.$shopApi.post({
				url: '/simulation/delivery/calculate',
				data,
				onSuccess: ({ data }) => {
					this.loading = false
					const { PickupPoint = [], Shipping = [] } = data.options || {}
					this.shippingOptions = { pickupPoint: PickupPoint, shipping: Shipping }
					this.showShipmentInput = false
					this.showShipmentInfo = true
				},
				onError: (error) => {
					this.loading = false
					this.error = true
				},
			})
		},
	},
	cssVars: {
		selector: '.product-page__shipment-calculator',
	},
}
</script>

<style></style>
