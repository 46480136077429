<script>
import LazyHydrate from 'vue-lazy-hydration'
export default {
	components: { LazyHydrate },
	data() {
		return {
			items: [],
			loading: false,
		}
	},
	mobileReload(context, to, from) {
		return true
	},
	async resolveData(context, to, from, next) {
		const { apis, store, ssr } = context
		const { shopApi } = apis

		try {
			const { data } = await shopApi.get('/home-data', {
				loading: (v) => store.set('shop/routeLoading', v),
			})
			return { items: data.items }
		} catch (err) {
			next(err)
		}
	},
	trackPageView: true,
}
</script>

<template>
	<v-progress-linear indeterminate light color="primary" v-if="loading" />
	<LazyHydrate when-idle v-else>
		<DynamicPageItems :items="items" css-vars-key="home" />
	</LazyHydrate>
</template>

<style></style>
