var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.showDialog,"persistent":"","dark":"","fullscreen":"","transition":"fade-transition"}},[_c('div',{staticClass:"d-flex align-center justify-center overlay"},[_c('Button',{staticClass:"place-up",attrs:{"absolute":"","top":"","right":"","icon":"","x-large":"","dark":""},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-close")])],1),(_vm.mountCarousel)?_c('Carousel',_vm._b({staticClass:"w100 h100",model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},'Carousel',
				_vm.$b.props({
					showArrows: { d: true },
					showDots: true,
					noDrag: { d: true },
					gutter: 0,
				})
			,false),_vm._l((_vm.product.images),function(image,i){return _c('div',{key:i,staticClass:"img w100 h100",class:{
					scale1: _vm.scale == 1,
					scale2: _vm.scale == 2,
					active: _vm.currentIndex == i,
					'py-8': _vm.$b.d,
					'pt-60': _vm.$b.m,
					'pt-80': _vm.$b.t,
				}},[_c('Media',{attrs:{"src":image.big,"height":"100%","width":"100%","scale-down":"","loader":""},on:{"click":function($event){return _vm.toggleScale()}}})],1)}),0):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }