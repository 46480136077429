var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$bem('product-images-carousel')},[_c('div',{staticClass:"d-flex"},[(_vm.$b.td)?_c('div',{class:_vm.$bem('__thumbs-cont', 'flex-shrink-1 mr-4')},_vm._l((_vm.product.images.slice(0, _vm.maxProductItems)),function(image,i){
var _obj;
return _c('div',{key:i,staticClass:"pb-3",on:{"mouseover":function($event){_vm.currentIndex = i}}},[_c('Media',{class:( _obj = {}, _obj[_vm.$bem('__thumb', 'rounded-lg')] = true, _obj[_vm.$bem('__thumb--active')] = _vm.currentIndex == i, _obj ),attrs:{"src":image.thumb,"img-class":"rounded-lg","aspect-ratio":"1","loader":""}},[(_vm.product.images.length > _vm.maxProductItems && i === _vm.maxProductItems - 1)?_c('div',{staticClass:"d-flex justify-center align-center rounded",staticStyle:{"height":"100%","width":"100%","background-color":"rgba(0, 0, 0, 0.6)"},on:{"click":function($event){return _vm.onLastImageClick(i)}}},[_c('span',{staticClass:"font-10",staticStyle:{"color":"white"}},[_vm._v("+"+_vm._s(_vm.productItemsLeft))])]):_vm._e()])],1)}),0):_vm._e(),_c('div',{staticClass:"flex-grow-1 pa-sm-4",class:_vm.$bem('__carousel-cont')},[_c('Carousel',_vm._b({class:_vm.$bem('__carousel'),on:{"drag:start":function($event){_vm.dragging = true},"press":function($event){_vm.dragging = false},"release":function($event){_vm.showDialog = !_vm.dragging}},model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},'Carousel',
					_vm.$b.props({
						center: true,
						showDots: { m: true },
					})
				,false),_vm._l((_vm.product.images),function(image,i){return _c('div',{key:i},[_c('Media',{class:_vm.$bem('__image'),attrs:{"src":image.medium,"scale-down":_vm.scaleDown,"width":"100%","aspect-ratio":"1","loader":""},on:{"click":function($event){_vm.product.images.length == 1 ? (_vm.showDialog = true) : null}}})],1)}),0)],1)]),_c('ProductImagesDialog',{attrs:{"product":_vm.product,"show-dialog":_vm.showDialog},on:{"update:showDialog":function($event){_vm.showDialog=$event},"update:show-dialog":function($event){_vm.showDialog=$event}},model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }