<script>
import scrollMixin from './scrollMixin'
import appbarHeightMixin from './appbarHeightMixin'

export default {
	mixins: [scrollMixin, appbarHeightMixin],
	metaInfo() {
		const categories = this.megaMenuCategories
		return {
			link: categories.map((item, i) => ({
				as: 'image',
				rel: 'preload',
				href: this.$media(item.img).src,
			})),
		}
	},
	props: {
		forceHover: Number,
		submenuStyle: Object,
		submenuClass: [String, Object],
		closeOnScroll: Boolean,
		overlay: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			megaMenuCategories: this.$srv('MegaMenuHorizontal', []),
			globalHover: false,
			hoverSubmenu: false,
			close: false,
			visibilityRef: 'megaMenuHorizontal',
			appbarPosition: this.$srv('AppBarConfig.position', 'center'),
		}
	},
	model: {
		prop: 'forceHover',
		event: 'updateForceHover',
	},
	watch: {
		globalHover(val) {
			this.$emit('updateForceHover', val)
		},
	},
	methods: {
		compareFn(elementRect) {
			return elementRect.top > -56 && elementRect.bottom <= window.innerHeight
		},
		onNotVisibleCb() {
			this.close = true
		},
		bgImageStyle(item) {
			if (!item.img) return null
			let { src } = this.$media(item.img)
			const styles = {
				backgroundImage: `url('${src}')`,
				backgroundPosition: 'right bottom',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'contain',
			}
			return styles
		},
		updateHover(event, index) {
			if (this.closeOnScroll) this.close = !event
			this.globalHover = index
		},
		doToggle(hover, toggle) {
			if (this.$b.t && hover) toggle()
		},
	},
	computed: {
		cMaxWidth() {
			const offset = 90
			const width = window.innerWidth - offset
			return width
		},
		widthStyle() {
			if (this.$ssr) return

			const offset = 100
			const width = window.innerWidth - offset

			if (this.$srv('AppBarConfig.type') === 'round') {
				return {
					width: '100%',
					'max-width': '1200px',
					left: '50%',
					marginLeft: '-600px',
				}
			}

			return {
				width: `${width}px`,
				'margin-left': `${width / -2}px`,
				left: '50%',
			}
		},
		appbarMenuOffset() {
			return this.$srv('AppBarConfig.type') === 'regular' ? 10 : 80
		},
		subChild() {
			return (child, n = 4) => child.children?.slice(0, n)
		},
		hasManySubChildren() {
			return (child, n = 4) => child.children?.length > n
		},
		linkTextStyle() {
			return `color: ${this.$vars.linktext}!important`
		},
	},
	cssVars: {
		selector: '.appbar__mega-menu',
	},
}
</script>

<template>
	<div ref="megaMenuHorizontal" class="appbar__mega-menu d-flex" v-if="megaMenuCategories.length">
		<div
			v-for="(item, index) of megaMenuCategories"
			:key="index"
			class="mega-menu px-0 d-flex"
			min-width="200px"
		>
			<Hover
				v-slot="{ hover, toggle }"
				unhover-on-route-loading
				:open-delay="$b.d ? 250 : 0"
				:close-delay="$b.d ? 250 : 0"
				:value="globalHover"
				@updateModel="updateHover($event, index)"
			>
				<div>
					<SafeLink class="d-inline-block pt-3">
						<v-btn
							:color="$vars.basetext"
							class="h100 underline"
							:class="{ 'underline--permanent': hover || forceHover == index }"
							text
							depressed
							small
							@click="doToggle(hover, toggle)"
						>
							<v-list-item-title> {{ item.category.name }} </v-list-item-title>
						</v-btn>
					</SafeLink>
					<div
						class="mega-menu__submenu px-6 py-1 pb-6 rounded"
						:class="[submenuClass]"
						:style="[bgImageStyle(item), { 'max-height': `${cMaxHeight}px!important` }, widthStyle]"
						v-show="!close && (hover || forceHover === index)"
					>
						<!-- Title -->
						<CategoriesMegaMenu-CategoryTitle :item="item" @click.native="toggle" />

						<v-row justify="start" class="pl-4 pt-0">
							<v-col cols="9">
								<div id="menu" class="pt-0">
									<div
										v-for="(child, i) of item.links"
										:key="i"
										class="category mega-menu__children mb-4 pr-2"
									>
										<div>
											<router-link
												:to="child.link"
												class="font-1 font-weight-bold pb-1 link"
												:style="linkTextStyle"
											>
												<div class="ellipsis-text">{{ child.name }}</div>
											</router-link>
										</div>

										<!-- Sublink -->
										<div class="subcategories">
											<div v-for="(subChild, i) in subChild(child)" :key="i">
												<router-link
													:to="subChild.link"
													class="subchild-font"
													:style="linkTextStyle"
												>
													<div>{{ subChild.name }}</div>
												</router-link>
											</div>
										</div>

										<!-- ALL -->
										<div>
											<router-link
												:to="
													$shop.getShopRoute({
														categories: [item.category.urlName, child.urlName],
													})
												"
												class="subchild-font font-weight-bold link"
												v-if="hasManySubChildren(child)"
												:style="linkTextStyle"
											>
												<span>Ver más categorías >></span>
											</router-link>
										</div>
									</div>
									<div
										v-for="child of item.category.children"
										:key="child.id"
										class="category mb-4 mega-menu__children pr-2"
									>
										<div>
											<router-link
												:to="
													$shop.getShopRoute({
														categories: [item.category.urlName, child.urlName],
													})
												"
												class="font-1 font-weight-bold pb-1 link"
												:style="linkTextStyle"
											>
												<div class="ellipsis-text">{{ child.name }}</div>
											</router-link>
										</div>

										<!-- Sublink -->
										<div class="subcategories">
											<router-link
												v-for="(subChild, i) in subChild(child)"
												:key="i"
												:to="
													$shop.getShopRoute({
														categories: [
															item.category.urlName,
															child.urlName,
															subChild.urlName,
														],
													})
												"
												class="subchild-font link"
												:style="linkTextStyle"
											>
												<div>{{ subChild.name }}</div>
											</router-link>
										</div>

										<!-- ALL -->
										<div>
											<router-link
												:to="
													$shop.getShopRoute({
														categories: [item.category.urlName, child.urlName],
													})
												"
												class="subchild-font mt-2 font-weight-bold link"
												v-if="hasManySubChildren(child)"
												:style="linkTextStyle"
											>
												<span>Ver más categorías >></span>
											</router-link>
										</div>
									</div>
									<v-col>
										<SafeLink :to="item.link">
											<div class="clickable-img"></div>
										</SafeLink>
									</v-col>
								</div>
							</v-col>
							<v-col>
								<SafeLink :to="item.link">
									<div class="clickable-img"></div>
								</SafeLink>
							</v-col>
						</v-row>
					</div>
				</div>
			</Hover>
		</div>
		<!-- <CategoriesMegaMenu-Overlay v-if="overlay" v-show="globalHover" style="z-index: 1; left: -17px" /> -->
	</div>
</template>

<style lang="scss" scoped>
#menu {
	column-count: 3;
	column-gap: 10px;
}

.category {
	break-inside: avoid-column;
}

.subchild-font {
	font-size: 13px;
}

.clickable-img {
	position: absolute;
	width: 230px;
	height: 100%;
	background-color: transparent;
	right: 0;
	bottom: 0;
}

.underline {
	&::after {
		content: ' ';
		position: absolute;
		left: 0;
		right: 0;
		border-bottom: 1px solid var(--basetext);
		bottom: 4px;
		margin-right: 10px;
		margin-left: 10px;
		transform: scaleX(0);
		transform-origin: bottom center;
		transition: transform 0.25s ease-out;
	}
	&:hover::after {
		transform: scaleX(1);
		transform-origin: bottom center;
	}
	&--permanent::after {
		transform: scaleX(1);
		transform-origin: bottom center;
	}
}

.mega-menu {
	min-height: 52px;

	&__submenu {
		position: absolute;
		min-height: 350px;
		overflow: auto;
		background-color: var(--secondary);
		width: 100%;
		// margin-left: -50%;
		top: 52px;
		z-index: 3;

		&::-webkit-scrollbar {
			width: 3px;
		}

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(145, 144, 144, 0.3);
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--base);
			outline: 1px solid rgb(143, 143, 144);
		}
	}
}
</style>
