import { views } from '@/v-shop/autoloader'

export default (options) => {
	const { store } = options
	const beforeEnter = (to, from, next) => {
		if (!store.get('shop/user')) {
			next({ name: 'home' })
		} else {
			next()
		}
	}
	return [
		{
			beforeEnter,
			path: '/user/account',
			name: 'user.account',
			component: views.UserAccount,
		},
		{
			beforeEnter,
			path: '/user/favorites',
			name: 'user.favorites',
			component: views.UserFavorites,
		},
		{
			beforeEnter,
			path: '/user/orders',
			name: 'user.orders',
			component: views.UserOrders,
		},
		{
			beforeEnter,
			path: '/user/orders/:id',
			name: 'user.order-detail',
			component: views.UserOrderDetail,
		},
	]
}
