var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"categories-list primary pa-0 rounded"},_vm._l((_vm.items),function(ref,i){
var ref_category = ref.category;
var name = ref_category.name;
var children = ref_category.children;
var id = ref_category.id;
return _c('div',{key:id},[_c('router-link',{key:i,attrs:{"to":''}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"categories-list__item pa-2 pl-4 d-flex justify-space-between",class:{
						'rounded-bl rounded-br': i === _vm.items.length - 1,
						'rounded-tl rounded-tr': i === 0,
						'on-hover': hover || _vm.hoverSubmenu === id,
					},attrs:{"input-value":id === _vm.hoverSubmenu},on:{"mouseover":function($event){return _vm.$emit('mouseover', id)}}},[_c('div',{staticClass:"categories-list__title font-weight-bold"},[_vm._v(" "+_vm._s(name)+" ")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(children.length > 0),expression:"children.length > 0"}],staticClass:"pr-2",attrs:{"size":"small","color":hover || _vm.hoverSubmenu === id ? _vm.$vars.basetext : _vm.$vars.primarytext}},[_vm._v(" mdi-chevron-right ")])],1)]}}],null,true)})],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }