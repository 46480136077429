import gtagUtils from './googleAnalyticsUtils.js'
import fbPixelUtils from './fbPixelUtils.js'

export default (options) => {
	const { eventer, store, srv, trackers } = options

	// UPDATE USER ID ------------------------------------------
	let _currUserId = null
	const updateUser = (user) => {
		if (!user || _currUserId == user.id) return
		_currUserId = user.id

		trackers.gtag('set', { user_id: user.id })
		trackers.fbq('init', srv('Analytics.fbpId'), fbPixelUtils.normalizeUserData(user))
	}

	updateUser(store.get('shop/user'))
	store.watch(() => store.get('shop/user'), updateUser)

	// LISTENERS
	eventer.listen('product-list:view', ({ products, listId = null, listName = null }) => {
		const productsData = products.map((product, i) => ({
			product: product,
			variant: product.selectedVariant,
			index: 'index' in product ? product.index : i,
		}))
		trackers.gtag(
			'event',
			'view_item_list',
			gtagUtils.normalizeProductListData(productsData, listId, listName)
		)
	})

	eventer.listen('product:click', ({ product, variant, listId = null, listName = null, index = 0 }) => {
		const productsData = [{ product, variant: variant || product.selectedVariant, index }]
		trackers.gtag(
			'event',
			'select_item',
			gtagUtils.normalizeProductListData(productsData, listId, listName)
		)
	})

	eventer.listen('product:view', ({ product, variant }) => {
		let productsData = [{ product, variant }]
		trackers.gtag('event', 'view_item', gtagUtils.normalizeProductListData(productsData))
		trackers.fbq('track', 'ViewContent', fbPixelUtils.normalizeProductData(product, variant))
	})

	eventer.listen('user:signup', ({ loginMethod, user }) => {
		trackers.gtag('event', 'sign_up', { method: loginMethod, user_id: user.id })
		trackers.fbq('track', 'CompleteRegistration', fbPixelUtils.normalizeUserData(user))
	})

	eventer.listen('user:signin', ({ loginMethod, user }) => {
		trackers.gtag('event', 'login', { method: loginMethod, user_id: user.id })
	})

	eventer.listen('cart:item-added', ({ product, qty, order }) => {
		trackers.gtag('event', 'add_to_cart', gtagUtils.normalizeCartItemData(product, qty))
		trackers.fbq('track', 'AddToCart', fbPixelUtils.normalizeOrderData(order))
	})

	eventer.listen('cart:item-removed', async ({ product, qty }) => {
		trackers.gtag('event', 'remove_from_cart', gtagUtils.normalizeCartItemData(product, qty))
	})

	eventer.listen('checkout:init', async ({ order }) => {
		trackers.gtag('event', 'begin_checkout', gtagUtils.normalizeOrderData(order))
		trackers.fbq('track', 'InitiateCheckout', fbPixelUtils.normalizeOrderData(order))
	})

	eventer.listen('checkout:step-submit', ({ stepKey, order }) => {
		if (stepKey === 'delivery') {
			trackers.gtag('event', 'add_shipping_info', gtagUtils.normalizeOrderData(order))
		} else if (stepKey === 'payment') {
			trackers.gtag('event', 'add_payment_info', gtagUtils.normalizeOrderData(order))
		} else if (stepKey === 'contact') {
			trackers.fbq('track', 'AddPaymentInfo', fbPixelUtils.normalizeOrderData(order))
		} else if (stepKey === 'confirm') {
			let gtagPromise = new Promise((resolve) => {
				if (!trackers.gtagEnabled) return resolve()
				let params = gtagUtils.normalizeOrderData(order)
				params.event_callback = resolve
				trackers.gtag('event', 'purchase', params)
			})
			let fbqPromise = new Promise((resolve) => {
				if (!trackers.fbqEnabled) return resolve()
				let params = fbPixelUtils.normalizeOrderData(order)
				trackers.fbq('track', 'Purchase', params, resolve)
				setTimeout(resolve, 2000)
			})
			return Promise.all([gtagPromise, fbqPromise])
		}
	})

	eventer.listen('search:view-results', ({ products, searchTerm, listId }) => {
		const productsData = products.map((product, i) => ({
			product: product,
			variant: product.selectedVariant,
			index: 'index' in product ? product.index : i,
		}))
		trackers.gtag('event', 'view_search_results', {
			...gtagUtils.normalizeProductListData(productsData, listId),
			search_term: searchTerm,
		})
	})

	eventer.listen('search:search', ({ searchTerm }) => {
		trackers.gtag('event', 'search', {
			search_term: searchTerm,
		})
		trackers.fbq('track', 'Search', {
			search_string: searchTerm,
		})
	})
}
