import { views } from '@/v-shop/autoloader'

export default ({ srv }) => {
	return srv('dynamicPagesRoutes', []).map((data) => {
		return {
			path: data.path,
			meta: data.meta,
			component: views.DynamicPage,
		}
	})
}
