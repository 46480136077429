<script>
// const YoutubeVideo = () => import(/* webpackChunkName: "checkout" */ './youtube-video.vue')
const VImg = () => import('vuetify/lib/components/VImg')
export default {
	inheritAttrs: false,
	props: {
		src: [Object, String],
		imageProps: Object,
		videoProps: Object,
		youtubeVideoProps: Object,
		noAlt: Boolean,
	},
	components: {
		VImg,
		// YoutubeVideo,
	},
	computed: {
		mediaItem() {
			return this.$media(this.src)
		},
		cProps() {
			const props = {}
			props.alt = this.noAlt ? '' : this.mediaItem.alt || ''
			if (this.mediaItem.type === 'image' || this.mediaItem.type === 'gif') {
				props.as = 'RatioImage'
				props.srcKey = 'src'
				let width = this.mediaItem.data?.width || undefined
				let height = this.mediaItem.data?.height || undefined
				if (width && height) {
					props.original = { width, height }
				}
				Object.assign(props, this.$attrs)
				Object.assign(props, this.imageProps || {})
				//if (isBotAgent) props.eager = true
			} else if (this.mediaItem.type === 'video') {
				props.as = 'video'
				props.srcKey = 'src'
				Object.assign(props, this.$attrs)
				Object.assign(props, this.videoProps || {})
			} else if (this.mediaItem.type === 'youtube-video') {
				props.as = 'YoutubeVideo'
				props.srcKey = 'key'
				Object.assign(props, this.$attrs)
				Object.assign(props, this.youtubeVideoProps || {})
			}
			props[props.srcKey] = this.mediaItem.src
			delete props.srcKey
			return props
		},
		slotsKeys() {
			return Object.keys(this.$slots).filter((key) => key !== 'default')
		},
	},
}
</script>

<template>
	<component :is="cProps.as" v-bind="{ ...cProps, as: undefined }" v-on="$listeners">
		<template #default>
			<slot v-if="mediaItem.type == 'image' || mediaItem.type == 'gif'"></slot>
			<slot v-else :name="mediaItem.type"> </slot>
		</template>
		<template v-for="slotKey of slotsKeys" #[slotKey]>
			<slot :name="slotKey" />
		</template>
	</component>
</template>
