<script>
export default {
	hookZone: ['bottom'],
	cssVars: {
		selector: '.smart-popup',
	},
	metaInfo() {
		const link = []
		this.popupConfig.forEach((config) => {
			if (config.bgImage) {
				link.push({
					as: 'image',
					rel: 'preload',
					href: this.$media(config.bgImage).src,
				})
			}
			if (this.$b.m && config.bgImageMobile) {
				link.push({
					as: 'image',
					rel: 'preload',
					href: this.$media(config.bgImageMobile).src,
				})
			}
			if (config.useBgFullImage && config.bgFullImage) {
				link.push({
					as: 'image',
					rel: 'preload',
					href: this.$media(config.bgFullImage).src,
				})
			}
			if (config.useBgFullImage && this.$b.m && config.bgFullImageMobile) {
				link.push({
					as: 'image',
					rel: 'preload',
					href: this.$media(config.bgFullImageMobile).src,
				})
			}
			if (config.useBgFullImage && config.bgFullImageBottom) {
				link.push({
					as: 'image',
					rel: 'preload',
					href: this.$media(config.bgFullImage).src,
				})
			}
			if (config.insideImage) {
				link.push({
					as: 'image',
					rel: 'preload',
					href: this.$media(config.insideImage).src,
				})
			}
		})
		return { link }
	},
	data() {
		return {
			popupConfig: this.$srv('popupConfig', {}),
		}
	},
}
</script>

<template>
	<div v-if="popupConfig">
		<css-vars
			v-for="(popup, i) in popupConfig"
			:key="i"
			:default-vars="popup.cssVars"
			:selector="`.smart-popup-${popup.id}`"
		>
			<ClientSideOnly>
				<UrlMatch :data="popup">
					<SmartPopupCenter
						style="z-index: 10"
						:popupConfig="popup"
						v-if="popup.position === 'smart-popup-center'"
					/>
					<SmartPopupBottom
						style="z-index: 10"
						:popupConfig="popup"
						v-else-if="popup.position === 'smart-popup-bottom'"
					/>
					<SmartPopupRight
						style="z-index: 10"
						:popupConfig="popup"
						v-else-if="popup.position === 'smart-popup-right'"
					/>
				</UrlMatch>
			</ClientSideOnly>
		</css-vars>
	</div>
</template>

<style lang="scss" scoped></style>
