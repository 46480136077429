<script>
import numberedPropsToArray from '@/utils/numbered-props-to-array'
import { get } from 'vuex-pathify'
import scrollMixin from './scrollMixin'
import appbarHeightMixin from './appbarHeightMixin'

export default {
	hooksKey: 'CategoriesMegaMenu',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	mixins: [appbarHeightMixin],
	props: {
		noBg: Boolean,
		hamburgerBtn: Boolean,
		dense: {
			type: Boolean,
			default: false,
		},
		denseWidthScale: {
			type: Number,
			default: 0.5,
		},
		btnText: String,
		overlay: {
			type: Boolean,
			default: true,
		},
		menuStyles: Object,
		buttonClass: Array,
		widthScale: {
			type: Number,
			default: 0.7,
		},
		submenuStyles: [String, Object],
		submenuClass: [String, Object],
		dialogArrow: Boolean,
	},
	data() {
		return {
			items: this.$srv('MegaMenu', []),
			hover: false,
			hoverSubmenu: false,
			categoriesWidth: 240,
			close: false,
			visibilityRef: 'megaMenu',
			windowWidth: 0,
		}
	},
	mounted() {
		this.windowWidth = window.innerWidth
		window.addEventListener('resize', this.handleResize)
	},
	watch: {
		$route() {
			this.hover = false
		},
		loading() {
			this.hover = false
		},
	},
	computed: {
		appbarMenuOffset() {
			return this.$srv('AppBarConfig.type') === 'regular' ? 10 : 5
		},
		loading: get('shop/routeLoading'),
		itemsWithSubcategories() {
			return this.items.filter((i) => i.category.children.length)
		},
		subChild() {
			return (child) => child.children.slice(0, 3)
		},
		hasManySubChildren() {
			return (child) => child.children.length > 3
		},
		imageData() {
			return (item) => numberedPropsToArray(item, 2).filter((d) => d.img)
		},
		cSubmenuStyles() {
			if (this.submenuStyles) return this.submenuStyles
			if (this.$ssr) return

			const offset = 70

			const calcWidth = this.windowWidth - this.categoriesWidth - offset
			return `width: ${calcWidth}px`
		},
	},
	methods: {
		handleResize() {
			this.windowWidth = window.innerWidth
		},
		compareFn(elementRect) {
			return elementRect.top > -56 && elementRect.bottom <= window.innerHeight
		},
		hasImages(item) {
			const data = this.imageData(item)
			return data.filter((d) => typeof d.img === 'object').length > 0
		},
		onNotVisibleCb() {
			this.close = true
		},
	},
	cssVars: {
		selector: '.appbar__mega-menu',
	},
}
</script>

<template>
	<div :ref="visibilityRef" :class="$bem('appbar__mega-menu')" v-if="items.length">
		<Hover
			v-model="hover"
			@input="
				(e) => {
					if (closeOnScroll) close = !e
				}
			"
			:open-delay="$b.d ? 250 : 0"
			:close-delay="$b.d ? 250 : 0"
		>
			<div
				:class="$bem('__container')"
				class="full-height d-flex"
				style="position: relative; max-width: 1200px"
			>
				<Button
					ref="button"
					:class="[$bem('__button'), ...buttonClass]"
					class="px-3 py-4 base"
					:style="noBg && 'background: transparent!important'"
					height="100%"
					large
					tile
					depressed
					@click="hover = !hover"
				>
					<div class="d-flex" style="gap: 7px" :class="hamburgerBtn && 'flex-row-reverse align-center'">
						<span v-if="btnText" class="font-1 font-weight-bold"> {{ btnText }} </span>
						<v-icon
							:class="hover && 'appbar__mega-menu__button-icon--open'"
							class="appbar__mega-menu__button-icon mx-0"
							:left="!hamburgerBtn"
							:size="30"
							:color="hamburgerBtn ? $vars.basetext : ''"
							>{{ hamburgerBtn ? 'mdi-menu' : 'mdi-chevron-down' }}
						</v-icon>
					</div>
				</Button>

				<div
					class="appbar__mega-menu__main elevation-12"
					:class="{ 'dialog-arrow': dialogArrow, 'arrow-offset-left': dialogArrow && btnText }"
					v-show="!close && hover"
					:style="{ ...menuStyles, width: `${categoriesWidth}px`, 'max-height': `${cMaxHeight}px` }"
					style="transition: 0.2s 1s"
				>
					<CategoriesMegaMenu-CategoriesList
						:style="`max-height: ${cMaxHeight}px`"
						:items="itemsWithSubcategories"
						@mouseover="hoverSubmenu = arguments[0]"
						:hover-submenu="hoverSubmenu"
					/>

					<div>
						<!-- Right submenu -->
						<hook zone="submenu">
							<div
								v-for="item of itemsWithSubcategories"
								:key="item.id"
								v-show="hoverSubmenu === item.category.id"
							>
								<div
									class="appbar__mega-menu__submenu elevation-12 px-6 py-1 rounded rounded-l-0"
									:class="submenuClass"
									:style="cSubmenuStyles"
								>
									<!-- Title -->
									<CategoriesMegaMenu-CategoryTitle
										style="border-bottom: 1px solid #eee"
										:item="item"
									/>

									<v-row class="pl-4 pt-0">
										<!-- Links -->
										<v-col :cols="hasImages(item) && 9" class="px-2 d-flex flex-column w100 h100">
											<div class="h100" style="min-height: 0; column-count: 3; column-gap: 10px">
												<div
													v-for="child of item.category.children"
													:key="child.id"
													class="appbar__mega-menu__children-container pr-2 pb-4"
													style="break-inside: avoid-column"
												>
													<router-link
														:to="
															$shop.getShopRoute({
																categories: [item.category.urlName, child.urlName],
															})
														"
														class="font-2 pb-1"
													>
														<span class="font-weight-bold">{{ child.name }}</span>
													</router-link>

													<!-- Sublink -->
													<router-link
														v-for="(subChild, i) in subChild(child)"
														:key="i"
														:to="
															$shop.getShopRoute({
																categories: [
																	item.category.urlName,
																	child.urlName,
																	subChild.urlName,
																],
															})
														"
													>
														{{ subChild.name }}
													</router-link>

													<!-- ALL -->
													<router-link
														:to="
															$shop.getShopRoute({
																categories: [item.category.urlName, child.urlName],
															})
														"
														class="font-1"
														v-if="hasManySubChildren(child)"
													>
														<span>Ver más</span>
													</router-link>
												</div>
											</div>
										</v-col>
										<!-- Images -->
										<v-col class="pl-0" v-if="hasImages(item)">
											<v-row>
												<v-col
													v-for="(data, n) in imageData(item)"
													:key="n"
													col="6"
													:class="dense && 'appbar__mega-menu__submenu__images-container--dense'"
												>
													<CategoriesMegaMenu-Image v-bind="data" />
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</div>
							</div>
						</hook>
					</div>
				</div>
			</div>
		</Hover>
	</div>
</template>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.bordered {
	border: 1px solid #eee;
}

.menu-right-icon-active {
	font-size: 30px !important;
	transform: translateX(96%) rotate(0.5turn);
}

.dialog-arrow::before {
	content: '';
	width: 0;
	height: 0;
	border: 0 solid transparent;
	border-right-width: 14px;
	border-left-width: 14px;
	border-bottom: 14px solid var(--primary);
	top: -14px;
	left: 20px;
	position: absolute;
	display: block;
}

.arrow-offset-left::before {
	left: 7px;
}

.appbar__mega-menu {
	&__container {
		position: relative;
		z-index: 3;
	}

	&__button {
		height: 100%;

		&-icon {
			&--open {
				transform: rotate(180deg);
			}
		}
	}

	&__main {
		position: absolute;
		top: 98%;
		z-index: 4;
	}

	&__submenu {
		position: absolute;
		z-index: 5;
		top: 0;
		left: 95%;
		background: var(--secondary) !important;
		height: 100%;
		overflow: overlay;

		&__images-container--dense {
			display: flex;
			justify-content: center;
			gap: 35px;
		}

		&::-webkit-scrollbar {
			width: 3px;
		}

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(145, 144, 144, 0.3);
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--base);
			outline: 1px solid rgb(143, 143, 144);
		}

		a {
			color: var(--linktext) !important;
		}
	}

	@media screen and (min-width: 1904px) {
		&__submenu {
			width: 1200px;
		}
	}

	&__children-container {
		overflow: hidden;

		a {
			opacity: 0.8;
			transition: all 0.2s;
			display: block;
			overflow: hidden;
			width: 100%;

			.v-icon {
				width: 0px;
				opacity: 0;
				transition: all 0.2s;
				display: inline-block;
				vertical-align: middle;
			}

			span {
				width: calc(100% - 25px);
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}
</style>
