<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],

	hooksKey: 'ProductCard',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div class="pb-2 h100 product-card-shadow-with-btn-vertical-wrapper" v-if="verticalCard">
		<v-hover v-slot="{ hover }">
			<v-card
				:hover="!outOfStock ? true : false"
				@click="cardClick"
				@mouseover="firstImageHover = true"
				:ripple="false"
				:class="$bem('product-card-shadow-with-btn-vertical')"
				class="h100 d-flex flex-column base rounded-md overflow-hidden product-card"
			>
				<div :class="$bem('__image-cont')">
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont')">
							<Media
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
							/>
						</div>
					</hook>

					<hook zone="offer">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>

					<hook zone="event-icon">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
					</hook>
				</div>

				<div class="d-flex flex-column justify-space-between h100">
					<div class="d-flex align-center px-3 py-2 justify-space-between">
						<div class="d-flex align-center">
							<hook zone="prevPrice" v-if="discountPct">
								<del class="grey--text">
									<PriceText
										:amount="prevPrice"
										:currency="currency"
										:currencyMargin="0"
										:class="$bem('__prev-price')"
										class="grey--text mr-2 font-0"
										priceClass="grey--text"
									/>
								</del>
							</hook>

							<hook zone="price">
								<PriceText
									:amount="price"
									:currency="currency"
									:class="$bem('__price')"
									class="price font-4 font-weight-bold"
								/>
							</hook>
						</div>
					</div>
					<v-spacer />

					<hook zone="attrs&financingTags" v-if="financingTagText">
						<div :class="$bem('__financing')" class="font-0 line-clamp-1 px-3 font-weight-bold primary">
							{{ financingTagText }}
						</div>
					</hook>

					<hook zone="textTags">
						<div
							:class="$bem('__textTags')"
							class="font-0 line-clamp-1 px-3 font-weight-bold primary"
							v-if="hasTextTag"
						>
							{{ textTags[0].text }}
						</div>
					</hook>

					<v-spacer v-if="financingTagText || hasTextTag" />

					<router-link class="card-anchor header" :to="productRoute">
						<hook zone="name">
							<div
								class="product-card__name line-clamp-2 font-2 font-weight-bold px-3 header"
								:class="{
									'mt-8': !financingTagText && brand,
									'mb-4': !financingTagText && !brand,
									'mt-2': financingTagText,
								}"
							>
								{{ product.name }}
							</div>
						</hook>
					</router-link>

					<hook zone="brand" v-if="brand">
						<div :class="$bem('__brand', 'font-0 line-clamp-1 px-3 mt-1')">
							{{ brand | lang }}
						</div>
					</hook>

					<v-spacer />

					<div class="pa-3" :class="{ 'mt-1': financingTagText }">
						<hook zone="btn">
							<v-btn class="cta w100 rounded-md" :disabled="buttonText == 'Sin stock'">
								{{ buttonText | lang }}
							</v-btn>
						</hook>
					</div>
				</div>

				<hook zone="wishlist-toggler">
					<ProductWishlistToggler
						:productId="product.id"
						:class="$bem('__wishlist-toggler')"
						style="z-index: 1"
					/>
				</hook>

				<hook zone="discount" v-if="discountPct > 0">
					<span :class="$bem('__discount')" class="discount font-weight-medium pa-2">
						{{ discountPct }}% OFF
					</span>
				</hook>

				<hook zone="end" />
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD FOR MOBILE SHOP -->
	<div class="w100" v-else>
		<v-card
			outlined
			@click="cardClick"
			:ripple="false"
			:class="$bem('product-card-shadow-with-btn-horizontal')"
			class="d-flex overflow-hidden base product-card py-2"
		>
			<div :class="$bem('__image-cont', 'col col-5 pa-0')">
				<hook zone="imageHorizontal">
					<Media :class="$bem('__main-image', 'px-1')" :src="mainImage" width="100%" />
				</hook>

				<hook zone="offerHorizontal">
					<LimitedTimeOffer
						:product="product"
						v-if="hasLimitedTimeOffer"
						:class="[$bem('__offer'), { 'pr-2': hasEventIcon }]"
						:textAlign="hasEventIcon ? 'end' : 'center'"
					/>
				</hook>
			</div>

			<div class="col col-7 py-0 pl-1 d-flex flex-column justify-center">
				<div class="d-flex align-center px-2 py-1">
					<hook zone="prevPriceHorizontal" v-if="discountPct">
						<del class="grey--text">
							<PriceText
								:amount="prevPrice"
								:currency="currency"
								:currencyMargin="0"
								:class="$bem('__prev-price')"
								priceClass="grey--text mr-2 font-0"
							/>
						</del>
					</hook>

					<hook zone="priceHorizontal">
						<PriceText
							:amount="price"
							:currency="currency"
							:class="[$bem('__price'), { 'px-1': discountPct }]"
							class="price font-4 line-clamp-1"
						/>
					</hook>

					<hook zone="discountHorizontal" v-if="discountPct > 0">
						<span
							:class="$bem('__discount')"
							class="d-inline-block font-weight-medium discount px-2"
							v-if="discountPct > 0"
						>
							{{ discountPct }}% OFF
						</span>
					</hook>
				</div>

				<hook zone="attrs&financingTagsHorizontal" v-if="financingTagText">
					<div :class="$bem('__financing')" class="font-0 line-clamp-1 mx-2 font-weight-bold primary">
						{{ financingTagText }}
					</div>
				</hook>

				<hook zone="textTagsHorizontal" v-if="hasTextTag">
					<div :class="$bem('__text-tag')" class="font-0 line-clamp-1 mx-2 font-weight-bold primary">
						{{ textTags[0].text }}
					</div>
				</hook>

				<router-link class="card-anchor header" :to="productRoute">
					<hook zone="nameHorizontal">
						<div :class="$bem('__name')" class="line-clamp-2 px-2 header font-weight-regular font-2">
							{{ product.name }}
						</div>
					</hook>
				</router-link>

				<div class="px-2 pt-2">
					<hook zone="btnHorizontal">
						<v-btn class="cta w100 rounded-md" :disabled="buttonText == 'Sin stock'" small>
							{{ buttonText | lang }}
						</v-btn>
					</hook>
				</div>

				<hook zone="wishlist-togglerHorizontal">
					<ProductWishlistToggler
						:productId="product.id"
						:class="$bem('__wishlist-toggler')"
						:height="30"
						:width="30"
						iconSize="25px"
						style="z-index: 1"
					/>
				</hook>

				<hook zone="event-iconHorizontal">
					<CollectionEventIcon
						:product="product"
						:class="[
							$bem('__event-icon'),
							{ 'product-card-shadow-with-btn-horizontal__event-icon--w25': hasLimitedTimeOffer },
						]"
						:imgWidth="hasLimitedTimeOffer ? '25px' : '30px'"
					/>
				</hook>
				<hook zone="endHorizontal" />
			</div>
		</v-card>
	</div>
	<!-- END OF HORIZONTAL CARD FOR MOBILE SHOP -->
</template>

<style lang="scss" scoped>
.product-card-shadow-with-btn-vertical-wrapper {
	min-height: 370px;
	width: 250px;
	min-width: 250px;
}

.product-card-shadow-with-btn-vertical {
	position: relative;
	cursor: pointer;
	text-align: left;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__wishlist-toggler {
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 1;
	}

	&__offer {
		position: absolute;
		bottom: 0;
		z-index: 1;
	}

	&__event-icon {
		position: absolute;
		bottom: 0;
		left: 6px;
		width: 40px;
		z-index: 1;
	}

	&__name {
		line-height: 1.6rem !important;
	}

	&__prev-price {
		line-height: normal;
	}

	&__image-cont {
		border-bottom: 1px solid rgb(170, 170, 170);
	}

	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}
}

.product-card-shadow-with-btn-horizontal {
	min-height: 180px;

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__main-image {
		left: 50%;
		transform: translateX(-50%);
	}

	&__event-icon {
		position: absolute;
		bottom: 6px;
		left: 6px;
		width: 30px;
		z-index: 1;

		&--w25 {
			bottom: 8px;
			width: 25px;
		}
	}

	&__offer {
		position: absolute;
		bottom: 0;
		z-index: 1;
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 1;
	}

	&__discount {
		font-size: 0.9rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}

	&__name {
		line-height: 1.5rem !important;
	}
}

.no-stock {
	opacity: 0.4;
}

a.card-anchor:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 1;
	left: 0;
}
</style>
