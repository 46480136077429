<script>
export default {
	props: {
		name: String,
		attributes: Array,
	},
	computed: {
		hasAttributes() {
			return this.attributes?.length > 0
		},
	},
}
</script>

<template>
	<div class="product-page__attributes" v-if="hasAttributes">
		<div class="font-3 font-weight-bold pb-4 header product-page__attributes-title">
			Características {{ name }}
		</div>
		<v-row>
			<v-col cols="6" sm="4" md="3" v-for="(item, i) of attributes" :key="i">
				<div class="font-weight-bold font-2 product-page__attributes-key">
					{{ item.attrKey.k }}
				</div>
				<div class="pt-1 font-1 product-page__attributes-value">
					{{ item.v }}
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<style></style>
