function normalizeOrderData(order) {
	let numItems = 0
	let contents = order.items.map((item) => {
		numItems += item.qty
		let content = {
			id: item.sku,
			quantity: item.qty,
			title: item.name,
			variant: item.variantName,
			description: item.variantName,
			price: item.finalPrice,
			item_price: item.finalPrice,
		}
		if (item.customData?.brand?.name) {
			content.brand = item.customData.brand.name
		}
		if (item.customData?.categories?.length) {
			content.category = item.customData.categories.map((c) => c.name).join(' > ')
		}
		return content
	})

	return {
		value: order.total,
		currency: order.currency.iso,
		content_type: 'product',
		num_items: numItems,
		contents,
	}
}

function normalizeUserData(user) {
	let data = {
		external_id: user.id,
		email: user.accountEmail,
		last_name: user.lastname,
		first_name: user.firstname,
	}
	if (user.fullPhone) data.phone = user.fullPhone
	if (user.facebookId) data.fb_login_id = user.facebookId
	return data
}

function normalizeProductData(product, variant) {
	let { price } = variant.pvPrice
	let currency = variant.pvPrice.priceConfig.currency.iso
	let value = price
	let description = variant.attrs?.map((attr) => `${attr.attrKey.k} ${attr.v}`).join(' / ') || ''
	let content = {
		id: variant.sku,
		quantity: 1,
		title: product.name,
		description: description,
		variant: description,
		item_price: price,
		price,
	}

	if (product.brand) content.brand = product.brand.name
	if (product.categories?.length) content.category = product.categories.map((c) => c.name).join(' > ')

	return {
		contents: [content],
		currency,
		value,
		content_type: 'product',
	}
}

export default {
	normalizeOrderData,
	normalizeUserData,
	normalizeProductData,
}
