<template>
	<v-menu offset-y min-width="150px" open-on-hover transition="slide-y-transition">
		<template v-slot:activator="{ on, attrs }">
			<Button class="share-button" small fab v-bind="attrs" v-on="on" elevation="0">
				<v-icon color="grey">mdi-share-variant</v-icon>
			</Button>
		</template>
		<v-list>
			<div class="list-item" v-for="{ color, redSocial, icon } in dataToShare" :key="redSocial">
				<Button text small block class="d-flex justify-start text-uppercase" @click="share(redSocial)">
					<div
						class="svg-container d-flex justify-xl-center align-center"
						:style="`background-color:  ${color}`"
					>
						<v-icon color="white"> {{ icon }} </v-icon>
					</div>
					{{ redSocial }}
				</Button>
			</div>
		</v-list>
	</v-menu>
</template>

<script>
export default {
	props: {
		productName: String,
	},
	data: () => ({
		dataToShare: [
			{ redSocial: 'whatsapp', color: '#25d366', icon: 'mdi-whatsapp' },
			{ redSocial: 'email', color: '#777', icon: 'mdi-email' },
			{ redSocial: 'facebook', color: '#3b5998', icon: 'mdi-facebook' },
			{ redSocial: 'twitter', color: '#55acee', icon: 'mdi-twitter' },
			{ redSocial: 'linkedin', color: '#0077b5', icon: 'mdi-linkedin' },
		],
	}),
	methods: {
		share(social) {
			const shareString = `Mira que buen producto! ${this.productName}`

			const socialsShare = {
				whatsapp: `https://api.whatsapp.com/send?text= ${shareString}`,
				email: 'mailto:?subject=Mira este producto;body=',
				facebook:
					'https://www.facebook.com/login.php?next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%26ret%3Dlogin&display=popup#',
				linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=',
				twitter: `http://twitter.com/share?text=${shareString}&url=`,
			}

			const shareBaseLink = socialsShare[social]

			if (!shareBaseLink) return

			window.open(shareBaseLink + window.location, '_blank')
		},
	},
}
</script>

<style lang="scss" scoped>
.list-item {
	min-height: 30px;
	margin-bottom: 0.3rem;
	.svg-container {
		padding: 0.3rem;
		margin-right: 4px;
		border-radius: 4px;
	}
}
</style>
