<script>
export default {}
</script>

<template>
	<footer>
		<Footer-Top />
		<Footer-Bottom />
	</footer>
</template>
