var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","min-width":"150px","open-on-hover":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('Button',_vm._g(_vm._b({staticClass:"share-button",attrs:{"small":"","fab":"","elevation":"0"}},'Button',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-share-variant")])],1)]}}])},[_c('v-list',_vm._l((_vm.dataToShare),function(ref){
var color = ref.color;
var redSocial = ref.redSocial;
var icon = ref.icon;
return _c('div',{key:redSocial,staticClass:"list-item"},[_c('Button',{staticClass:"d-flex justify-start text-uppercase",attrs:{"text":"","small":"","block":""},on:{"click":function($event){return _vm.share(redSocial)}}},[_c('div',{staticClass:"svg-container d-flex justify-xl-center align-center",style:(("background-color:  " + color))},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(icon)+" ")])],1),_vm._v(" "+_vm._s(redSocial)+" ")])],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }