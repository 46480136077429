<script>
import appbarMixin from '@/v-shop/appbarMixin'

export default {
	mixins: [appbarMixin],
	props: {
		dense: Boolean,
	},
	computed: {
		iconSize() {
			return this.dense ? 20 : 25
		},
		btnClass() {
			return this.dense ? 'px-1' : 'px-2'
		},
	},
}
</script>

<template>
	<div class="d-flex align-center justify-end">
		<v-btn
			v-if="user && $b.td"
			text
			class="mr-2"
			:class="btnClass"
			:to="{ name: 'user.account' }"
			:color="$vars.basetext"
		>
			<v-icon :size="iconSize" class="mr-1 btn"> mdi-account</v-icon>
			<span class="btn font-1-">{{ user.firstname }}</span>
		</v-btn>
		<v-btn
			v-if="!user && $b.td"
			text
			class="mr-2"
			:class="btnClass"
			@click="showLoginDrawer"
			:color="$vars.basetext"
			:loading="loginLoading"
		>
			<v-icon :size="iconSize" class="mr-1 btn">mdi-account</v-icon>
			<span class="btn font-1">Ingresar</span>
		</v-btn>
		<v-btn v-if="$b.td" text class="mr-2" :class="btnClass" @click="goToFavorites" :color="$vars.basetext">
			<v-icon :size="iconSize" class="mr-1 btn">mdi-heart</v-icon>
			<span class="d-none d-lg-block btn font-1">Favoritos</span>
		</v-btn>

		<v-btn text @click="showCartDrawer" class="mr-md-0 px-0 px-md-2" :color="$vars.basetext">
			<v-badge :content="orderItemsQty" :value="!!orderItemsQty" overlap aria-label="carrito">
				<v-icon :size="iconSize" class="mr-1 btn">{{ $g('cartIcon', 'mdi-cart') }}</v-icon>
			</v-badge>
			<span class="d-none d-lg-block btn font-1">Carrito</span>
		</v-btn>
	</div>
</template>

<style lang="scss" scoped>
.text-transform-none {
	text-transform: none;
}
</style>
