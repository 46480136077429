import { render, staticRenderFns } from "./comp_ProductShipping-Price.vue?vue&type=template&id=608460f9&scoped=true&"
import script from "./comp_ProductShipping-Price.vue?vue&type=script&lang=js&"
export * from "./comp_ProductShipping-Price.vue?vue&type=script&lang=js&"
import style0 from "./comp_ProductShipping-Price.vue?vue&type=style&index=0&id=608460f9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "608460f9",
  null
  
)

export default component.exports