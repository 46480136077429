<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],

	hooksKey: 'ProductCard',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div class="pb-2 h100 product-card-shadow-vertical-wrapper" v-if="verticalCard">
		<v-hover v-slot="{ hover }">
			<v-card
				@mouseover="firstImageHover = true"
				:hover="!outOfStock ? true : false"
				@click="cardClick"
				:ripple="false"
				:class="$bem('product-card-shadow-vertical')"
				class="h100 d-flex flex-column base rounded-md overflow-hidden product-card"
			>
				<div :class="$bem('__image-cont')" v-if="!outOfStock">
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont')">
							<Media
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
							/>
						</div>
					</hook>

					<hook zone="offer">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>

					<hook zone="event-icon">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
					</hook>
				</div>

				<div :class="$bem('__image-cont', 'no-stock')" v-else>
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
					</hook>

					<hook zone="offer">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>

					<hook zone="event-icon">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
					</hook>
				</div>

				<div class="d-flex align-center px-3 py-2" :class="{ 'no-stock': outOfStock }">
					<hook zone="prevPrice" v-if="discountPct">
						<del class="grey--text">
							<PriceText
								:amount="prevPrice"
								:currency="currency"
								:currencyMargin="0"
								:class="$bem('__prev-price')"
								class="grey--text mr-2 font-0"
								priceClass="grey--text"
							/>
						</del>
					</hook>

					<hook zone="price">
						<PriceText
							:amount="price"
							:currency="currency"
							:class="$bem('__price')"
							class="price font-4 font-weight-bold"
						/>
					</hook>

					<hook zone="discount" v-if="discountPct > 0">
						<span :class="$bem('__discount')" class="discount font-weight-medium pa-2">
							{{ discountPct }}% OFF
						</span>
					</hook>
				</div>
				<v-spacer />

				<hook zone="attrs&financingTags" v-if="financingTagText" :class="{ 'no-stock': outOfStock }">
					<div :class="$bem('__financing')" class="font-0 line-clamp-1 px-3 font-weight-bold primary">
						{{ financingTagText }}
					</div>
				</hook>

				<hook zone="textTags" :class="{ 'no-stock': outOfStock }" v-if="hasTextTag">
					<div :class="$bem('__text-tag')" class="font-0 line-clamp-1 px-3 font-weight-bold primary">
						{{ textTags[0].text }}
					</div>
				</hook>

				<v-spacer v-if="financingTagText || hasTextTag" />

				<router-link class="card-anchor header" :to="productRoute">
					<hook zone="name" :class="{ 'no-stock': outOfStock }">
						<div
							class="product-card__name line-clamp-2 font-2 font-weight-bold px-3 header"
							:class="{
								'mb-15': !financingTagText && !brand && !discountPct,
								'mt-2': financingTagText,
								'mb-2': !brand,
							}"
						>
							{{ product.name }}
						</div>
					</hook>
				</router-link>

				<hook zone="brand" v-if="brand" :class="{ 'no-stock': outOfStock }">
					<div :class="$bem('__brand', 'font-0 line-clamp-1 px-3 my-2')">
						{{ brand }}
					</div>
				</hook>

				<hook zone="wishlist-toggler" :class="{ 'no-stock': outOfStock }">
					<ProductWishlistToggler
						:productId="product.id"
						:class="$bem('__wishlist-toggler')"
						style="z-index: 1"
					/>
				</hook>

				<hook zone="end" />
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD FOR MOBILE SHOP -->
	<div class="w100" v-else>
		<v-card
			outlined
			@click="cardClick"
			:ripple="false"
			:class="$bem('product-card-shadow-horizontal')"
			class="d-flex overflow-hidden base product-card py-2"
		>
			<div :class="[$bem('__image-cont', 'col col-5 pa-0'), { 'no-stock': outOfStock }]">
				<hook zone="imageHorizontal">
					<Media :class="$bem('__main-image', 'px-1')" :src="mainImage" width="100%" />
				</hook>

				<hook zone="offerHorizontal">
					<LimitedTimeOffer
						:product="product"
						v-if="hasLimitedTimeOffer"
						:class="[$bem('__offer'), { 'pr-2': hasEventIcon }]"
						:textAlign="hasEventIcon ? 'end' : 'center'"
					/>
				</hook>

				<hook zone="event-iconHorizontal">
					<CollectionEventIcon
						:product="product"
						:class="[
							$bem('__event-icon'),
							{ 'product-card-shadow-horizontal__event-icon--w25': hasLimitedTimeOffer },
						]"
						:imgWidth="hasLimitedTimeOffer ? '25px' : '30px'"
					/>
				</hook>
			</div>

			<div
				class="col col-7 py-0 pl-1 d-flex flex-column justify-center"
				:class="{ 'no-stock': outOfStock }"
			>
				<div class="d-flex align-center px-2 py-1">
					<hook zone="prevPriceHorizontal" v-if="discountPct">
						<del class="grey--text">
							<PriceText
								:amount="prevPrice"
								:currency="currency"
								:currencyMargin="0"
								:class="$bem('__prev-price')"
								priceClass="grey--text mr-2 font-0"
							/>
						</del>
					</hook>

					<hook zone="priceHorizontal">
						<PriceText
							:amount="price"
							:currency="currency"
							:class="[$bem('__price'), discountPct && 'px-1']"
							class="price font-4 line-clamp-1"
						/>
					</hook>

					<hook zone="discountHorizontal" v-if="discountPct > 0">
						<span
							:class="$bem('__discount')"
							class="d-inline-block font-weight-medium discount px-2"
							v-if="discountPct > 0"
						>
							{{ discountPct }}% OFF
						</span>
					</hook>
				</div>

				<hook
					zone="attrs&financingTagsHorizontal"
					v-if="financingTagText"
					:class="{ 'no-stock': outOfStock }"
				>
					<div :class="$bem('__financing', 'font-0 line-clamp-1 mx-2 font-weight-bold primary')">
						{{ financingTagText }}
					</div>
				</hook>

				<hook zone="textTagsHorizontal" :class="{ 'no-stock': outOfStock }">
					<div
						v-if="hasTextTag"
						:class="$bem('__text-tag')"
						class="font-0 line-clamp-1 mx-2 font-weight-bold primary"
					>
						{{ textTags[0].text }}
					</div>
				</hook>

				<router-link class="card-anchor header" :to="productRoute">
					<hook zone="nameHorizontal">
						<div :class="$bem('__name')" class="line-clamp-2 px-2 header font-weight-regular font-2">
							{{ product.name }}
						</div>
					</hook>
				</router-link>

				<hook zone="wishlist-togglerHorizontal">
					<ProductWishlistToggler
						:productId="product.id"
						:class="$bem('__wishlist-toggler')"
						:height="30"
						:width="30"
						iconSize="25px"
						style="z-index: 1"
					/>
				</hook>
				<hook zone="endHorizontal" />
			</div>
		</v-card>
	</div>
	<!-- END OF HORIZONTAL CARD FOR MOBILE SHOP -->
</template>

<style lang="scss" scoped>
.product-card-shadow-vertical-wrapper {
	min-height: 370px;
	width: 250px;
	min-width: 250px;
}

.product-card-shadow-vertical {
	position: relative;
	cursor: pointer;
	text-align: left;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__wishlist-toggler {
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 1;
	}

	&__event-icon {
		position: absolute;
		bottom: 0;
		left: 6px;
		width: 40px;
		z-index: 1;
	}

	&__offer {
		position: absolute;
		bottom: 0;
		z-index: 1;
	}

	&__name {
		line-height: 1.6rem !important;
	}

	&__prev-price {
		line-height: normal;
	}

	&__image-cont {
		border-bottom: 1px solid rgb(170, 170, 170);
	}

	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}
}

.product-card-shadow-horizontal {
	min-height: 170px;

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__main-image {
		left: 50%;
		transform: translateX(-50%);
	}

	&__event-icon {
		position: absolute;
		bottom: 0px;
		left: 6px;
		width: 30px;
		z-index: 1;

		&--w25 {
			width: 25px;
		}
	}

	&__offer {
		position: absolute;
		bottom: 0;
		z-index: 1;
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 1;
	}

	&__discount {
		font-size: 0.9rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}

	&__name {
		line-height: 1.7rem !important;
	}
}

.no-stock {
	opacity: 0.4;
}

a.card-anchor:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 1;
	left: 0;
}
</style>
