<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],

	hooksKey: 'ProductCard',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<div
		class="h100 py-2 px-1"
		:class="{
			'product-card-left-aligned-small-carousel-wrapper-mobile': $b.mt,
			'product-card-left-aligned-small-carousel-wrapper': $b.d,
		}"
	>
		<v-card
			:hover="!outOfStock ? true : false"
			@click="cardClick()"
			:ripple="false"
			:class="$bem('product-card-left-aligned-small-carousel')"
			class="h100 d-flex flex-column base rounded-md overflow-hidden justify-space-between product-card"
		>
			<v-hover v-slot="{ hover }" v-if="!outOfStock">
				<div :class="$bem('__image-cont')" @mouseover="firstImageHover = true">
					<hook zone="image">
						<Media
							cover
							:class="$bem('__main-image')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1"
							:imgClass="{
								'product-card-left-aligned-small-carousel__image--smallWidth': $b.d,
								'product-card-left-aligned-small-carousel__image--smallWidth-mobile': $b.mt,
							}"
						/>
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont')">
							<Media
								cover
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
								:imgClass="{
									'product-card-left-aligned-small-carousel__image--smallWidth': $b.d,
									'product-card-left-aligned-small-carousel__image--smallWidth-mobile': $b.mt,
								}"
							/>
						</div>
					</hook>
					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
				</div>
			</v-hover>

			<div :class="$bem('__image-cont', 'no-stock')" v-else>
				<hook zone="image">
					<Media
						:class="$bem('__main-image')"
						:src="mainImage"
						width="100%"
						aspect-ratio="1"
						:imgClass="{ smallWidth: $b.d, 'smallWidth-mobile': $b.mt }"
					/>
				</hook>
				<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
			</div>

			<hook zone="name" :class="{ 'no-stock': outOfStock }">
				<div
					:class="[$bem('__name'), { 'mb-2': !brand }]"
					class="line-clamp-2 font-1 font-weight-regular px-3 mt-3 header"
				>
					{{ product.name }}
				</div>
			</hook>

			<hook zone="brand" v-if="brand" :class="{ 'no-stock': outOfStock }">
				<div :class="$bem('__brand')" class="font-0 line-clamp-1 px-3 mb-2">
					{{ brand }}
				</div>
			</hook>
			<hook zone="end" />
		</v-card>
	</div>
</template>

<style lang="scss" scoped>
.product-card-left-aligned-small-carousel-wrapper {
	min-height: 270px;
	width: 180px;
	min-width: 100px;
}

.product-card-left-aligned-small-carousel-wrapper-mobile {
	width: 170px;
}

.product-card-left-aligned-small-carousel {
	position: relative;
	cursor: pointer;
	text-align: left;

	&__image-cont {
		position: relative;
		border-bottom: 1px solid rgb(170, 170, 170);
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__event-icon {
		position: absolute;
		bottom: 0;
		left: 6px;
		width: 25px;
	}

	&__name {
		line-height: 1.3rem !important;
	}

	&__brand {
		line-height: 1rem;
	}
}

.no-stock {
	opacity: 0.4;
}

::v-deep .product-card-left-aligned-small-carousel__image--smallWidth {
	max-width: 180px;
	max-height: 180px;
}
::v-deep .product-card-left-aligned-small-carousel__image--smallWidth-mobile {
	max-width: 170px;
	max-height: 170px;
}
</style>
