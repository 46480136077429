<script>
export default {
	props: {
		loading: Boolean,
	},
}
</script>

<template>
	<transition :duration="{ enter: 5000, leave: 500 }">
		<div class="loading-bar" v-if="loading">
			<div class="loading-bar__p"></div>
		</div>
	</transition>
</template>

<style lang="scss" scoped>
.loading-bar {
	background-color: rgba(0, 0, 0, 0.5);
	height: 6px;
	width: 100%;
	&__p {
		background-color: var(--link);
		width: 100%;
		height: 100%;
		transform: translateX(-10%);
	}
	&.v-enter-active .loading-bar__p {
		transition: transform 5s cubic-bezier(0.07, 0.31, 0, 0.97);
	}
	&.v-enter .loading-bar__p {
		transform: translateX(-100%);
	}
	&.v-leave-active .loading-bar__p {
		transition: transform 0.5s;
	}
	&.v-leave-to .loading-bar__p {
		transform: translateX(0);
	}
}
</style>
