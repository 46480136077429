<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],

	hooksKey: 'ProductCard',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div class="pb-2 h100 product-card-left-aligned-vertical-wrapper" v-if="verticalCard">
		<v-hover v-slot="{ hover }">
			<v-card
				:hover="!outOfStock ? true : false"
				outlined
				@click="cardClick"
				:ripple="false"
				:class="$bem('product-card-left-aligned-vertical')"
				class="h100 d-flex flex-column overflow-hidden base product-card"
				@mouseover="firstImageHover = true"
			>
				<div :class="$bem('__image-cont')" v-if="!outOfStock">
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont')">
							<Media
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
							/>
						</div>
					</hook>

					<hook zone="offer">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>
					<hook zone="event-icon">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
					</hook>
				</div>

				<div :class="$bem('__image-cont', 'mx-4 no-stock')" v-else>
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
					</hook>
					<hook zone="offer">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>
					<hook zone="event-icon">
						<CollectionEventIcon
							:product="product"
							:class="[$bem('__event-icon'), { 'ml-2': !hasLimitedTimeOffer }]"
						/>
					</hook>
				</div>

				<hook zone="price" :class="{ 'no-stock': outOfStock }">
					<PriceText
						:amount="price"
						:currency="currency"
						:class="[$bem('__price'), { 'no-stock': outOfStock }]"
						class="price font-6 mt-6 line-clamp-1 px-4"
					/>
				</hook>

				<div
					class="d-flex align-center pb-2 line-clamp-1 px-4"
					:class="[$bem('__prev-price'), { 'no-stock': outOfStock }]"
					v-if="prevPrice"
				>
					<hook zone="prevPrice">
						<del class="grey--text">
							<PriceText
								:amount="prevPrice"
								:currency="currency"
								:currencyMargin="0"
								priceClass="grey--text font-2"
							/>
						</del>
					</hook>

					<hook zone="discount">
						<span
							:class="$bem('__discount')"
							class="d-inline-block discount font-2"
							v-if="discountPct > 0"
						>
							{{ discountPct }}% OFF
						</span>
					</hook>
				</div>

				<hook zone="attrs&financingTags" :class="{ 'no-stock': outOfStock }">
					<div
						v-if="financingTagText"
						:class="[
							$bem('__financing'),
							{ 'my-2': !discountPct && !hasTextTag, 'mt-2': !discountPct && hasTextTag },
						]"
						class="font-0 line-clamp-1 px-4 font-weight-bold primary text-start"
					>
						{{ financingTagText }}
					</div>
				</hook>

				<hook zone="textTags" :class="{ 'no-stock': outOfStock }">
					<div
						v-if="hasTextTag"
						:class="[
							$bem('__text-tag'),
							{ 'my-2': !discountPct && !financingTagText, 'mb-2': !discountPct && financingTagText },
						]"
						class="font-0 line-clamp-1 mx-2 px-2 font-weight-bold primary text-start"
					>
						{{ textTags[0].text }}
					</div>
				</hook>

				<v-spacer />

				<router-link class="card-anchor header" :to="productRoute">
					<hook zone="name" :class="{ 'no-stock': outOfStock }">
						<div
							:class="$bem('__name')"
							class="line-clamp-2 font-1 px-4 header font-weight-regular text-start"
						>
							{{ product.name }}
						</div>
					</hook>
				</router-link>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD FOR MOBILE SHOP -->
	<div class="w100" v-else>
		<v-card
			outlined
			@click="cardClick"
			:ripple="false"
			:class="$bem('product-card-left-aligned-horizontal')"
			class="d-flex overflow-hidden base product-card py-2"
		>
			<div :class="[$bem('__image-cont', 'col col-5 pa-0'), { 'no-stock': outOfStock }]">
				<hook zone="imageHorizontal">
					<Media :class="$bem('__main-image', 'px-1')" :src="mainImage" width="100%" />
				</hook>
				<hook zone="offerHorizontal">
					<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" />
				</hook>
				<hook zone="event-iconHorizontal">
					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
				</hook>
			</div>

			<div
				class="col col-7 py-0 pl-1 d-flex flex-column justify-center"
				:class="{ 'no-stock': outOfStock }"
			>
				<hook zone="priceHorizontal">
					<PriceText
						:amount="price"
						:currency="currency"
						:class="$bem('__price')"
						class="price font-6 line-clamp-1 px-2"
					/>
				</hook>

				<div
					class="d-flex align-center pb-2 line-clamp-1 px-2"
					:class="$bem('__prev-price')"
					v-if="prevPrice"
				>
					<hook zone="prevPriceHorizontal">
						<del class="grey--text">
							<PriceText
								:amount="prevPrice"
								:currency="currency"
								:currencyMargin="0"
								priceClass="grey--text font-2"
							/>
						</del>
					</hook>

					<hook zone="discountHorizontal">
						<span
							:class="$bem('__discount')"
							class="d-inline-block discount font-2"
							v-if="discountPct > 0"
						>
							{{ discountPct }}% OFF
						</span>
					</hook>
				</div>

				<hook zone="attrs&financingTagsHorizontal" :class="{ 'no-stock': outOfStock }">
					<div
						:class="[
							$bem('__financing', 'font-0 line-clamp-1 mx-2 font-weight-bold primary py-1'),
							{ 'mt-2': !discountPct },
						]"
						v-if="financingTagText"
					>
						{{ financingTagText }}
					</div>
				</hook>

				<hook zone="textTagsHorizontal" :class="{ 'no-stock': outOfStock }">
					<div
						v-if="hasTextTag"
						:class="[$bem('__text-tag'), { 'mt-2': !discountPct && !financingTagText }]"
						class="font-0 line-clamp-1 mx-2 font-weight-bold primary"
					>
						{{ textTags[0].text }}
					</div>
				</hook>

				<router-link class="card-anchor header" :to="productRoute">
					<hook zone="nameHorizontal">
						<div
							:class="$bem('__name')"
							class="line-clamp-2 font-1 px-2 header font-weight-regular text-start"
						>
							{{ product.name }}
						</div>
					</hook>
				</router-link>
			</div>
		</v-card>
	</div>
	<!-- END OF HORIZONTAL CARD FOR MOBILE SHOP -->
</template>

<style lang="scss" scoped>
.product-card-left-aligned-vertical-wrapper {
	min-height: 380px;
	width: 250px;
	min-width: 250px;
}

.product-card-left-aligned-vertical {
	position: relative;
	cursor: pointer;
	border: 1px solid #eaeaea;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__offer {
		position: absolute;
		bottom: 0;
	}

	&__event-icon {
		position: absolute;
		bottom: -7px;
		left: 6px;
		width: 40px;
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__name {
		margin-bottom: 15px;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}
}

.product-card-left-aligned-horizontal {
	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__main-image {
		left: 50%;
		transform: translateX(-50%);
	}

	&__event-icon {
		width: 25px;
		position: absolute;
		top: 7px;
		left: 6px;
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}
}

.no-stock {
	opacity: 0.4;
}

a.card-anchor:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 2;
	left: 0;
}
</style>
