<template>
	<div class="category-title pt-2 pb-4 mb-4">
		<router-link :to="$shop.getShopRoute({ categories: [item.category.urlName] })" class="link">
			<span class="ml-4 is-heading font-4">{{ item.category.name }}</span>
			<span class="font-1 font-weight-bold ml-4">
				Ver todo
				<v-icon v-if="showIcon" small class="pb-1">mdi-chevron-right</v-icon>
			</span>
		</router-link>
	</div>
</template>

<script>
export default {
	props: {
		item: Object,
		showIcon: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style lang="scss" scoped></style>
