import { views } from '@/v-shop/autoloader'

export default () => [
	{
		path: '/terminos-y-condiciones',
		name: 'terms',
		component: views.TermsCondition,
	},
	{
		path: '/preguntas-frecuentes',
		name: 'Info',
		component: views.Info,
	},
	{
		path: '/comprar',
		name: 'howto',
		component: views.HowTo,
	},
]
