// Pathify
import { make } from 'vuex-pathify'

export default (options) => {
	const { srv } = options

	const state = {
		order: srv('cart.order'),
	}

	const mutations = make.mutations(state)
	const actions = make.actions(state)
	const getters = {}

	return {
		name: 'cart',
		namespaced: true,
		state,
		mutations,
		actions,
		getters,
	}
}
