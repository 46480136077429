import { views } from '@/v-shop/autoloader'

export default () => [
	{
		path: '/credit-card-payment/:id',
		name: 'creditCardPayment',
		component: views.CreditCardPayment,
		meta: { appComponent: 'CreditCardApp' },
	},
]
