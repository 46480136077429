const affiliation = `DrubbitShop (${process.env.VUE_APP_BASE_URL})`

function extractCurrencyIso(product) {
	return product?.variants[0].pvPrice.priceConfig.currency.iso
}

function calculateTotalValue(items) {
	let value = items.reduce((sum, item) => {
		return sum + item.price * item.quantity
	}, 0)
	return n(value, 2)
}
function n(v, dec = 0) {
	return Number(Number(v || 0).toFixed(dec))
}

function normalizeOrderItemData(orderItem, qty, currencyIso) {
	let params = {
		affiliation,
		item_id: orderItem.sku,
		item_name: orderItem.name,
		quantity: qty,
		price: orderItem.finalPrice,
		discount: orderItem.finalDiscount,
	}

	if (orderItem.variantName) {
		params.item_variant = orderItem.variantName
	}

	let { categories, brand, refPrice } = orderItem.customData || {}
	if (categories?.length) {
		categories.slice(0, 4).forEach((c, i) => {
			let n = i == 0 ? '' : `${i + 1}`
			params[`item_category${n}`] = c.name
		})
	}

	if (brand) {
		params.item_brand = brand.name
	}

	if (refPrice) {
		params[`item_category5`] = `${currencyIso} ${refPrice.toFixed(2)}`
	}

	fixItemParams(params)
	return params
}

function normalizeProductData({ product, variant, qty = 1, listId, listName, index }) {
	variant = variant || product.variants.find((x) => x.forDisplay)
	let { prevPrice, price } = variant.pvPrice
	if (!prevPrice) prevPrice = price
	let params = {
		affiliation: 'Drubbit',
		item_id: variant.sku,
		item_name: product.name,
		price,
		discount: prevPrice - price,
		quantity: qty,
	}
	if (product.brand?.name) {
		params.item_brand = product.brand.name
	}

	let { categories } = product
	if (categories?.length) {
		categories.slice(0, 4).forEach((c, i) => {
			let n = i == 0 ? '' : `${i + 1}`
			params[`item_category${n}`] = c.name
		})
	}

	const currencyIso = extractCurrencyIso(product)
	params.currencyIso = currencyIso
	params[`item_category5`] = `${currencyIso} ${price.toFixed(2)}`
	if (listId) params.item_list_id = listId
	if (listName) params.item_list_name = listName

	let variantName = variant.attrs?.map((attr) => `${attr.attrKey.k} ${attr.v}`).join(' / ')
	if (variantName) params.item_variant = variantName
	fixItemParams(params)

	if (typeof index == 'number' && index >= 0) {
		params.index = index
	}

	return params
}

function fixItemParams(params) {
	params.quantity = n(params.quantity)
	params.price = n(params.price, 2)
	params.discount = n(params.discount, 2)
	let roundedQty = Math.round(params.quantity)
	if (params.quantity != roundedQty) {
		let m = params.quantity / roundedQty
		params.quantity = roundedQty
		params.price = n(params.price * m, 2)
		params.discount = n(params.discount * m, 2)
	}
}

function normalizeOrderData(order) {
	let params = {
		affiliation,
	}
	params.currency = order.currency.iso
	params.items = order.items.map((item) => {
		return normalizeOrderItemData(item, item.qty, order.currency.iso)
	})
	params.value = calculateTotalValue(params.items)
	if (order.code) params.transaction_id = `${order.code}`
	if (order.delivery) {
		params.shipping = order.delivery.total
		let shippingTier = [order.delivery.methodKey]
		if (order.delivery.optionKey) shippingTier.push(order.delivery.optionKey)
		params.shipping_tier = shippingTier.join(' / ')
	}

	const coupon = order.discounts.find((discount) => discount.triggerType == 'coupon')
	if (coupon) params.coupon = coupon.discountName

	if (order.payments?.length) {
		let payment = order.payments[0]
		let paymentType = [payment.methodKey]
		if (payment.optionKey) paymentType.push(payment.optionKey)
		params.payment_type = paymentType.join(' / ')
	}
	return params
}

function normalizeProductListData(productsData, listId, listName) {
	const gaItems = productsData.map(({ product, variant, index }) => {
		return normalizeProductData({
			product,
			variant,
			listId,
			listName,
			index,
		})
	})
	return {
		items: gaItems,
		currency: extractCurrencyIso(productsData[0].product),
		value: calculateTotalValue(gaItems),
	}
}

function normalizeCartItemData(product, qty) {
	const currencyIso = extractCurrencyIso(product)
	let items = [normalizeProductData({ product, variant: product.variants[0], qty, currencyIso })]
	let value = calculateTotalValue(items)
	return { currency: currencyIso, value, items }
}

export default {
	normalizeOrderData,
	normalizeProductListData,
	normalizeCartItemData,
}
