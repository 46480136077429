<script>
export default {
	data() {
		return { isMounted: false }
	},
	mounted() {
		this.isMounted = true
	},
	render() {
		return this.isMounted ? this.$slots.default : null
	},
}
</script>
