<script>
export default {
	props: {
		card: Object,
		type: Number,
	},
}
</script>

<template>
	<v-card :to="card.link" :class="$bem('offer-card', 'h100')" :ripple="false" outlined hover>
		<div :class="[type == 2 && 'd-flex align-center', card.inverted && 'flex-row-reverse']">
			<div :class="type == 2 && 'col-6 pa-0'">
				<Media
					:class="[$bem('__image'), type == 4 && 'mb-2']"
					:src="$b({ td: card.img, m: card.imgMobile })"
					width="100%"
					:imgClass="[
						type == 4 && 'rounded-t',
						card.inverted && type == 2 && 'rounded-r',
						!card.inverted && type == 2 && 'rounded-l',
					]"
				/>
			</div>

			<div :class="[$bem('__text', 'text-center'), type == 2 ? 'col-6' : 'pb-2 px-2']">
				<h2
					:class="[
						type == 2 && { 'font-2 header': $b.mt, 'font-3 header': $b.d },
						type == 4 && 'font-2 font-weight-regular header',
					]"
				>
					{{ card.title }}
				</h2>
				<h3
					v-if="card.subtitle"
					class="primary--text font-weight-regular"
					:class="{ 'font-1 my-1': $b.d, 'mb-0 font-0': $b.mt }"
				>
					{{ card.subtitle }}
				</h3>
				<p
					v-if="card.offer"
					class="discount--text text-uppercase"
					:class="{ 'font-1 my-1': $b.d, 'mb-0 font-0': $b.mt }"
				>
					{{ card.offer }}
				</p>
				<p
					v-if="card.linkText"
					class="cta py-1 px-2 d-inline-block"
					:class="[$bem('__cta'), { 'font-1 my-1': $b.d, 'mt-1 mb-0 font-0': $b.mt }]"
				>
					{{ card.linkText }}
				</p>
			</div>
		</div>
	</v-card>
</template>

<style lang="scss" scoped>
.offer-card {
	border: 1px solid #eaeaea !important;

	&__cta {
		border-radius: 4px;
	}
}
</style>
