<template>
  <component
      :v-bind="$props"
      :is="searchBarType"
  ></component>
</template>

<script>
export default {
	name: 'comp_SearchFieldRenderer',
	props: {
		fieldType: String, // regular | autocomplete
	},
  data() {
    return {
      searchBarTypes: {
        regular: 'RegularSearchField',
        autocomplete: 'AutocompleteSearchField',
      }
    }
  },
	computed: {
		searchBarType() {
      return this.searchBarTypes[this.fieldType]
		},
	},
}
</script>

<style scoped></style>
