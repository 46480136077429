<script>
export default {
	props: {
		showDialog: Boolean,
		indexValue: Number,
		product: Object,
	},
	model: {
		prop: 'indexValue',
		event: 'updateIndexValue',
	},
	data() {
		return {
			currentIndex: this.indexValue,
			mountCarousel: false,
			scale: 0,
		}
	},
	watch: {
		indexValue(value) {
			this.currentIndex = value || 0
		},
		currentIndex(value) {
			this.$emit('updateIndexValue', value)
			this.scale = 0
		},
		showDialog(value) {
			this.scale = 0
			setTimeout(() => (this.mountCarousel = value), 50)
		},
	},
	methods: {
		closeDialog() {
			this.$emit('update:show-dialog', false)
		},
		toggleScale() {
			this.scale += 1
			if (this.scale > 2) {
				this.scale = 0
			}
		},
	},
}
</script>

<template>
	<v-dialog :value="showDialog" persistent dark fullscreen transition="fade-transition">
		<div class="d-flex align-center justify-center overlay">
			<Button class="place-up" absolute top right icon x-large @click="closeDialog" dark>
				<v-icon x-large>mdi-close</v-icon>
			</Button>
			<Carousel
				v-if="mountCarousel"
				v-model="currentIndex"
				class="w100 h100"
				v-bind="
					$b.props({
						showArrows: { d: true },
						showDots: true,
						noDrag: { d: true },
						gutter: 0,
					})
				"
			>
				<div
					v-for="(image, i) of product.images"
					:key="i"
					class="img w100 h100"
					:class="{
						scale1: scale == 1,
						scale2: scale == 2,
						active: currentIndex == i,
						'py-8': $b.d,
						'pt-60': $b.m,
						'pt-80': $b.t,
					}"
				>
					<Media :src="image.big" height="100%" width="100%" scale-down loader @click="toggleScale()" />
				</div>
			</Carousel>
		</div>
	</v-dialog>
</template>

<style scoped>
.place-up {
	z-index: 999;
}
.overlay {
	background-color: rgba(0, 0, 0, 0.8);
	height: 100%;
}
.img {
	transform-origin: center;
	transition: 0.3s all;
}
.img.active.scale1 {
	transform: scale(1.2);
}
.img.active.scale2 {
	transform: scale(1.4);
}

.pt-80 {
	padding-top: 80px !important;
}

.pt-60 {
	padding-top: 60px !important;
}

::v-deep div.ssr-carousel,
::v-deep div.ssr-carousel-slides,
::v-deep div.ssr-carousel-mask,
::v-deep div.ssr-carousel-track {
	height: 90vh;
}

@media (min-width: 900px) {
	::v-deep button.ssr-carousel-next-button {
		right: 2%;
	}
	::v-deep button.ssr-carousel-back-button {
		left: 2%;
	}
}
</style>
