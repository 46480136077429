<script>
import urlMatch from '#/v-shop/vue-src/utils/urlMatch'

export default {
	props: {
		data: Object,
	},
	computed: {
		urlPasses() {
			const route = this.$route.fullPath
			return urlMatch(this.data, route)
		},
	},
	render() {
		return this.urlPasses ? this.$slots.default : null
	},
}
</script>
