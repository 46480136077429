<script>
export default {
	props: {
		templateData: Object,
	},
	computed: {
		hasDescription() {
			return !!this.textDescription || !!this.templateData.richDescription
		},
		textDescription() {
			return this.templateData.description || this.templateData.content
		},
		descriptionHasHtml() {
			const htmlRegex = /<("[^"]*"|'[^']*'|[^'">])*>/
			return htmlRegex.test(this.textDescription)
		},
		hasHtml() {
			return this.descriptionHasHtml || this.templateData.useRichText
		},
		htmlDescription() {
			return this.templateData.useRichText ? this.templateData.richDescription : this.textDescription
		},
	},
}
</script>

<template>
	<div class="product-page__description" v-if="hasDescription">
		<h2 class="header font-3 font-weight-bold pb-4 product-page__description-title">Descripción</h2>
		<div v-html="htmlDescription" v-if="hasHtml"></div>
		<div class="product-page__description-content" v-else>
			{{ textDescription }}
		</div>
	</div>
</template>

<style scoped lang="scss">
::v-deep .product-page__description-content {
	white-space: pre-line;
}
::v-deep img {
	width: 100%;
	height: auto;
}
</style>
