<script>
import fiscal from './assets/data-fiscal.png'

export default {
	data() {
		return {
			logo: this.$srv('SiteLogo')?.img,
			fiscal,
			footerLinksList: this.$srv('FooterLinksList.links', []),
			footerSocialList: this.$srv('FooterSocialsList.links', []),
			favorites: this.$srv('FooterFavorites.links', []),
			footerLogo: this.$srv('FooterLogo'),
			categories: this.$srv('FooterCategories.categories', []),
			brands: this.$srv('FooterBrands.brands', []),
			institutionals: this.$srv('FooterInstitutional.links', []),
		}
	},
	computed: {
		hasCategories() {
			return this.categories.length > 0 && this.hasNonNullCategories
		},
		hasNonNullCategories() {
			return this.categories.some((c) => c !== null)
		},
	},
	cssVars: {
		selector: '.footer',
	},
}
</script>

<template>
	<div class="footer base">
		<v-container>
			<v-row class="border my-15">
				<v-col
					cols="12"
					md="4"
					class="d-flex flex-column align-center align-md-start justify-center justify-md-start"
				>
					<!-- LOGO -->
					<SafeLink v-if="footerLogo" :to="footerLogo.url || '/'">
						<Media
							:src="footerLogo.img"
							max-width="200px"
							:alt="footerLogo.img.alt"
							class="mb-8"
							contain
						/>
					</SafeLink>

					<FooterContactList v-if="footerLinksList.length > 0" :items="footerLinksList" />

					<FooterSocialsList
						class="d-flex"
						v-if="footerSocialList.length > 0"
						:items="footerSocialList"
					/>
				</v-col>

				<v-col cols="12" md="8">
					<v-row>
						<!--CATEGORIES-->
						<FooterLinkList
							v-if="hasCategories"
							cols="12"
							sm="3"
							class="d-flex flex-column align-center align-sm-start"
							title="Categorías"
						>
							<SafeLink
								v-for="(category, i) of categories"
								:key="`categories_${i}`"
								class="footer-link text-capitalize link"
								style="cursor: pointer"
								:to="$shop.getShopRoute({ categories: category.familyUrlNames })"
							>
								{{ category.name }}
							</SafeLink>
						</FooterLinkList>

						<FooterLinkList
							cols="12"
							sm="3"
							class="d-flex flex-column align-center align-sm-start"
							title="Marcas"
							v-if="brands.length"
						>
							<SafeLink
								v-for="(item, i) of brands.slice(0, 9)"
								:key="`brands_${i}`"
								class="footer-link text-capitalize link"
								style="cursor: pointer"
								:to="$shop.getShopRoute({ brand: item.urlName })"
							>
								{{ item.name }}
							</SafeLink>
							<SafeLink :to="$shop.getShopRoute()" v-if="brands.length > 9" class="link">
								Ver todas...
							</SafeLink>
						</FooterLinkList>

						<FooterLinkList
							cols="12"
							sm="3"
							class="d-flex flex-column align-center align-sm-start"
							title="Tus Favoritos"
							:items="favorites"
							v-if="favorites.length"
						/>

						<!--INSTITUTIONAL-->
						<FooterLinkList
							cols="12"
							sm="3"
							title="Institucionales"
							class="d-flex flex-column align-center align-sm-start"
							:items="institutionals"
							v-if="institutionals.length"
						>
						</FooterLinkList>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style scoped>
.footer-link {
	margin-bottom: 15px;
}
</style>
