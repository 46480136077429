var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h100",class:{ 'center-card-sq-wrapper': _vm.$b.d, 'product-card-sq-wrapper-mobile': _vm.$b.mt }},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"h100 d-flex flex-column overflow-hidden justify-space-between base product-card",class:_vm.$bem('center-card-sq'),attrs:{"hover":!_vm.outOfStock ? true : false,"outlined":"","ripple":false},on:{"mouseover":function($event){_vm.firstImageHover = true},"click":_vm.cardClick}},[(!_vm.outOfStock)?_c('div',{class:_vm.$bem('__image-cont mx-2')},[_c('hook',{attrs:{"zone":"image"}},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"cover":"","src":_vm.mainImage,"width":"100%","aspect-ratio":"1.5","imgClass":"center-card-sq__image--smallWidth"}}),(_vm.secondImage && _vm.firstImageHover)?_c('div',{class:_vm.$bem('__second-image-cont')},[_c('Media',{class:[_vm.$bem('__second-image'), hover ? _vm.$bem('__second-image--show') : ''],attrs:{"cover":"","src":_vm.secondImage,"width":"100%","aspect-ratio":"1.5","imgClass":"center-card-sq__image--smallWidth"}})],1):_vm._e()],1)],1):_c('div',{class:_vm.$bem('__image-cont mx-2', 'no-stock')},[_c('hook',{attrs:{"zone":"image"}},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"src":_vm.mainImage,"width":"100%","aspect-ratio":"1.5","imgClass":"center-card-sq__image--smallWidth"}})],1)],1),_c('hook',{class:{ 'no-stock': _vm.outOfStock },attrs:{"zone":"price"}},[_c('PriceText',{staticClass:"price font-2 font-weight-bold line-clamp-1 mt-1 px-2",class:[_vm.$bem('__price'), { 'product-card-sq__price-mobile': _vm.$b.mt }],attrs:{"amount":_vm.price,"currency":_vm.currency,"priceClass":"text-center"}})],1),(_vm.prevPrice)?_c('div',{staticClass:"d-flex align-center px-2 justify-center",class:[
					_vm.$bem('__prev-price'),
					{ 'no-stock': _vm.outOfStock },
					{ 'product-card-sq__prev-prices-mobile': _vm.$b.mt } ]},[_c('hook',{attrs:{"zone":"prevPrice"}},[_c('del',{staticClass:"grey--text"},[_c('PriceText',{class:{ 'product-card-sq__prev-price-mobile': _vm.$b.mt },attrs:{"amount":_vm.prevPrice,"currency":_vm.currency,"currencyMargin":0,"priceClass":"grey--text font-0"}})],1)]),_c('hook',{attrs:{"zone":"discount"}},[(_vm.discountPct > 0)?_c('span',{staticClass:"d-inline-block discount font-0 ml-2",class:[_vm.$bem('__discount'), { 'product-card-sq__discount-mobile': _vm.$b.mt }]},[_vm._v(" "+_vm._s(_vm.discountPct)+"% OFF ")]):_vm._e()])],1):_vm._e(),_c('v-spacer',{class:{ 'center-card-sq__spacer--flex-grow': !_vm.discountPct }}),_c('router-link',{staticClass:"card-anchor header",attrs:{"to":_vm.productRoute}},[_c('hook',{class:{ 'no-stock': _vm.outOfStock },attrs:{"zone":"name"}},[_c('div',{staticClass:"line-clamp-2 header mb-2 px-2 font-weight-regular font-1 text-center",class:[_vm.$bem('__name'), { 'product-card-sq__name-mobile': _vm.$b.mt }]},[_vm._v(" "+_vm._s(_vm.product.name)+" ")])])],1),_c('CollectionEventIcon',{class:[
					_vm.$bem('__event-icon'),
					{ 'no-stock': _vm.outOfStock },
					{ 'product-card-sq__event-icon-mobile': _vm.$b.mt } ],attrs:{"product":_vm.product,"imgWidth":"25px"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }