<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	mixins: [productCardMixin],
}
</script>

<template>
	<v-container :class="$bem('product-data-sheet', 'secondary no-mobile-px py-0')">
		<!-- DESKTOP VERSION -->
		<v-row :class="$bem('__product-view')" v-if="$b.d" class="my-0">
			<v-col cols="4">
				<v-hover v-slot="{ hover }">
					<div :class="$bem('__image-cont')" @mouseover="firstImageHover = true">
						<Media cover :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont')">
							<Media
								cover
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
							/>
						</div>
					</div>
				</v-hover>
			</v-col>
			<v-col cols="8" class="py-15 d-flex flex-column justify-space-around" style="position: relative">
				<h3 class="font-3 header">{{ product.name }}</h3>
				<div class="d-flex align-center">
					<div>
						<div class="d-flex align-baseline mt-2 font-2" v-if="discountPct">
							<del class="grey--text">
								<PriceText
									:amount="prevPrice"
									:currency="currency"
									:currencyMargin="0"
									:class="$bem('__prev-price')"
									priceClass="grey--text mr-2"
								/>
							</del>

							<span :class="$bem('__discount')" class="discount font-weight-medium mx-2">
								{{ discountPct }}% OFF
							</span>
						</div>
						<PriceText
							:class="$bem('__price')"
							class="price font-7 mb-5"
							:amount="price"
							:currency="currency"
						/>
					</div>

					<CollectionEventIcon
						multiple
						:product="product"
						:class="[$bem('__event-icon'), { 'mb-5': !discountPct }]"
						class="ml-4"
					/>
				</div>

				<div v-if="product.info.description">{{ product.info.description }}</div>
				<div
					:class="$bem('__financing', 'line-clamp-1 font-weight-medium primary px-2 py-1 rounded')"
					v-if="financingTagText"
				>
					{{ financingTagText }}
				</div>

				<ProductActionsSmall :product="product" :variant="selectedVariant" />
			</v-col>
		</v-row>
		<!-- END OF DESKTOP VERSION -->

		<!-- MOBILE VERSION -->
		<div :class="$bem('__product-view', 'd-flex flex-column align-center')" v-else>
			<div
				:class="$bem('__financing-mobile', 'line-clamp-1 font-weight-medium primary px-2 py-1 rounded')"
				v-if="financingTagText"
			>
				{{ financingTagText }}
			</div>
			<div :class="$bem('__imgs-container', 'mb-2')">
				<v-hover v-slot="{ hover }">
					<div :class="$bem('__image-cont')" @mouseover="firstImageHover = true">
						<Media
							cover
							:class="$bem('__main-image')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1"
							:imgClass="{ smallSize: $b.mt }"
						/>
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont')">
							<Media
								cover
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
								:imgClass="{ smallSize: $b.mt }"
							/>
						</div>
					</div>
				</v-hover>
			</div>

			<div class="d-flex flex-column justify-space-around w100">
				<h3 class="font-2 header line-clamp-1">{{ product.name }}</h3>
				<div class="d-flex align-center">
					<div>
						<div class="d-flex align-baseline mt-2 font-1" v-if="discountPct">
							<del class="grey--text">
								<PriceText
									:amount="prevPrice"
									:currency="currency"
									:currencyMargin="0"
									:class="$bem('__prev-price')"
									priceClass="grey--text mr-2"
								/>
							</del>

							<span :class="$bem('__discount')" class="discount font-weight-medium mx-2">
								{{ discountPct }}% OFF
							</span>
						</div>
						<PriceText
							:class="[$bem('__price'), discountPct ? 'mb-3' : 'mt-3']"
							class="price font-6"
							:amount="price"
							:currency="currency"
						/>
					</div>

					<CollectionEventIcon
						multiple
						:limitAmount="3"
						:product="product"
						:class="[$bem('__event-icon'), { 'mt-3': !discountPct }]"
						class="ml-4"
					/>
				</div>

				<ProductActionsSmall :product="product" :variant="selectedVariant" class="mt-3" />
			</div>
		</div>
		<!-- END OF MOBILE VERSION -->
	</v-container>
</template>

<style scoped lang="scss">
::v-deep .smallSize {
	width: 200px !important;
	height: 200px !important;
}

::v-deep .qty-btn {
	justify-content: center;
}

::v-deep .btn-buy {
	border-radius: 0;
}

::v-deep .stock-message {
	text-align: center;
}

.product-data-sheet {
	position: relative;

	&__imgs-container {
		width: 200px;
	}

	&__financing {
		position: absolute;
		z-index: 1;
		top: 15px;
		left: 13px;
	}

	&__financing-mobile {
		position: absolute;
		z-index: 1;
		top: 0px;
		left: 0px;
	}

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}
}
</style>
