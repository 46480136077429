var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h100 py-2 px-1",class:{
		'product-card-center-aligned-small-carousel-wrapper-mobile': _vm.$b.mt,
		'product-card-center-aligned-small-carousel-wrapper': _vm.$b.d,
	}},[_c('v-card',{staticClass:"h100 d-flex flex-column base rounded-md overflow-hidden justify-space-between product-card",class:_vm.$bem('product-card-center-aligned-small-carousel'),attrs:{"hover":!_vm.outOfStock ? true : false,"ripple":false},on:{"click":function($event){return _vm.cardClick()}}},[(!_vm.outOfStock)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
	var hover = ref.hover;
return [_c('div',{class:_vm.$bem('__image-cont'),on:{"mouseover":function($event){_vm.firstImageHover = true}}},[_c('hook',{attrs:{"zone":"image"}},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"cover":"","src":_vm.mainImage,"width":"100%","aspect-ratio":"1","imgClass":{
							'product-card-center-aligned-small-carousel__image--smallWidth': _vm.$b.d,
							'product-card-center-aligned-small-carousel__image--smallWidth-mobile': _vm.$b.mt,
						}}}),(_vm.secondImage && _vm.firstImageHover)?_c('div',{class:_vm.$bem('__second-image-cont')},[_c('Media',{class:[_vm.$bem('__second-image'), hover ? _vm.$bem('__second-image--show') : ''],attrs:{"cover":"","src":_vm.secondImage,"width":"100%","imgClass":{
								'product-card-center-aligned-small-carousel__image--smallWidth': _vm.$b.d,
								'product-card-center-aligned-small-carousel__image--smallWidth-mobile': _vm.$b.mt,
							}}})],1):_vm._e()],1)],1)]}}],null,false,541157656)}):_c('div',{class:_vm.$bem('__image-cont', 'no-stock')},[_c('hook',{attrs:{"zone":"image"}},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"src":_vm.mainImage,"width":"100%","aspect-ratio":"1","imgClass":{
						'product-card-center-aligned-small-carousel__image--smallWidth': _vm.$b.d,
						'product-card-center-aligned-small-carousel__image--smallWidth-mobile': _vm.$b.mt,
					}}})],1)],1),_c('hook',{class:{ 'no-stock': _vm.outOfStock },attrs:{"zone":"name"}},[_c('div',{class:[
					_vm.$bem('__name', 'line-clamp-2 font-1 font-weight-regular px-3 mt-3 text-center header'),
					{ 'mb-2': !_vm.brand } ]},[_vm._v(" "+_vm._s(_vm.product.name)+" ")])]),(_vm.brand)?_c('hook',{class:{ 'no-stock': _vm.outOfStock },attrs:{"zone":"brand"}},[_c('div',{class:_vm.$bem('__brand', 'font-0 line-clamp-1 px-3 mb-2 text-center')},[_vm._v(" "+_vm._s(_vm.brand)+" ")])]):_vm._e(),_c('CollectionEventIcon',{class:[_vm.$bem('__event-icon'), { 'no-stock': _vm.outOfStock }],attrs:{"product":_vm.product,"imgWidth":"25px"}}),_c('hook',{class:{ 'no-stock': _vm.outOfStock },attrs:{"zone":"end"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }