<script>
import { get } from 'vuex-pathify'

export default {
	props: {
		product: Object,
	},
	computed: {
		limitedTimeOffer: get('shop/limitedTimeOffer'),
		productOffers() {
			return this.limitedTimeOffer?.filter((offer) =>
				this.product.collectionsIds.includes(Number(offer.collectionId))
			)
		},
		hasOffers() {
			return !!this.productOffers?.length
		},
		limitedTimeOfferMsg() {
			if (!this.productOffers.length) return
			else {
				const days = this.productOffers[0].days
				const hours = this.productOffers[0].hours
				const minutes = this.productOffers[0].minutes
				const format = (val) => (val < 10 ? `0${val}` : val)

				if (hours === 0 && days === 0) {
					return `${format(minutes)}m`
				}
				if (days === 0) {
					return `${format(hours)}h ${format(minutes)} m`
				}
				return `${format(days)} d ${format(hours)} hs ${format(minutes)} m`
			}
		},
	},
}
</script>

<template>
	<div v-if="hasOffers">
		<div class="d-flex justify-start align-center font-weight-medium font-2 w100 px-0">
			<div class="offer rounded py-1 px-2">
				<div>Termina en {{ limitedTimeOfferMsg }}</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
