<script>
export default {
	props: {
		small: Boolean,
		smallCarousel: Boolean,
		square: Boolean,
		featured: Boolean,
	},
	computed: {
		compName() {
			let type = this.type || this.$srv('productCardType.name', 'LeftAligned')
			let concept = type.replace('WithBtn', '')

			if (this.small) {
				return `${concept}Small`
			} else if (this.smallCarousel) {
				return `${concept}ForSmallCarousel`
			} else if (this.square) {
				return `${concept}Sq`
			} else if (this.featured) {
				return `${type}Featured`
			} else {
				return type
			}
		},
	},
}
</script>

<template>
	<component :is="`ProductCard-${compName}`" v-bind="$attrs" v-on="$listeners" :featured="featured" />
</template>
