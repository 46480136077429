// Pathify
import { make } from 'vuex-pathify'
import moment from 'moment'
import urlMatch from '#/v-shop/vue-src/utils/urlMatch'

export default ({ srv }) => {
	const state = {
		user: srv('user', null),
		wishlistIds: srv('wishlistIds', []),
		loginDrawer: false,
		cartDrawer: false,
		menuMobileDrawer: false,
		offersDrawer: false,
		appBarRoundDrawer: false,
		forceTransparency: false,
		upsells: null,
		defaultAppComponent: 'ShopApp',
		appComponent: 'ShopApp',
		loading: false,
		limitedTimeOffer: limitedTimeOffer(srv),
		loginLoading: false,
		googleLoginLoading: false,
		routeLoading: false,
		navbarHeight: 0,
		showWhatsappChat: false,
		ctaIsLoading: false,
	}

	const mutations = {
		...make.mutations(state),
		SET_LOGIN_DRAWER: (state, value) => {
			state.loginDrawer = value
			state.cartDrawer = false
			state.menuMobileDrawer = false
			state.offersDrawer = false
			if (typeof document != 'undefined') {
				document.documentElement.classList.toggle('drawer-opened', value)
			}
		},
		SET_CART_DRAWER: (state, value) => {
			state.cartDrawer = value
			state.loginDrawer = false
			state.menuMobileDrawer = false
			state.offersDrawer = false
			if (typeof document != 'undefined') {
				document.documentElement.classList.toggle('drawer-opened', value)
			}
		},
		SET_MENU_MOBILE_DRAWER: (state, value) => {
			state.menuMobileDrawer = value
			state.loginDrawer = false
			state.cartDrawer = false
			state.offersDrawer = false
			if (typeof document != 'undefined') {
				document.documentElement.classList.toggle('drawer-opened', value)
			}
		},
		SET_OFFER_DRAWER: (state, value) => {
			state.offersDrawer = value
			state.loginDrawer = false
			state.cartDrawer = false
			state.menuMobileDrawer = false
			if (typeof document != 'undefined') {
				document.documentElement.classList.toggle('drawer-opened', value)
			}
		},
	}

	const actions = make.actions(state)

	const getters = {
		...make.getters(state),
		getWhatsappConfig: (state, getters, rootState) => {
			const currentDay = new Date().toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()

			const agentIsOnSchedule = (agent) =>
				agent.active && agent[currentDay] && agentIsOnScheduleHours(agent)
			const agentIsOnScheduleHours = (agent) => {
				const start = agent[`${currentDay}ScheduleStart`]
				const end = agent[`${currentDay}ScheduleEnd`]

				const startTime = moment(start, 'HH:mm')
				const endTime = moment(end, 'HH:mm')

				return moment().isBetween(startTime, endTime)
			}

			return {
				get config() {
					return srv('whatsAppBtnConfig')
						?.filter((config) => urlMatch(config, rootState['route'].fullPath))
						.reduce((acc, config) => {
							const agents = config.agents?.filter(agentIsOnSchedule)
							if (!agents.length) return acc
							config.agents = agents
							acc.push(config)
							return acc
						}, [])
				},
				get hasActiveAgents() {
					return this.config.some((c) => c.agents.length)
				},
			}
		},
	}

	return {
		name: 'shop',
		namespaced: true,
		state,
		mutations,
		actions,
		getters,
	}
}

const limitedTimeOffer = (srv) => {
	const res = srv('limitedTimeOffer', [])

	if (!res.length) return null

	let units = {
		day: 86400,
		hour: 3600,
		minute: 60,
		second: 1,
	}

	return res.map((r) => {
		const { secondsLeft, collectionId, untilDate } = r

		let days = secondsLeft / units['day']
		let hours = (secondsLeft % units['day']) / units['hour']
		let minutes = (secondsLeft % units['hour']) / units['minute']
		let seconds = (secondsLeft % units['minute']) / units['second']

		return {
			days: Math.floor(days),
			hours: Math.floor(hours),
			minutes: Math.floor(minutes),
			seconds: Math.floor(seconds),
			secondsLeft,
			collectionId,
			untilDate,
		}
	})
}
