import Vue from 'vue'

export default (options) => {
	const { router, store, srv, window, apis, eventer } = options
	const { shopApi } = apis
	const Shop = {}
	Shop.search = async function (searchTerm) {
		searchTerm = searchTerm ? searchTerm.trim() : ''
		if (searchTerm.length <= 2) return
		eventer.trigger('search:search', { searchTerm })
		return this.goShop({ search: searchTerm })
	}

	Shop.getShopRoute = function ({ collection, categories, brand, search } = {}) {
		let filters = []
		if (!collection && !categories) {
			filters.push(process.env.VUE_APP_SHOP_ALL_KEYWORD)
		} else {
			if (collection) {
				filters.push(collection)
			}
			if (categories) {
				filters = filters.concat(categories)
			}
		}
		if (brand) {
			filters.push(`brand-${brand}`)
		}

		let query = {}
		if (search) query.search = search

		return { name: 'shop', params: { filters }, query }
	}

	Shop.goShop = async (options = {}) => router.push(Shop.getShopRoute(options))

	Shop.addToCart = async ({
		productId,
		variantId,
		type = 'product',
		customization = {},
		qty = 1,
		sum = true,
		showUpsells = true,
		showCart = true,
		...options
	}) => {
		return shopApi.post({
			...options,
			url: '/cart/item',
			data: {
				qty,
				refType: type,
				refId: variantId,
				customization,
				sum,
			},
			onSuccess: async (apiRes) => {
				const { result, order } = apiRes.data
				store.set('cart/order', order)
				if (result) {
					eventer.trigger(`cart:item-${result.type}`, {
						product: result.product,
						qty: result.qty,
						order,
					})
				}

				if (showUpsells) {
					let upsells = await Shop.getUpsells({
						productId,
						loading: false,
					})
					if (upsells?.length) {
						store.set('shop/upsells', upsells)
						apiRes.data.upsells = upsells
						await router.push({
							name: 'upsells',
							params: { id: productId },
						})
					} else {
						showCart && Shop.showCart()
					}
				} else {
					showCart && Shop.showCart()
				}

				await options.onSuccess?.call(null, apiRes)
			},
		})
	}

	Shop.showCart = (b = true) => {
		store.set('shop/cartDrawer', b)
	}

	Shop.getUpsells = async ({ productId, ...options }) => {
		let { data, success } = await shopApi.get({
			...options,
			url: `/catalog/product-upsells/${productId}`,
		})
		return success && data.upsellsProducts
	}

	Shop.changeLang = (langIso) => {
		if (langIso == srv('lang.iso') || !window) return
		window.location.href = router.resolve({
			query: { lang: langIso },
		}).href
	}

	options.shop = Shop
}

Vue.mixin({
	beforeCreate() {
		this.$shop = this.$options.shop || this.$root.$options.shop
	},
})
