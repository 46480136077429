import cloneDeep from 'lodash/cloneDeep'
import unset from 'lodash/unset'
import get from 'lodash/get'
import Vue from 'vue'

export default function createSrv(options) {
	const { ssr, window } = options
	let data = ssr ? ssr.serverData : cloneDeep(window.__SERVER_DATA__ || {})

	function srv(key, defaultValue) {
		let val = get(data, key)
		if (!val) return defaultValue
		else return typeof val == 'object' ? cloneDeep(val) : val
	}

	srv.extract = function (key, defaultValue) {
		let val = srv(key, defaultValue)
		unset(data, key)
		return val
	}

	options.srv = srv
}

Vue.mixin({
	beforeCreate() {
		this.$srv = this.$root.$options.srv
	},
})
