<script>
export default {
	data() {
		return {
			megaMenuType: this.$srv('AppBarConfig.megaMenuType'),
			selectedItem: null,
		}
	},
	mounted() {
		this.setupCategoryLinkColor()
	},
	computed: {
		megaMenuCategories() {
			return this.megaMenuType === 'vertical'
				? this.$srv('MegaMenu', [])
				: this.$srv('MegaMenuHorizontal', [])
		},
	},
	methods: {
		setupCategoryLinkColor() {
			const currentRoute = this.$route.path.split('/')[1]
			if (!currentRoute) return

			const index = this.megaMenuCategories.findIndex(
				(c) => c.category.urlName.indexOf(currentRoute) !== -1
			)
			this.selectedItem = index
		},
		getCategoryLink(category) {
			const hasChildren = category.category.children && category.category.children.length
			if (this.showSubitems && hasChildren) return null
			return this.$shop.getShopRoute({
				categories: [category.category.urlName],
			})
		},
		getAppendIcon(category) {
			if (!this.showSubitems) return 'mdi-chevron-right'
			return category.category.children ? 'mdi-chevron-down' : ''
		},
		selectItem(i) {
			this.selectedItem = i
		},
	},
}
</script>

<template>
	<div>
		<v-list-item
			:ref="`listItem${i}`"
			v-for="(item, i) of megaMenuCategories"
			:key="i"
			:disabled="!item.category.children"
			:append-icon="getAppendIcon(item)"
			:to="getCategoryLink(item)"
			@click="selectItem(i)"
		>
			<v-list-item-title :color="$vars.basetext" class="base font-1">
				{{ item.category.name }}
			</v-list-item-title>
			<v-list-item-action>
				<v-icon :color="$vars.basetext" size="25">mdi-chevron-right</v-icon>
			</v-list-item-action>
		</v-list-item>
		<div class="border mx-4 my-2"></div>
	</div>
</template>

<style scoped>
.border {
	border: thin solid var(--primary);
}
</style>

<style></style>
