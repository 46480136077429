<script>
import { get, sync } from 'vuex-pathify'
//import { createPortals } from '@/utils/utils'
import makeSummary from '#/shop-order/helpers/make-summary'

/*const CartItemPortals = createPortals(
	require.context('portals', true, /portal_CartItem(-.+)?\.vue/)
)*/

export default {
	//components: { CartItemPortals },
	name: 'comp_CartDrawer',
	lang: 'shop',
	data() {
		return {
			cartKey: 0,
		}
	},
	computed: {
		summary() {
			return makeSummary(this.order)
		},
		cartDrawer: sync('shop/cartDrawer'),
		order: get('cart/order'),
		joinedItems() {
			return this.summary.getJoinedItems()
		},
		items() {
			return this.order?.items
		},
		isCartEmpty() {
			return !this.order?.items?.length
		},
		drawerWidth() {
			return 700
		},
	},
	methods: {
		goToCheckout() {
			this.cartDrawer = false
			this.$router.push({ name: 'checkout' })
		},
		keepBuying() {
			this.cartDrawer = false
			this.$shop.goShop()
		},
	},
	watch: {
		items() {
			this.cartKey += 1
		},
	},
	cssVars: {
		selector: '.cart-drawer',
	},
}
</script>

<template>
	<!-- Seteando :styles="$vars.base" para pisar el prop dark/light de v-navigation-drawer -->
	<v-navigation-drawer
		v-model="cartDrawer"
		class="cart-drawer base pa-4 drawer-index"
		:color="$vars.base"
		right
		fixed
		:width="drawerWidth"
		max-width="100%"
		overlay-color="#000"
		temporary
		:overlay-opacity="0.8"
		touchless
	>
		<!-- <CartItemPortals :joined-items="joinedItems" v-if="order" /> -->
		<v-list-item class="px-2" :style="`color: ${$vars.basetext}`">
			<div class="font-weight-bold font-4 header">
				{{ 'Carrito' | lang }}
			</div>
			<v-spacer></v-spacer>
			<Button color="link" text @click="cartDrawer = false">
				<v-icon color="link">mdi-close</v-icon> {{ 'Cerrar' | lang }}
			</Button>
		</v-list-item>
		<v-divider />
		<v-list-item v-if="isCartEmpty" class="pt-4 d-flex justify-center" :style="`color: ${$vars.basetext}`">
			<div class="text-center font-weight-bold">
				{{ 'No hay productos en el Carrito' | lang }}
			</div>
		</v-list-item>
		<template v-else>
			<div class="px-sm-6" v-for="(jItem, i) of joinedItems" :key="`${cartKey}-${i}`">
				<CartItem :joined-item="jItem" :order="order" class="py-6" :index="i" />
				<v-divider v-if="i < joinedItems.length - 1" />
			</div>
			<div class="px-sm-6 pb-4">
				<OrderSummary-Totals :order="order" :summary="summary" />
			</div>
		</template>
		<v-list-item class="justify-center mb-4">
			<Button color="cta" :disabled="isCartEmpty" x-large @click="goToCheckout">
				{{ 'Finalizar compra' | lang }}
			</Button>
		</v-list-item>
		<v-list-item class="justify-center">
			<Button color="link" class="mt-2" text @click="keepBuying">
				{{ 'Seguir comprando' | lang }}
			</Button>
		</v-list-item>
	</v-navigation-drawer>
</template>
