import Vue from 'vue'

export default function createEventer(options) {
	const { ssr } = options
	const _all = []
	let _id = 0

	const Eventer = class {
		listen(name, listener, options = {}) {
			if (ssr) return
			let id = ++_id
			_all.push({ listener, id, name, options, component: this._component })
			if (this._component) this._listening = true
			return id
		}
		once(name, listener, options = {}) {
			options.once = true
			return this.listen(name, listener, options)
		}
		remove(id) {
			let idx = _all.findIndex((e) => e.id == id)
			if (idx >= 0) _all.splice(idx, 1)
		}
		removeBy(predicate) {
			_all.filter(predicate).forEach((e) => this.remove(e.id))
		}
		removeByEventName(name) {
			this.removeBy((e) => e.name == name)
		}
		async trigger(name, data) {
			if (ssr) return
			let events = _all.filter((e) => e.name == name)
			for (let e of events) {
				await e.listener(data)
				if (e.options.once) this.remove(e.id)
			}
		}
		_createComponentInstance(component) {
			let eventer = new Eventer()
			eventer._component = component
			return eventer
		}
	}

	options.eventer = new Eventer()
}

Vue.mixin({
	beforeCreate() {
		// only non root components
		if (!this.$parent) return

		this.$eventer = () => {
			if (!this.$_eventerInstance) {
				this.$_eventerInstance =
					this.$root.$options.eventer._createComponentInstance(this)
			}
			return this.$_eventerInstance
		}

		// $options.eventer can be true or object { [listenerName]: listenerFn }
		if (this.$options.eventsListeners) {
			for (let x in this.$options.eventsListeners) {
				this.$eventer().listen(
					x,
					this.$options.eventsListeners[x].bind(this)
				)
			}
		}
	},
	beforeDestroy() {
		if (this.$_eventerInstance?._listening) {
			this.$_eventerInstance.removeBy((e) => e.component == this)
		}
	},
})
