export default function (data, until) {
	let arr = []
	for (let n = 1; n <= until; n++) {
		let re = new RegExp(`[a-z_]${n}$`, 'i')
		let obj = {}
		for (let x in data) {
			if (x.match(re)) {
				obj[x.slice(0, -String(n).length)] = data[x]
			}
		}
		obj.n = n
		arr.push(obj)
	}
	return arr
}
