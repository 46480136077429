import Vue from 'vue'
import loadGtag from './gtag'
import loadFbq from './fbq'

export default (options) => {
	const { ssr, srv, window } = options
	const gtag = !ssr && loadGtag(srv, window)
	const fbq = !ssr && loadFbq(srv, window)

	const trackers = {
		gtag: gtag || (() => {}),
		gtagEnabled: !!gtag,
		fbq: fbq || (() => {}),
		fbqEnabled: !!fbq,
	}

	trackers.pageView = ({ title, location } = {}) => {
		if (ssr) return
		title = title || document.title
		location = location || window.location.href
		if (!location.startsWith(window.location.origin)) {
			location = `${window.location.origin}${location}`
		}

		trackers.gtag('event', 'page_view', {
			page_title: title,
			page_location: location,
		})
		trackers.fbq('track', 'PageView')
	}

	options.trackers = trackers
}

Vue.mixin({
	beforeCreate() {
		this.$trackers = this.$options.trackers || this.$root.$options.trackers
	},
	mounted() {
		if (this.$ssr) return
		if (this.$options.trackPageView) {
			let { title, location } = this.$options.trackPageView.call?.(this) || {}
			if (!title) this.$meta().refresh()
			this.$trackers.pageView({ title, location })
		}
	},
})
