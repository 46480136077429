<script>
import { sync } from 'vuex-pathify'
export default {
	props: {
		productId: String,
		height: {
			type: [String, Number],
			default: 40,
		},
		width: {
			type: [String, Number],
			default: 40,
		},
		iconSize: {
			type: String,
			default: 'default',
		},
	},
	computed: {
		wishlistIds: sync('shop/wishlistIds'),
		inWishlist: {
			get() {
				return this.wishlistIds.includes(this.productId)
			},
			set(val) {
				if (val) {
					this.wishlistIds = this.wishlistIds.concat(this.productId)
				} else {
					this.wishlistIds = this.wishlistIds.filter((id) => id !== this.productId)
				}
			},
		},
		icon() {
			return this.inWishlist ? 'mdi-heart' : 'mdi-heart-outline'
		},
	},
	methods: {
		toggleWishlist() {
			if (!this.$store.get('shop/user')) {
				this.$store.set('shop/loginDrawer', true)
				return
			}
			this.inWishlist = !this.inWishlist
			let action = this.inWishlist ? 'add' : 'remove'
			this.$shopApi.post({
				url: `/catalog/wishlist/${action}/${this.productId}`,
			})
		},
	},
}
</script>

<template>
	<!-- TODO: check color -->
	<Button
		@click.stop.prevent="toggleWishlist"
		style="background-color: #f5f5f5"
		class="share-button"
		fab
		icon
		:height="height"
		:width="width"
	>
		<v-icon color="grey" :size="iconSize">{{ icon }}</v-icon>
	</Button>
</template>
