<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],
	props: {
		position: String,
	},
	hooksKey: 'ProductCard',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div class="pb-2 h100" v-if="verticalCard">
		<v-hover v-slot="{ hover }">
			<v-card
				@mouseover="firstImageHover = true"
				@click="cardClick"
				:ripple="false"
				flat
				:class="[
					$bem('product-card-shadow-with-timer-vertical'),
					{
						'product-card-shadow-with-timer-vertical__positionRight': position == 'right',
						'product-card-shadow-with-timer-vertical__positionLeft': position == 'left',
					},
				]"
				class="h100 d-flex flex-column base overflow-hidden product-card"
			>
				<div :class="$bem('__image-cont')" v-if="!outOfStock">
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont')">
							<Media
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
							/>
						</div>
					</hook>

					<hook zone="event-icon">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
					</hook>

					<hook zone="offer" v-if="!timerProduct">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>
				</div>

				<div :class="$bem('__image-cont', 'no-stock')" v-else>
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
					</hook>

					<hook zone="event-icon">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
					</hook>

					<hook zone="offer" v-if="!timerProduct">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>
				</div>

				<div
					class="d-flex align-center px-2 mx-3 py-2"
					:class="{ 'no-stock': outOfStock, 'mt-2': !timerProduct }"
				>
					<hook zone="prevPrice" v-if="discountPct">
						<del class="grey--text">
							<PriceText
								:amount="prevPrice"
								:currency="currency"
								:currencyMargin="0"
								:class="[$bem('__prev-price'), timerProduct ? 'font-0' : 'font-1']"
								class="grey--text mr-2"
								priceClass="grey--text"
							/>
						</del>
					</hook>

					<hook zone="price">
						<PriceText
							:amount="price"
							:currency="currency"
							:class="[$bem('__price'), timerProduct ? 'font-4' : 'font-5']"
							class="price font-weight-bold"
						/>
					</hook>

					<hook zone="discount" v-if="discountPct > 0">
						<span
							:class="[$bem('__discount'), timerProduct ? 'font-1' : 'font-2']"
							class="discount font-weight-medium pa-2"
						>
							{{ discountPct }}% OFF
						</span>
					</hook>
				</div>
				<v-spacer />

				<hook zone="attrs&financingTags" v-if="financingTagText" :class="{ 'no-stock': outOfStock }">
					<div
						:class="[$bem('__financing'), timerProduct ? 'font-0' : 'font-1']"
						class="line-clamp-1 px-2 mx-3 font-weight-bold primary"
					>
						{{ financingTagText }}
					</div>
				</hook>

				<v-spacer v-if="financingTagText" />

				<router-link class="card-anchor header" :to="productRoute">
					<hook zone="name" :class="{ 'no-stock': outOfStock }">
						<div
							class="product-card__name line-clamp-2 font-weight-bold px-2 mx-3 header"
							:class="[timerProduct ? 'font-2' : 'font-3', { 'mb-4': !brand && !timerProduct }]"
						>
							{{ product.name }}
						</div>
					</hook>
				</router-link>

				<hook zone="brand" v-if="brand && !timerProduct" :class="{ 'no-stock': outOfStock }">
					<div :class="$bem('__brand')" class="font-0 line-clamp-1 px-2 mx-3 mb-4">
						{{ brand }}
					</div>
				</hook>

				<hook zone="wishlist-toggler" :class="{ 'no-stock': outOfStock }">
					<ProductWishlistToggler
						:productId="product.id"
						:class="$bem('__wishlist-toggler')"
						style="z-index: 1"
					/>
				</hook>

				<hook zone="offerTimerProduct" :class="{ 'no-stock': outOfStock }" v-if="timerProduct">
					<div :class="[$bem('__offerTimerProduct'), 'base pt-1 mt-5']">
						<LimitedTimeOfferCountdown :limitedTimeOffer="limitedTimeOffer" class="mt-3" timerCard />
						<div class="mt-2 pb-2">
							<p
								class="text-uppercase text-center line-clamp-3 mb-0 font-weight-medium py-1 mx-3 px-2 rounded font-0"
							>
								{{ limitedTimeOfferDate }}
							</p>
						</div>
					</div>
				</hook>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD FOR MOBILE -->
	<div class="w100" v-else>
		<v-card
			flat
			@click="cardClick"
			:ripple="false"
			:class="[
				$bem('product-card-shadow-with-timer-horizontal'),
				{ 'product-card-shadow-with-timer-horizontal__noTimer': !timerProduct && $b.t },
			]"
			class="d-flex overflow-hidden base product-card pt-2 flex-column"
		>
			<div class="d-flex overflow-hidden py-2">
				<div :class="[$bem('__image-cont', 'col col-5 pa-0'), { 'no-stock': outOfStock }]">
					<hook zone="imageHorizontal">
						<Media
							:class="$bem('__main-image', 'px-1')"
							:src="mainImage"
							:width="$b.m ? '100%' : '200px'"
							:maxHeight="$b.t ? '200px' : ''"
						/>
					</hook>

					<hook zone="offerHorizontal" v-if="!timerProduct">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>

					<hook zone="event-iconHorizontal">
						<CollectionEventIcon
							:product="product"
							:class="$bem('__event-icon')"
							:imgWidth="$b.t ? '40px' : '30px'"
						/>
					</hook>
				</div>

				<div
					class="col col-7 py-0 pl-1 d-flex flex-column justify-center"
					:class="{ 'no-stock': outOfStock }"
				>
					<div class="d-flex align-center px-2 py-1">
						<hook zone="prevPriceHorizontal" v-if="discountPct">
							<del class="grey--text">
								<PriceText
									:amount="prevPrice"
									:currency="currency"
									:currencyMargin="0"
									:class="$bem('__prev-price')"
									:priceClass="timerProduct ? 'font-0 grey--text mr-2' : 'font-1 grey--text mr-2'"
								/>
							</del>
						</hook>

						<hook zone="priceHorizontal">
							<PriceText
								:amount="price"
								:currency="currency"
								:class="[$bem('__price'), discountPct && 'px-1', timerProduct ? 'font-4' : 'font-5']"
								class="price line-clamp-1"
							/>
						</hook>

						<hook zone="discountHorizontal" v-if="discountPct > 0">
							<span
								:class="[$bem('__discount'), timerProduct ? 'font-1' : 'font-2']"
								class="d-inline-block font-weight-medium discount px-2"
								v-if="discountPct > 0"
							>
								{{ discountPct }}% OFF
							</span>
						</hook>
					</div>

					<hook
						zone="attrs&financingTagsHorizontal"
						v-if="financingTagText"
						:class="{ 'no-stock': outOfStock }"
					>
						<div
							:class="
								$bem(
									'__financing',
									'line-clamp-1 mx-2 font-weight-bold primary',
									timerProduct ? 'font-0' : 'font-1'
								)
							"
						>
							{{ financingTagText }}
						</div>
					</hook>

					<router-link class="card-anchor header" :to="productRoute">
						<hook zone="nameHorizontal">
							<div :class="$bem('__name')" class="line-clamp-2 px-2 header font-weight-regular font-2">
								{{ product.name }}
							</div>
						</hook>
					</router-link>

					<hook zone="brandHorizontal" v-if="brand && !timerProduct">
						<div :class="$bem('__brand')" class="font-0 line-clamp-1 px-2 mb-4">
							{{ brand }}
						</div>
					</hook>
				</div>

				<hook zone="wishlist-togglerHorizontal">
					<ProductWishlistToggler
						:productId="product.id"
						:class="$bem('__wishlist-toggler')"
						:height="30"
						:width="30"
						iconSize="25px"
						style="z-index: 1"
					/>
				</hook>
			</div>

			<hook zone="offerHorizontalTimerProduct" :class="{ 'no-stock': outOfStock }" v-if="timerProduct">
				<div :class="[$bem('__offerTimerProduct'), 'base pt-1']">
					<LimitedTimeOfferCountdown :limitedTimeOffer="limitedTimeOffer" timerCard class="mt-2" />
					<div class="mt-2">
						<p
							class="text-uppercase line-clamp-3 mb-0 font-weight-medium pt-1 pb-2 px-2 rounded font-0 text-center"
						>
							{{ limitedTimeOfferDate }}
						</p>
					</div>
				</div>
			</hook>
		</v-card>
	</div>
	<!-- END OF HORIZONTAL CARD FOR MOBILE -->
</template>

<style lang="scss" scoped>
.product-card-shadow-with-timer-vertical {
	position: relative;
	cursor: pointer;
	text-align: left;
	justify-content: space-between;
	// border-bottom: 15px solid var(--secondary);

	// &__timerProduct {
	// 	border-bottom: transparent;
	// }

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__event-icon {
		position: absolute;
		bottom: 0;
		left: 10px;
		width: 40px;
		z-index: 1;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 1;
	}

	&__name {
		line-height: 1.6rem !important;
	}

	&__prev-price {
		line-height: normal;
	}

	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 10px;
		left: 10px;
		border-bottom-right-radius: 4px;
	}

	&__offerTimerProduct {
		border-top: 2px solid var(--offertext);
	}

	&__offer {
		position: absolute;
		bottom: 0;
		z-index: 1;
	}

	&__positionRight {
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		// border-left: 6px solid var(--secondary);
	}

	&__positionLeft {
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		// border-right: 6px solid var(--secondary);
	}
}

.product-card-shadow-with-timer-horizontal {
	// border-left: 4px solid var(--secondary);

	// &__noTimer {
	// 	border-bottom: 15px solid var(--secondary);
	// }

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__main-image {
		left: 50%;
		transform: translateX(-50%);
	}

	&__event-icon {
		position: absolute;
	}

	@media (min-width: 601px) {
		&__event-icon {
			width: 40px;
			bottom: 0px;
			left: 6px;
		}
	}

	@media (max-width: 600px) {
		&__event-icon {
			bottom: 0px;
			left: 6px;
			width: 30px;
			z-index: 1;
		}
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__discount {
		font-size: 0.9rem;
		position: absolute;
		z-index: 1;
		top: 6px;
		left: 0;
		border-bottom-right-radius: 4px;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 1;
	}

	&__name {
		line-height: 1.7rem !important;
	}

	&__offerTimerProduct {
		border-top: 2px solid var(--offertext);
	}

	&__offer {
		position: absolute;
		bottom: 0px;
		left: 6px;
		z-index: 2;
	}
}

.no-stock {
	opacity: 0.4;
}

a.card-anchor:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 2;
	left: 0;
}
</style>
