<script>
export default {
	data() {
		return {
			seconds: 0,
			minutes: 0,
			hours: 0,
			days: 0,
			intervalId: 0,
		}
	},
	props: {
		limitedTimeOffer: Object,
		timerCard: { type: Boolean, default: false },
	},
	computed: {
		time() {
			return this.limitedTimeOffer
		},
	},
	methods: {
		startTimer() {
			let units = {
				day: 86400,
				hour: 3600,
				minute: 60,
				second: 1,
			}
			this.intervalId = setInterval(() => {
				this.time.secondsLeft--

				this.days = Math.floor(this.time.secondsLeft / units['day'])
				this.hours = Math.floor((this.time.secondsLeft % units['day']) / units['hour'])
				this.minutes = Math.floor((this.time.secondsLeft % units['hour']) / units['minute'])
				this.seconds = Math.floor((this.time.secondsLeft % units['minute']) / units['second'])
			}, 1000)
		},
	},
	created() {
		this.startTimer()
	},
	beforeDestroy() {
		clearInterval(this.intervalId)
	},
}
</script>

<template>
	<v-row class="font-weight-bold ma-0" :class="{ 'mx-1': timerCard, 'offer--text': !timerCard }">
		<v-col
			v-if="days > 0"
			:class="{ 'mb-0': $b.m, 'marginTimerCard offer': timerCard }"
			class="text-center"
		>
			<p class="line-clamp-1 mb-0" :class="{ 'font-1': $b.m, 'font-2': $b.td }">{{ days }}</p>
			<p class="text-uppercase line-clamp-1 font-0 mb-0">días</p>
		</v-col>

		<v-col
			v-if="hours >= 0"
			:class="{ 'mb-0': $b.m, 'marginTimerCard offer': timerCard }"
			class="text-center"
		>
			<p class="line-clamp-1 mb-0" :class="{ 'font-1': $b.m, 'font-2': $b.td }">{{ hours }}</p>
			<p class="text-uppercase line-clamp-1 font-0 mb-0">hs</p>
		</v-col>

		<v-col
			v-if="minutes >= 0"
			:class="{ 'mb-0': $b.m, 'marginTimerCard offer': timerCard }"
			class="text-center"
		>
			<p class="line-clamp-1 mb-0" :class="{ 'font-1': $b.m, 'font-2': $b.td }">{{ minutes }}</p>
			<p class="text-uppercase line-clamp-1 font-0 mb-0">min</p>
		</v-col>

		<v-col :class="{ 'mb-0': $b.m, 'marginTimerCard offer': timerCard }" class="text-center">
			<p class="line-clamp-1 mb-0" :class="{ 'font-1': $b.m, 'font-2': $b.td }">{{ seconds }}</p>
			<p class="text-uppercase line-clamp-1 font-0 mb-0">seg</p>
		</v-col>
	</v-row>
</template>

<style scoped>
.marginTimerCard {
	margin: 0 2px;
}
</style>
