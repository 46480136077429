<script>
import { get } from 'vuex-pathify'
import { generateProductSchema } from './product-schema'
import productMixin from '@/v-shop/mixins/product-mixin'

export default {
	lang: 'shop',
	mixins: [productMixin('page')],
	hooksKey: 'ProductPage',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-page',
	},
	metaInfo() {
		if (!this.product) return

		let title = [this.product.name]
		if (!this.product.hasUniqueVariant) {
			title.push(...this.selectedVariant.attrs.map((attr) => attr.v))
		}
		title = title.join(' - ')

		let description = this.product.info?.description || this.product.name
		let productSchema = generateProductSchema(this.$router, this.product, this.selectedVariant)
		let script = [{ json: productSchema, type: 'application/ld+json', vmid: 'ldjson-schema-product' }]
		let link = [{ rel: 'canonical', href: this.$router.resolve(this.$route).href }]
		return {
			title,
			meta: [{ vmid: 'd', name: 'description', content: description }],
			script,
			link,
		}
	},

	data() {
		return {
			product: null,
			relatedProducts: [],
			selectedVariantId: null,
			customization: {},
			validation: {},
			//collectionId: this.$srv('ProductAdditionalInfo.collectionId', null),
			// info: this.$srv('EnabledPaymentMethods'),
			discountTags: this.$srv('discountTags', []),
			imgIndex: 0,
			colSizes: {
				left: 7,
				right: 5,
			},
			hideStockAvailabilityMessage: this.$srv('shopOptions')?.hideStockAvailabilityMessage,
		}
	},
	computed: {
		routeId: get('route@params.id'),
		routeVariantId: get('route@query.variant'),
		selectedVariant() {
			return this.product.variants.find((x) => x.id == this.selectedVariantId)
		},
		breadcrumbs() {
			const categories = this.product.categories.map((category) => ({
				text: category.name,
				route: this.$shop.getShopRoute({ categories: category.familyUrlNames }),
			}))
			const first = {
				text: 'Todos los productos',
				route: this.$shop.getShopRoute(),
			}
			const last = {
				text: this.product.name,
			}
			return [first, ...categories, last]
		},
		attributes() {
			return this.product.attrs || []
		},
		marketplaceMode() {
			return process.env.VUE_APP_MARKETPLACE_MODE
		},
	},
	watch: {
		$route() {
			this.onProductLoaded()
		},
		selectedVariantId(value, oldValue) {
			if (!oldValue || this.product.hasUniqueVariant) return
			if (value == this.$route.query.variant) return

			this.$router.push({
				params: { ...this.$route.params, scrollPosition: 'keep' },
				query: { ...this.$route.query, variant: value },
			})
		},
	},
	methods: {
		async onProductLoaded() {
			if (!this.product) return

			const variant = this.product.variants.find((x) => x.id == this.selectedVariantId)
			if (variant.imageIdx) {
				this.imgIndex = variant.imageIdx - 1
			}

			if (this.product.urlName != this.$route.params.urlName) {
				await this.$router.replace({
					params: { ...this.$route.params, urlName: this.product.urlName },
				})
			}
			await this.autoAddToCart()

			this.$meta().refresh()
			this.$trackers.pageView()
			this.$eventer().trigger('product:view', {
				variant: this.selectedVariant,
				product: this.product,
			})
		},
		async autoAddToCart() {
			if (this.$route.query['add-to-cart'] != 1) return
			await this.$shopApi.post({
				url: '/cart/item',
				loading: false,
				data: {
					refType: 'product',
					refId: this.selectedVariant.id,
					qty: 1,
				},
				onSuccess: async ({ data }) => {
					this.$store.set('cart/order', data.order)
				},
			})
			await this.$router.replace({
				query: { ...this.$route.query, 'add-to-cart': undefined },
			})
			this.$store.set('shop/cartDrawer', true)
		},
	},
	mobileReload(context, to, from) {
		return to.name != from.name || to.params.id != from.params.id
	},
	async resolveData(options, to, from, next) {
		let { ssr, apis, router, store } = options
		if (!ssr && to.name == from.name && to.params.id == from.params.id) {
			return
		}

		let { success, data } = await apis.shopApi.get({
			loading: (v) => store.set('shop/routeLoading', v),
			url: `/catalog/product/${to.params.id}`,
		})

		if (success) {
			let selectedVariantId = to.query.variant
			let mainVariantId = data.product.variants.find((x) => x.forDisplay).id
			let redirect = false

			if (!data.product.hasUniqueVariant && !selectedVariantId) {
				to.query.variant = mainVariantId
				selectedVariantId = mainVariantId
				redirect = true
			} else if (data.product.hasUniqueVariant && selectedVariantId) {
				to.query.variant = undefined
				selectedVariantId = mainVariantId
				redirect = true
			} else if (selectedVariantId && !data.product.variants.some((x) => x.id == selectedVariantId)) {
				to.query.variant = mainVariantId
				selectedVariantId = mainVariantId
				redirect = true
			} else if (!selectedVariantId) {
				selectedVariantId = mainVariantId
			}

			if (redirect) {
				if (ssr) {
					ssr.responseSent = true
					ssr.res.redirect(301, router.resolve(to).href)
					return next(false)
				} else {
					next(to)
				}
			}

			const variant = data.product.variants.find((x) => x.id == selectedVariantId)
			const imgIndex = Math.max(0, variant.imageIdx - 1)
			return {
				product: data.product,
				relatedProducts: data.relatedProducts,
				selectedVariantId,
				imgIndex,
			}
		} else {
			/* if (message.code == 'not_found') */
			return { product: null }
		}
	},
	mounted() {
		this.onProductLoaded()
	},
}
</script>

<template>
	<NotFoundPage v-if="!product" />
	<Container v-else class="product-page" :key="product.id">
		<hook zone="top" />
		<Breadcrumbs class="py-5" :items="breadcrumbs" />
		<ProductPage-MainContainer>
			<hook zone="top-inside" />
			<div class="pb-3">
				<v-row>
					<v-col
						cols="12"
						:md="colSizes.left"
						class="no-mobile-px"
						:class="$b({ d: 'grey-border-right' })"
					>
						<hook zone="images">
							<ProductImagesCarousel v-model="imgIndex" :scale-down="false" :product="product" />
						</hook>
					</v-col>
					<v-col cols="12" :md="colSizes.right">
						<div class="px-0 px-md-6">
							<v-row>
								<v-col cols="10">
									<h1 class="header font-weight-bold font-6 py-1">{{ product.name }}</h1>
									<hook zone="brand-sku">
										<div class="d-flex align-center base--text">
											<div v-if="product.brand" class="mr-2">
												Marca: <span class="font-weight-bold">{{ product.brand.name }}</span>
											</div>
											<div>
												Sku: <span class="font-weight-bold">{{ selectedVariant.sku }}</span>
											</div>
										</div>
										<div class="my-2" v-if="marketplaceMode">
											<div v-if="product.seller.logo">
												<Media :src="product.seller.logo" />
											</div>
											<div>
												Producto vendido por
												<span class="font-weight-bold">{{
													product.seller.name.toUpperCase()
												}}</span>
											</div>
										</div>
									</hook>
								</v-col>
								<v-col cols="2" class="text-right">
									<div
										class="wishlist-share w100 h100 d-flex flex-column justify-space-around"
										:class="{ 'align-end': $b.t }"
									>
										<ProductWishlistToggler
											class="flex-grow mb-3"
											:productId="product.id"
											:heartWidth="25"
										/>
										<ShareProduct :productName="product.name" />
									</div>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="d-flex flex-wrap align-center icons-offer">
									<CollectionEventIcon
										multiple
										:product="product"
										class="event-icon"
										:limitAmount="$b.m ? 5 : null"
									/>
									<ProductPage-LimitedTimeOffer :product="product" />
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<div style="position: relative">
										<VariantsSelector
											v-model="selectedVariantId"
											:product="product"
											class="pb-3"
											:show-select-input="true"
										/>
										<hook zone="price-layout">
											<PriceLayout
												:prev-price="selectedVariant.pvPrice.prevPrice"
												:price="selectedVariant.pvPrice.price"
												:discount-pct="selectedVariant.pvPrice.discountPct"
												:unit-metric="product.packMetric ? product.unitMetric : null"
												:currency="selectedVariant.pvPrice.priceConfig.currency.sign"
												price-class="font-8"
											/>
										</hook>
										<div
											class="my-2 py-1 px-2 primary rounded"
											style="width: fit-content"
											v-if="financingTagText"
										>
											{{ financingTagText }}
										</div>
										<div
											class="my-2 py-1 px-2 secondary rounded"
											style="width: fit-content"
											v-for="(item, i) of discountsTagsTexts"
											:key="i"
										>
											{{ item }}
										</div>

										<!-- TODO: Create enabledPaymentMethods modal -->
										<!-- <div class="pb-3">
											<Button text class="link--text pa-0 font-weight-medium" small
												>Ver todos los medios de pago</Button
											>
										</div> -->

										<div class="py-2 font-1">
											<ProductStockAvailabilityMessage
												v-if="!hideStockAvailabilityMessage"
												:stock="selectedVariant.stock"
												:product="product"
												class="py-1"
											/>
											<div
												class="d-flex align-center py-1"
												v-if="selectedVariant.type == 'digital'"
											>
												<v-icon class="mr-2">mdi-cloud-download</v-icon>
												{{ 'Producto digital descargable' | lang }}
											</div>
										</div>
										<hook zone="actions">
											<ProductActions
												:product="product"
												:variant="selectedVariant"
												:customization="customization"
												:ctaText="buttonText"
												@validation="validation = $event"
												class="py-3"
											/>
										</hook>
										<div
											class="my-1 pt-2 mx-n3 px-3 mx-md-n9 px-md-9"
											style="border-top: 1px solid #eee"
										>
											<ProductPage-ProductShipmentInfo
												:currency="selectedVariant.pvPrice.priceConfig.currency.sign"
												:selectedVariantId="selectedVariantId"
											/>
										</div>
										<div>
											<ProductPage-AdditionalPaymentInfo :product="product" />
										</div>
									</div>
								</v-col>
							</v-row>
						</div>
					</v-col>
				</v-row>
			</div>

			<hook zone="bottom-inside" />
		</ProductPage-MainContainer>
		<hook zone="bottom" />

		<hook zone="related-products">
			<div class="product-page__related py-12" v-if="relatedProducts.length">
				<v-container class="pb-4 font-3 header font-weight-bold">
					{{ 'También te puede interesar' | lang }}
				</v-container>
				<v-container>
					<ProductsCarousel
						:products="relatedProducts"
						list-id="related-products"
						:dotContainerFullWidthMobile="relatedProducts.length >= 15"
						:arrowsBgHalfRounded="$b.mt"
					/>
				</v-container>
			</div>
		</hook>
	</Container>
</template>

<style>
.shadow {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

::v-deep iframe {
	max-width: 100%;
	widows: 500px;
	min-height: 250px;
}

.divider,
.divider-small {
	margin-right: -12px;
	margin-left: -12px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
	.divider-small {
		margin-right: -36px;
		margin-left: -36px;
	}
}

.grey-border-top {
	border-top: 1px solid #eee;
}

.grey-border-right {
	border-right: 1px solid #eee;
}

.grey-border-bottom {
	border-bottom: 1px solid #eee;
}

.grey-border-left {
	border-left: 1px solid #eee;
}

.bordered {
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
}
.wishlist-share {
	max-height: 102px;
	max-width: 102px;
}

.icons-offer {
	padding-top: 0;
	padding-bottom: 0;
	gap: 6px;
}
</style>
