<script>
import { sync } from 'vuex-pathify'

export default {
	lang: 'shop',
	data() {
		return {
			data: this.$srv('offerDrawer'),
			waveStyler: null,
		}
	},
	computed: {
		offersDrawer: sync('shop/offersDrawer'),
		listId() {
			return `${this.data.collectionId}-drawer-ofertas`
		},
		btnStyler() {
			let position = this.data.position == 'left' ? { left: '20px' } : { right: '20px' }
			let bgColor = { backgroundColor: this.data.btnColor }
			let radius = this.data.btnType == 'iconText' ? { borderRadius: '40px' } : { borderRadius: '50%' }
			return [position, bgColor, radius]
		},
		textColor() {
			return this.data.btnType == 'iconText' ? { color: this.data.textColor } : null
		},
	},
	methods: {
		openBtn() {
			this.offersDrawer = true
			this.$eventer().trigger('product-list:view', {
				products: this.data.products,
				listId: this.listId,
			})
		},
		waveColor(n) {
			if (this.data.iconType == 'icon') {
				return {
					background: this.modifyColor(
						this.data.iconColor == '#FFFFFF' ? this.data.btnColor : this.data.iconColor,
						n
					),
				}
			} else {
				if (this.data.btnType == 'iconText') {
					return {
						background: this.modifyColor(
							this.data.textColor == '#FFFFFF' ? this.data.btnColor : this.data.textColor,
							n
						),
					}
				} else {
					return {
						background: this.modifyColor(this.data.btnColor, n),
					}
				}
			}
		},
		modifyColor(color, amount) {
			const clamp = (val) => Math.min(Math.max(val, 0), 0xff)
			const fill = (str) => ('00' + str).slice(-2)
			const num = parseInt(color.substr(1), 16)
			const red = clamp((num >> 16) + amount)
			const green = clamp(((num >> 8) & 0x00ff) + amount)
			const blue = clamp((num & 0x0000ff) + amount)
			return '#' + fill(red.toString(16)) + fill(green.toString(16)) + fill(blue.toString(16))
		},
	},
	mounted() {
		if (!this.data) return
		this.$nextTick(() => {
			if (!this.$refs.btn?.$el) return

			let { clientWidth, clientHeight } = this.$refs.btn.$el
			let position = this.data.position == 'left' ? { left: '20px' } : { right: '20px' }

			this.waveStyler = {
				width: `${clientWidth}px`,
				height: `${clientHeight}px`,
				borderRadius: this.data.btnType == 'justIcon' ? '50%' : '40px',
				...position,
			}
		})
	},
}
</script>

<template>
	<DeviceMatch :data="data">
		<div v-if="data">
			<Button
				@click="openBtn"
				:icon="data.btnType == 'justIcon'"
				class="offer-drawer-btn"
				v-bind:style="btnStyler"
				:width="data.btnType == 'justIcon' ? '60px' : 'fit-content'"
				:height="data.btnType == 'justIcon' ? '60px' : '45px'"
				ref="btn"
			>
				<div v-if="data.btnType == 'justIcon'" class="pa-2">
					<Media :src="data.btnImg" v-if="data.iconType == 'img'" />
					<v-icon v-else size="40" :color="data.iconColor">{{ data.btnIcon }}</v-icon>
				</div>

				<div class="d-flex align-center justify-center" v-else>
					<Media :src="data.btnImg" v-if="data.iconType == 'img'" width="20px" class="mr-2" />
					<v-icon :color="data.iconColor" v-else>{{ data.btnIcon }}</v-icon>
					<div v-bind:style="textColor" class="font-weight-bold">{{ data.btnText }}</div>
				</div>
			</Button>
			<span class="wave1 waves" v-bind:style="{ ...waveColor(52), ...waveStyler }"></span>
			<span class="wave2 waves" v-bind:style="{ ...waveColor(32), ...waveStyler }"></span>
			<span class="wave3 waves" v-bind:style="{ ...waveColor(12), ...waveStyler }"></span>
		</div>
	</DeviceMatch>
</template>

<style>
.offer-drawer-btn {
	position: fixed;
	bottom: 20px;
	z-index: 3;
}

.waves {
	position: fixed;
	bottom: 20px;
	transform-origin: center center;
	z-index: 2;
}

.wave1 {
	animation: waves 2.5s linear;
	animation-delay: 0.1s;
	animation-iteration-count: infinite;
}

.wave2 {
	animation: waves 2.5s linear 0.7s forwards;
	animation-iteration-count: infinite;
}

.wave3 {
	animation: waves 2.5s linear 1.3s forwards;
	animation-iteration-count: infinite;
}

@keyframes waves {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(1.3);
		opacity: 0;
	}
}

@media (max-width: 900px) {
	.offer-drawer-btn {
		bottom: 80px;
	}

	.waves {
		bottom: 80px;
	}
}
</style>
