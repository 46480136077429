export default function (config, currentRoute) {
	const fns = {
		exact: (pattern) => currentRoute === pattern,
		pattern: (pattern) => currentRoute.startsWith(pattern),
		regex: (pattern) => new RegExp(pattern).test(currentRoute),
	}

	return shouldShow()

	function shouldShow() {
		if (config.showAll) return currentUrlHide()
		return currentRoutePasses() && currentUrlHide()
	}

	function someFn(condition) {
		return fns[condition.urlCondition]?.(condition.pattern)
	}

	function currentRoutePasses() {
		return config.urlShowPatterns ? config.urlShowPatterns.some(someFn) : true
	}

	function currentUrlHide() {
		return config.urlHidePatterns ? !config.urlHidePatterns.some(someFn) : true
	}
}
