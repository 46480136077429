<script>
import { get } from 'vuex-pathify'

export default {
	props: {
		product: Object,
		textAlign: { type: String, default: 'center' },
	},
	computed: {
		limitedTimeOffer: get('shop/limitedTimeOffer'),
		productOffers() {
			return this.limitedTimeOffer?.filter((offer) =>
				this.product.collectionsIds.includes(Number(offer.collectionId))
			)
		},
		offer() {
			return this.productOffers[0]
		},
		hasLimitedTimeOffer() {
			return !!this.productOffers?.length
		},
		limitedTimeOfferMsg() {
			if (this.hasLimitedTimeOffer) {
				if (this.offer.hours == 0 && this.offer.days == 0) {
					return `${this.offer.minutes}m`
				} else if (this.offer.days == 0) {
					return `${this.offer.hours}h ${this.offer.minutes}m`
				} else {
					return `${this.offer.days}d ${this.offer.hours}h`
				}
			}
			return null
		},
		alignment() {
			switch (this.textAlign) {
				case 'center':
					return 'text-center'

				case 'end':
					return 'text-end'

				default:
					return 'text-start'
			}
		},
	},
}
</script>

<template>
	<div v-if="hasLimitedTimeOffer" class="offer font-weight-medium font-0 py-1 w100" :class="alignment">
		<!-- <span class="mr-1">
			<v-icon class="offer" small>mdi-flash</v-icon>
		</span> -->
		<span
			>Termina en <strong>{{ limitedTimeOfferMsg }}</strong></span
		>
	</div>
</template>
