<template>
	<div
		class="py-2 my-1 mx-n3 px-3 mx-md-n9 px-md-9"
		style="border-top: 1px solid #eee"
		v-if="hasMsg && hasInfo"
	>
		<ShipmentInfoItem v-for="i in info" :key="i.id" :item="i" />
	</div>
</template>

<script>
export default {
	props: {
		product: Object,
	},
	data() {
		return {
			collectionId: this.$srv('ProductAdditionalInfo.collectionId'),
			info: this.$srv('ProductAdditionalInfo.info'),
		}
	},
	computed: {
		hasInfo() {
			return this.info.length > 0
		},
		hasMsg() {
			return this.product.collectionsIds.includes(Number(this.collectionId))
		},
	},
}
</script>

<style></style>
