<template>
	<div>
		<ShipmentInfoItem :item="{ text: 'Calcular envío', icon: 'mdi-truck-fast-outline' }" />
		<div style="max-width: 250px; max-height: 100px" class="mb-2 d-flex">
			<div class="d-flex">
				<label for="zipcode" id="zipcode">
					<input
						v-model.number="zipCode"
						class="zipcode-input font-0 pa-1"
						type="number"
						id="zipcode"
						placeholder="Código postal"
						@keydown.enter="calculate"
					/>
				</label>
				<Button
					:disabled="!hasZipCode"
					class="rounded-tl-0 rounded-bl-0 shipment-btn"
					style="min-height: 0"
					:loading="loading"
					@click="calculate"
					small
					>Calcular</Button
				>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		loading: Boolean,
	},
	data() {
		return {
			zipCode: this.getZipCodeFromLocalStorage() || null,
		}
	},
	computed: {
		hasZipCode() {
			return this.zipCode > 0
		},
	},
	methods: {
		getZipCodeFromLocalStorage() {
			return Number(this.$window?.localStorage.getItem('zipcode'))
		},
		calculate() {
			// emit and store...
			this.$emit('calculate', this.zipCode)
			this.saveToLocalStorage()
		},
		saveToLocalStorage() {
			this.$window?.localStorage.setItem('zipcode', this.zipCode)
		},
	},
}
</script>

<style lang="scss">
div.v-input__append-outer {
	margin: 0 !important;
}
</style>

<style scoped lang="scss">
.zipcode-input {
	max-width: 165px;
	min-height: 20px;
	border: 1px solid #ccc;
	&:focus-visible {
		outline: none !important;
		border: 1px solid #b5b4b4;
	}
}

.shipment-btn {
	background: grey !important;
	color: white !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
</style>
