<template>
	<div>
		<h2 class="font-3 pl-2 my-4 font-weight-bold">Información Adicional</h2>
		<div class="shadow">
			<v-tabs
				v-model="tab"
				:background-color="$vars.secondary"
				:color="$vars.secondarytext"
				active-class="active-tab"
				hide-slider
				center-active
			>
				<v-tab
					v-for="(item, i) in items"
					:key="i"
					class="font-2 font-weight-bold"
					style="text-transform: none"
					el="h3"
				>
					<h3>
						{{ item.title }}
					</h3>
				</v-tab>
			</v-tabs>

			<v-tabs-items v-model="tab">
				<v-tab-item eager v-for="(item, i) in items" :key="i">
					<v-card flat>
						<div v-html="item.richDescription" class="px-6 py-8" v-if="item.useRichText"></div>
						<div class="font-1 px-6 py-8 pre-line" v-else>{{ item.content }}</div>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		items: Array,
	},
	data() {
		return {
			tab: null,
		}
	},
}
</script>

<style lang="scss" scoped>
.pre-line {
	white-space: pre-line;
}
// ::v-deep
// 	.v-tabs:not(.v-tabs--vertical).v-tabs--right
// 	> .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
// 	.v-slide-group__next {
// 	display: contents;
// }

::v-deep .v-item-group {
	border-radius: 5px 5px 0px 0px;
}

::v-deep .active-tab {
	background: var(--base);
	border-top: 3px solid var(--offer);
}

::v-deep .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
	color: var(--secondary--text) !important;
}
</style>
