<script>
import SmartPopupBase from '#/v-shop/vue-src/v-shop/common/comp_SmartPopupCenter.vue'

export default {
	extends: SmartPopupBase,
	computed: {
		imageStyle() {
			const img = this.$b({ m: this.popupConfig.bgImageMobile, d: this.popupConfig.bgImageTop })
			return {
				'background-image': `url(${this.$media(img).src})`,
				'background-position': 'center center',
				'background-size': 'cover',
			}
		},
	},
}
</script>

<template>
	<transition name="fade">
		<div :class="storageKey" class="elevation-24 base fixed-bottom-right" v-if="showTemplate && show">
			<div v-if="!popupConfig.useBgFullImage">
				<Button class="close" :color="$vars.secondary" icon @click="show = false">
					<v-icon>mdi-close</v-icon>
				</Button>
				<div>
					<div v-if="popupConfig.bgImageTop" class="pa-12" :style="imageStyle"></div>
					<div class="py-4 px-14">
						<div class="my-6 d-flex justify-center" v-if="popupConfig.insideImage">
							<Media width="100%" :src="popupConfig.insideImage" />
						</div>
						<div class="my-6 text-center">
							<h1 class="header font-4 mb-2">{{ popupConfig.title }}</h1>
							<p>{{ popupConfig.text }}</p>
						</div>
						<SmartPopup-Form
							v-if="popupConfig.enableCta"
							:popupConfig="popupConfig"
							@ctaClick="ctaClick"
						/>
					</div>
				</div>
			</div>
			<div v-else class="base" style="position: relative">
				<Button class="close" style="right: 0" :color="$vars.secondary" icon @click="show = false">
					<v-icon>mdi-close</v-icon>
				</Button>
				<SafeLink :to="popupConfig.ctaUrl">
					<Media :class="storageKey" :src="popupConfig.bgFullImage" @click="ctaClick"> </Media>
				</SafeLink>
			</div>
		</div>
	</transition>
</template>

<style scoped>
::v-deep .v-label,
.v-input ::v-deep input {
	color: var(--primarytext);
}

.fade-enter-active,
.fade-leave-active {
	transition: all 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	transform: translateX(40px);
	opacity: 0;
}

.fixed-bottom-right {
	position: fixed;
	right: 0;
	bottom: 0;
	max-width: 700px;
	width: 100%;
}

.close {
	position: absolute;
	top: 15px;
	right: 15px;
	color: #000;
	opacity: 1;
	text-shadow: none;
	font-size: 24px;
	z-index: 999;
}
</style>
