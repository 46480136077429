<script>
export default {
	data() {
		return {
			value: false,
			opt: {},
			firstTimeOpened: false,
		}
	},
	computed: {
		hasContent() {
			return this.opt.text || this.opt.code || this.opt.description || this.$slots.default
		},
		actionsAlignClass() {
			return this.opt.actionsRight ? 'justify-end' : 'justify-center'
		},
	},
	methods: {
		open({
			type = 'success',
			title,
			text,
			description,
			code,
			accept,
			cancel,
			onAccept,
			onCancel,
			maxHeight,
			maxWidth = 650,
			maxContentHeight = 650,
			actionsRight,
		} = {}) {
			const opt = {
				type,
				title,
				text,
				description,
				code,
				accept,
				cancel,
				onAccept,
				onCancel,
				maxHeight,
				maxWidth,
				maxContentHeight,
				actionsRight,
			}
			if (!opt.title) {
				if (opt.type == 'error') opt.title = 'Ups!'
				else if (opt.type == 'success') opt.title = 'Listo!'
			}
			if (!opt.cancel && (opt.onAccept || opt.onCancel)) {
				opt.cancel = 'Cancelar'
			}
			if (!opt.accept) opt.accept = 'Aceptar'
			this.opt = this.$assign({}, opt)
			this.value = true
			this.firstTimeOpened = true
		},
		cancel() {
			this.value = false
			this.opt.onCancel?.()
		},
		accept() {
			this.value = false
			this.opt.onAccept?.()
		},
	},
	mounted() {
		this.$shopApi.defaults({
			onMessage: ({ message, success }) => {
				this.open({
					type: message.type || (success ? 'success' : 'error'),
					title: message.title,
					text: message.text,
					description: message.description,
					code: message.code,
				})
			},
			onConfirm: ({ confirmed, canceled, options }) => {
				this.open({
					title: options.confirm.title,
					text: options.confirm.text,
					accept: options.confirm.accept,
					cancel: options.confirm.cancel,
					onAccept: confirmed,
					onCancel: canceled,
				})
			},
		})
	},
}
</script>

<template>
	<v-dialog
		v-if="firstTimeOpened"
		:value="value"
		:max-width="opt.maxWidth"
		persistent
		scrollable
		style="z-index: 9999999"
	>
		<v-card style="z-index: 9999999">
			<v-card-title class="headline" v-if="opt.title">
				{{ opt.title }}
			</v-card-title>
			<v-card-text v-if="hasContent" :style="{ maxHeight: `${opt.maxContentHeight}px` }" class="font-1">
				<slot>
					<div v-if="opt.text" :class="{ 'pt-8': !opt.title }">
						{{ opt.text }}
					</div>
					<div v-if="opt.code" class="grey--text font-0">[{{ opt.code }}]</div>
					<div v-if="opt.description" class="pt-4">
						<pre style="white-space: pre-wrap">{{ opt.description }}</pre>
					</div>
				</slot>
			</v-card-text>
			<v-card-actions :class="actionsAlignClass">
				<v-btn color="" text @click.stop="cancel()" v-if="opt.cancel">
					{{ opt.cancel }}
				</v-btn>
				<v-btn color="success" text @click.stop="accept()">
					{{ opt.accept }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style scoped>
::v-deep .v-dialog__content {
	z-index: 999999 !important;
}
</style>
