import Vue from 'vue'

function getAllHooks(autoloaderComponents) {
	return Object.entries(autoloaderComponents)
		.filter(([name]) => name.startsWith(`Hook-`))
		.map(([name, component]) => ({ name, component }))
}

function filterByKey(allHooks, hooksKey) {
	return allHooks.filter(({ name }) => name.startsWith(`Hook-${hooksKey}-`))
}

async function resolveHooksComponents(hooks) {
	await Promise.all(
		hooks.map(async (hook) => {
			if (typeof hook.component == 'function') {
				hook.component = (await hook.component()).default
			}
			hook.component.inheritAttrs = false
		})
	)
}

function filterByZone(hooks, hookContainer, zoneName) {
	return hooks
		.map((hook) => {
			return { position: 0, hook }
		})
		.filter((item) => {
			let zone = item.hook.component.hookZone || 'default'
			let filtered = false
			if (typeof zone == 'string') {
				filtered = zoneName == zone
			} else if (Array.isArray(zone)) {
				filtered = zone.includes(zoneName)
			}

			if (filtered && item.hook.component.showHook) {
				filtered = item.hook.component.showHook(zoneName, hookContainer)
			}

			if (filtered) {
				item.position = item.hook.component.hookPosition || 0
				if (typeof item.position == 'object') {
					item.position = item.position[zoneName]
				}
			}

			return filtered
		})
		.sort((a, b) => a.position - b.position)
		.map((item) => item.hook)
}

function getChildren(hooksKey, hookContainer, currentZone, hooks, defaultSlot, createElement) {
	if (hooks.length) {
		/*if (scopedSlot) {
			return scopedSlot({
				components: hooks.map(({ name }) => name),
				currentZone,
				[hooksKey]: hookContainer,
			})
		} else {*/
		return hooks.map((hook) =>
			createElement(hook.component, { props: { [hooksKey]: hookContainer, currentZone } })
		)
		/*}*/
	} else {
		return defaultSlot || []
	}
}

function makeHookZoneComponent(hooks, hooksKey) {
	return async () => {
		await resolveHooksComponents(hooks)

		return {
			props: {
				zone: {
					type: String,
					default: 'default',
				},
				wrapperTag: String,
			},
			inject: ['hookContainer'],
			computed: {
				cHookContainer() {
					return this.hookContainer()
				},
				hooks() {
					return filterByZone(hooks, this.cHookContainer, this.zone)
				},
				beforeHooks() {
					return filterByZone(hooks, this.cHookContainer, `before--${this.zone}`)
				},
				afterHooks() {
					return filterByZone(hooks, this.cHookContainer, `after--${this.zone}`)
				},
			},
			render(createElement) {
				let children = []

				if (!this.cNoBefore) {
					children = children.concat(
						getChildren(
							hooksKey,
							this.cHookContainer,
							`before--${this.zone}`,
							this.beforeHooks,
							//this.$scopedSlots.beforeHooks,
							this.$slots.before,
							createElement
						)
					)
				}

				children = children.concat(
					getChildren(
						hooksKey,
						this.cHookContainer,
						this.zone,
						this.hooks,
						//this.$scopedSlots.hooks,
						this.$slots.default,
						createElement
					)
				)

				if (!this.cNoAfter) {
					children = children.concat(
						getChildren(
							hooksKey,
							this.cHookContainer,
							`after--${this.zone}`,
							this.afterHooks,
							//this.$scopedSlots.afterHooks,
							this.$slots.after,
							createElement
						)
					)
				}

				return children.length ? createElement(this.wrapperTag || 'div', children) : null
			},
		}
	}
}

export default function createHooks(autoloaderComponents, options) {
	const allHooks = getAllHooks(autoloaderComponents)
	options.getHooksComponents = () => allHooks
}

Vue.mixin({
	beforeCreate() {
		if (this.$options.hooksKey && !this.$options.components['hook']) {
			const hooks = filterByKey(this.$root.$options.getHooksComponents(), this.$options.hooksKey)
			this.$options.components['hook'] = makeHookZoneComponent(hooks, this.$options.hooksKey)
		}
	},
})
