import productMixin from './product-mixin'

export default {
	mixins: [productMixin('card')],
	props: {
		product: Object,
		listId: String,
		listIndex: Number,
		timerProduct: { type: Boolean, default: false },
		verticalCard: { type: Boolean, default: true },
		featured: { type: Boolean, default: false },
	},
	data() {
		return {
			firstImageHover: false,
		}
	},
	computed: {
		mainImage() {
			let img = this.product.images[0].square
			img.alt = this.product.name
			return img
		},
		secondImage() {
			let img = this.product.images[1]?.square
			if (img) img.alt = this.product.name
			return img
		},
	},
	methods: {
		async cardClick() {
			this.$eventer().trigger('product:click', {
				product: this.product,
				variant: this.selectedVariant,
				listId: this.listId,
				index: this.listIndex,
			})
			this.$emit('card:click', this.product)
		},
	},
}
