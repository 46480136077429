<script>
export default {
	data() {
		return {
			certificates: this.$srv('FooterCertificates.certificates', []),
			copyrightText: this.$srv('FooterCopyrightText.copyrightText'),
		}
	},
	cssVars: {
		selector: '.footer',
	},
}
</script>
<template>
	<div class="footer primary base w100">
		<v-container>
			<v-row align="center">
				<v-col
					cols="12"
					md="8"
					class="text-center text-md-left primary"
					:class="{ 'px-1': $b.d }"
					v-html="copyrightText"
				>
				</v-col>

				<v-col cols="12" md="4" class="d-flex justify-center justify-md-end" :class="{ 'px-1': $b.d }">
					<FooterCertificates :items="certificates" />
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style></style>
