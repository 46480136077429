/* AUTO-GENERATED FILE - DO NOT EDIT */

/* Components */
/* project/vue-src/v-shop/components.js */
/* .blocks/v-shop-dynamic-home/vue-src/v-shop/components.js */
/* .blocks/v-shop-base-layout/vue-src/v-shop/components.js */
/* .blocks/v-shop/vue-src/v-shop/components.js */
/* .blocks/cc-operator-first-data/vue-src/v-shop/components.js */
/* .blocks/shop-payment-credit-card/vue-src/v-shop/components.js */
/* .blocks/shop-payment-cash/vue-src/v-shop/components.js */
/* .blocks/shop-payment-wiretransfer/vue-src/v-shop/components.js */
/* .blocks/shop-payment-mercadopago/vue-src/v-shop/components.js */

import Vue from 'vue'

const c = {}
const r = (a, b) => {
	c[a] = b
	Vue.component(a, b)
}
export const isComponent = (name) => name in c
export const getComponentsNames = () => Object.keys(c)

export const getComponents = () => ({ ...c })

r('ActionBarMobile', require('@/v-shop/comp_ActionBarMobile.vue').default)
r('AppBar-Bottom-CategoriesMenu', require('@/v-shop/comp_AppBar-Bottom-CategoriesMenu.vue').default)
r('AppBar-Bottom-MenuItems', require('@/v-shop/comp_AppBar-Bottom-MenuItems.vue').default)
r('AppBar-Bottom', require('@/v-shop/comp_AppBar-Bottom.vue').default)
r('AppBar-Buttons', require('@/v-shop/comp_AppBar-Buttons.vue').default)
r('AppBar-Top', require('@/v-shop/comp_AppBar-Top.vue').default)
r('AppBar-TopMsg', require('@/v-shop/comp_AppBar-TopMsg.vue').default)
r('AppBar', require('@/v-shop/comp_AppBar.vue').default)
r('AppBarMobileSearch', require('@/v-shop/comp_AppBarMobileSearch.vue').default)
r('AppBarRound-Drawer', require('@/v-shop/comp_AppBarRound-Drawer.vue').default)
r('AppBarRound', require('@/v-shop/comp_AppBarRound.vue').default)
r('AutocompleteSearchField-ProductItem', require('@/v-shop/comp_AutocompleteSearchField-ProductItem.vue').default)
r('AutocompleteSearchField', require('@/v-shop/comp_AutocompleteSearchField.vue').default)
r('BrandSku', require('@/v-shop/comp_BrandSku.vue').default)
r('CategoriesMegaMenu-CategoriesList', require('@/v-shop/comp_CategoriesMegaMenu-CategoriesList.vue').default)
r('CategoriesMegaMenu-CategoryTitle', require('@/v-shop/comp_CategoriesMegaMenu-CategoryTitle.vue').default)
r('CategoriesMegaMenu-Image', require('@/v-shop/comp_CategoriesMegaMenu-Image.vue').default)
r('CategoriesMegaMenu-Overlay', require('@/v-shop/comp_CategoriesMegaMenu-Overlay.vue').default)
r('CategoriesMegaMenu', require('@/v-shop/comp_CategoriesMegaMenu.vue').default)
r('CategoriesMegaMenuHorizontal', require('@/v-shop/comp_CategoriesMegaMenuHorizontal.vue').default)
r('CollectionEventIcon', require('@/v-shop/comp_CollectionEventIcon.vue').default)
r('DiscountText', require('@/v-shop/comp_DiscountText.vue').default)
r('Footer-Bottom', require('@/v-shop/comp_Footer-Bottom.vue').default)
r('Footer-Top', require('@/v-shop/comp_Footer-Top.vue').default)
r('Footer', require('@/v-shop/comp_Footer.vue').default)
r('FooterCertificates', require('@/v-shop/comp_FooterCertificates.vue').default)
r('FooterContactList', require('@/v-shop/comp_FooterContactList.vue').default)
r('FooterLinkItem', require('@/v-shop/comp_FooterLinkItem.vue').default)
r('FooterLinkList', require('@/v-shop/comp_FooterLinkList.vue').default)
r('FooterSocialItem', require('@/v-shop/comp_FooterSocialItem.vue').default)
r('FooterSocialsList', require('@/v-shop/comp_FooterSocialsList.vue').default)
r('Hook-ProductPage-BaseTemplate', require('@/v-shop/comp_Hook-ProductPage-BaseTemplate.vue').default)
r('Hook-ProductPage-DescriptionAndAttrs', require('@/v-shop/comp_Hook-ProductPage-DescriptionAndAttrs.vue').default)
r('Hook-ProductPage-InfoDropdownImages', require('@/v-shop/comp_Hook-ProductPage-InfoDropdownImages.vue').default)
r('Hook-ProductPage-InfoTabs', require('@/v-shop/comp_Hook-ProductPage-InfoTabs.vue').default)
r('Hover', require('@/v-shop/comp_Hover.vue').default)
r('LimitedTimeOffer', require('@/v-shop/comp_LimitedTimeOffer.vue').default)
r('LimitedTimeOfferCountdown', require('@/v-shop/comp_LimitedTimeOfferCountdown.vue').default)
r('MobileMenuDrawer-MegaMenuCategories', require('@/v-shop/comp_MobileMenuDrawer-MegaMenuCategories.vue').default)
r('MobileMenuDrawer', require('@/v-shop/comp_MobileMenuDrawer.vue').default)
r('PaymentOptions', require('@/v-shop/comp_PaymentOptions.vue').default)
r('PriceLayout', require('@/v-shop/comp_PriceLayout.vue').default)
r('ProductActions', require('@/v-shop/comp_ProductActions.vue').default)
r('ProductActionsSmall', require('@/v-shop/comp_ProductActionsSmall.vue').default)
r('ProductDiscount', require('@/v-shop/comp_ProductDiscount.vue').default)
r('ProductImagesCarousel', require('@/v-shop/comp_ProductImagesCarousel.vue').default)
r('ProductImagesDialog', require('@/v-shop/comp_ProductImagesDialog.vue').default)
r('ProductInfoDescription', require('@/v-shop/comp_ProductInfoDescription.vue').default)
r('ProductPage-AdditionalPaymentInfo', require('@/v-shop/comp_ProductPage-AdditionalPaymentInfo.vue').default)
r('ProductPage-Attributes', require('@/v-shop/comp_ProductPage-Attributes.vue').default)
r('ProductPage-Description', require('@/v-shop/comp_ProductPage-Description.vue').default)
r('ProductPage-InfoDropdownImages', require('@/v-shop/comp_ProductPage-InfoDropdownImages.vue').default)
r('ProductPage-InfoTabs', require('@/v-shop/comp_ProductPage-InfoTabs.vue').default)
r('ProductPage-LimitedTimeOffer', require('@/v-shop/comp_ProductPage-LimitedTimeOffer.vue').default)
r('ProductPage-MainContainer', require('@/v-shop/comp_ProductPage-MainContainer.vue').default)
r('ProductPage-ProductShipmentInfo', require('@/v-shop/comp_ProductPage-ProductShipmentInfo.vue').default)
r('ProductShareBtn', require('@/v-shop/comp_ProductShareBtn.vue').default)
r('ProductShipping-Price', require('@/v-shop/comp_ProductShipping-Price.vue').default)
r('ProductShippingOptions', require('@/v-shop/comp_ProductShippingOptions.vue').default)
r('ProductShippingOptionsList', require('@/v-shop/comp_ProductShippingOptionsList.vue').default)
r('ProductStockAvailabilityMessage', require('@/v-shop/comp_ProductStockAvailabilityMessage.vue').default)
r('ProductStockQtyMessage', require('@/v-shop/comp_ProductStockQtyMessage.vue').default)
r('RegularSearchField', require('@/v-shop/comp_RegularSearchField.vue').default)
r('SearchFieldRenderer', require('@/v-shop/comp_SearchFieldRenderer.vue').default)
r('ShareProduct', require('@/v-shop/comp_ShareProduct.vue').default)
r('ShipmentCalculator', require('@/v-shop/comp_ShipmentCalculator.vue').default)
r('ShipmentInfoItem', require('@/v-shop/comp_ShipmentInfoItem.vue').default)
r('VariantSelector-SelectItem', require('@/v-shop/comp_VariantSelector-SelectItem.vue').default)
r('VariantsSelector', require('@/v-shop/comp_VariantsSelector.vue').default)
r('unused_comp_ProductPage-Attributes.vue', require('@/v-shop/unused_comp_ProductPage-Attributes.vue').default)
r('AppRouteLoadingBar', require('@/v-shop/app/comp_AppRouteLoadingBar.vue').default)
r('ShopApp', require('@/v-shop/app/comp_ShopApp.vue').default)
r('AppMessageDialog', require('@/v-shop/common/comp_AppMessageDialog.vue').default)
r('Breadcrumbs', require('@/v-shop/common/comp_Breadcrumbs.vue').default)
r('CardLayout', require('@/v-shop/common/comp_CardLayout.vue').default)
r('Carousel', require('@/v-shop/common/comp_Carousel.vue').default)
r('ClientSideOnly', require('@/v-shop/common/comp_ClientSideOnly.vue').default)
r('Container', require('@/v-shop/common/comp_Container.vue').default)
r('CountryPhoneCodeSelector', require('@/v-shop/common/comp_CountryPhoneCodeSelector.vue').default)
r('DeviceMatch', require('@/v-shop/common/comp_DeviceMatch.vue').default)
r('Hook-ShopApp-SmartPopup', require('@/v-shop/common/comp_Hook-ShopApp-SmartPopup.vue').default)
r('LoadingBar', require('@/v-shop/common/comp_LoadingBar.vue').default)
r('OfferCard', require('@/v-shop/common/comp_OfferCard.vue').default)
r('OfferDrawer', require('@/v-shop/common/comp_OfferDrawer.vue').default)
r('OfferDrawerButton', require('@/v-shop/common/comp_OfferDrawerButton.vue').default)
r('OfferItem', require('@/v-shop/common/comp_OfferItem.vue').default)
r('PriceText', require('@/v-shop/common/comp_PriceText.vue').default)
r('ProductDataSheet', require('@/v-shop/common/comp_ProductDataSheet.vue').default)
r('ProductPrevPrice', require('@/v-shop/common/comp_ProductPrevPrice.vue').default)
r('ProductPrice', require('@/v-shop/common/comp_ProductPrice.vue').default)
r('ProductsCarousel', require('@/v-shop/common/comp_ProductsCarousel.vue').default)
r('ProductsLayout', require('@/v-shop/common/comp_ProductsLayout.vue').default)
r('ProductWishlistToggler', require('@/v-shop/common/comp_ProductWishlistToggler.vue').default)
r('QtyInput', require('@/v-shop/common/comp_QtyInput.vue').default)
r('SkuChip', require('@/v-shop/common/comp_SkuChip.vue').default)
r('SmartPopup-Form', require('@/v-shop/common/comp_SmartPopup-Form.vue').default)
r('SmartPopupBottom', require('@/v-shop/common/comp_SmartPopupBottom.vue').default)
r('SmartPopupCenter', require('@/v-shop/common/comp_SmartPopupCenter.vue').default)
r('SmartPopupRight', require('@/v-shop/common/comp_SmartPopupRight.vue').default)
r('Title', require('@/v-shop/common/comp_Title.vue').default)
r('UrlMatch', require('@/v-shop/common/comp_UrlMatch.vue').default)
r('WhatsappBtn', require('@/v-shop/common/comp_WhatsappBtn.vue').default)
r('WhatsappChat', require('@/v-shop/common/comp_WhatsappChat.vue').default)
r('ForgotForm', require('@/v-shop/login/comp_ForgotForm.vue').default)
r('GoogleAuthButton', require('@/v-shop/login/comp_GoogleAuthButton.vue').default)
r('LoginDrawer', require('@/v-shop/login/comp_LoginDrawer.vue').default)
r('LoginForm', require('@/v-shop/login/comp_LoginForm.vue').default)
r('LoginForms', require('@/v-shop/login/comp_LoginForms.vue').default)
r('SignupForm', require('@/v-shop/login/comp_SignupForm.vue').default)
r('ThirdPartyLoginsButtons', require('@/v-shop/login/comp_ThirdPartyLoginsButtons.vue').default)
r('CartDrawer', require('@/v-shop/cart-drawer/comp_CartDrawer.vue').default)
r('CartItem', require('@/v-shop/cart-drawer/comp_CartItem.vue').default)
r('ProductCard-CenterAligned', require('@/v-shop/product-card/comp_ProductCard-CenterAligned.vue').default)
r(
	'ProductCard-CenterAlignedFeatured',
	require('@/v-shop/product-card/comp_ProductCard-CenterAlignedFeatured.vue').default
)
r(
	'ProductCard-CenterAlignedForSmallCarousel',
	require('@/v-shop/product-card/comp_ProductCard-CenterAlignedForSmallCarousel.vue').default
)
r('ProductCard-CenterAlignedSmall', require('@/v-shop/product-card/comp_ProductCard-CenterAlignedSmall.vue').default)
r('ProductCard-CenterAlignedSq', require('@/v-shop/product-card/comp_ProductCard-CenterAlignedSq.vue').default)
r(
	'ProductCard-CenterAlignedWithBtn',
	require('@/v-shop/product-card/comp_ProductCard-CenterAlignedWithBtn.vue').default
)
r(
	'ProductCard-CenterAlignedWithBtnFeatured',
	require('@/v-shop/product-card/comp_ProductCard-CenterAlignedWithBtnFeatured.vue').default
)
r('ProductCard-LeftAligned', require('@/v-shop/product-card/comp_ProductCard-LeftAligned.vue').default)
r('ProductCard-LeftAlignedFeatured', require('@/v-shop/product-card/comp_ProductCard-LeftAlignedFeatured.vue').default)
r(
	'ProductCard-LeftAlignedForSmallCarousel',
	require('@/v-shop/product-card/comp_ProductCard-LeftAlignedForSmallCarousel.vue').default
)
r('ProductCard-LeftAlignedSmall', require('@/v-shop/product-card/comp_ProductCard-LeftAlignedSmall.vue').default)
r('ProductCard-LeftAlignedSq', require('@/v-shop/product-card/comp_ProductCard-LeftAlignedSq.vue').default)
r('ProductCard-LeftAlignedWithBtn', require('@/v-shop/product-card/comp_ProductCard-LeftAlignedWithBtn.vue').default)
r(
	'ProductCard-LeftAlignedWithBtnFeatured',
	require('@/v-shop/product-card/comp_ProductCard-LeftAlignedWithBtnFeatured.vue').default
)
r('ProductCard-Shadow', require('@/v-shop/product-card/comp_ProductCard-Shadow.vue').default)
r('ProductCard-ShadowFeatured', require('@/v-shop/product-card/comp_ProductCard-ShadowFeatured.vue').default)
r(
	'ProductCard-ShadowForSmallCarousel',
	require('@/v-shop/product-card/comp_ProductCard-ShadowForSmallCarousel.vue').default
)
r('ProductCard-ShadowSmall', require('@/v-shop/product-card/comp_ProductCard-ShadowSmall.vue').default)
r('ProductCard-ShadowSq', require('@/v-shop/product-card/comp_ProductCard-ShadowSq.vue').default)
r('ProductCard-ShadowWithBtn', require('@/v-shop/product-card/comp_ProductCard-ShadowWithBtn.vue').default)
r(
	'ProductCard-ShadowWithBtnFeatured',
	require('@/v-shop/product-card/comp_ProductCard-ShadowWithBtnFeatured.vue').default
)
r('ProductCard', require('@/v-shop/product-card/comp_ProductCard.vue').default)
r('Media', require('@/media/comp_Media.vue').default)
r('RatioBox', require('@/media/comp_RatioBox.vue').default)
r('RatioImage', require('@/media/comp_RatioImage.vue').default)
r('Button', require('@/vuetify-common/comp_Button.vue').default)
r('SafeLink', require('@/vuetify-common/comp_SafeLink.vue').default)
r('TextField', require('@/vuetify-common/comp_TextField.vue').default)
r('Validator', require('@/vuetify-common/comp_Validator.vue').default)
r('BannerItem', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_BannerItem.vue'))
r('dpc-AppbarImagesSlider', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-AppbarImagesSlider.vue')
)
r('dpc-Banner1', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Banner1.vue'))
r('dpc-Banner2', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Banner2.vue'))
r('dpc-Banner3', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Banner3.vue'))
r('dpc-Banner4', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Banner4.vue'))
r('dpc-Banner5', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Banner5.vue'))
r('dpc-Banner6', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Banner6.vue'))
r('dpc-BrandsBoard', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-BrandsBoard.vue')
)
r('dpc-CarouselMultiple', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-CarouselMultiple.vue')
)
r('dpc-EnhancedProductsCarousel', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-EnhancedProductsCarousel.vue')
)
r('dpc-GoogleReviews', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-GoogleReviews.vue')
)
r('dpc-HighSliderPlusText', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-HighSliderPlusText.vue')
)
r('dpc-Hotspot', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Hotspot.vue'))
r('dpc-ImagesCarousel', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ImagesCarousel.vue')
)
r('dpc-ImagesSlider', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ImagesSlider.vue')
)
r('dpc-ListCardWithIcon', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ListCardWithIcon.vue')
)
r('dpc-Newsletter', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Newsletter.vue')
)
r('dpc-OffersBoard', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-OffersBoard.vue')
)
r('dpc-OffersPlusBanners', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-OffersPlusBanners.vue')
)
r('dpc-ProductsBoard', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ProductsBoard.vue')
)
r('dpc-ProductsBoardWithData', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ProductsBoardWithData.vue')
)
r('dpc-ProductsCarouselWithTimer', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ProductsCarouselWithTimer.vue')
)
r('dpc-ProductsListWithFilters', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-ProductsListWithFilters.vue')
)
r('dpc-Richtext', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Richtext.vue'))
r('dpc-SliderPlus2Banners', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-SliderPlus2Banners.vue')
)
r('dpc-SliderPlusFeatured', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-SliderPlusFeatured.vue')
)
r('dpc-Spacer', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-Spacer.vue'))
r('dpc-VideoBackground', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-VideoBackground.vue')
)
r('dpc-WordpressPosts', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_dpc-WordpressPosts.vue')
)
r('DynamicPageItems', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_DynamicPageItems.vue')
)
r('HotspotItem', () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_HotspotItem.vue'))
r('NewsletterContent', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_NewsletterContent.vue')
)
r('NewsletterForm', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_NewsletterForm.vue')
)
r('WordpressPostItem', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_WordpressPostItem.vue')
)
r('WordpressPostItemHorizontal', () =>
	import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/comp_WordpressPostItemHorizontal.vue')
)
r('OrderSummary-Item', () => import(/* webpackChunkName: "order" */ '@/v-shop/order/comp_OrderSummary-Item.vue'))
r('OrderSummary-Totals', () => import(/* webpackChunkName: "order" */ '@/v-shop/order/comp_OrderSummary-Totals.vue'))
r('OrderSummary', () => import(/* webpackChunkName: "order" */ '@/v-shop/order/comp_OrderSummary.vue'))
r('PaymentInstructions', () => import(/* webpackChunkName: "order" */ '@/v-shop/order/comp_PaymentInstructions.vue'))
r('CheckoutApp', () => import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/comp_CheckoutApp.vue'))
r('AddressDialog', () => import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_AddressDialog.vue'))
r('CheckoutCollapsedSummary', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_CheckoutCollapsedSummary.vue')
)
r('CheckoutPaymentCardContent', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_CheckoutPaymentCardContent.vue')
)
r('CheckoutStepLayout', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_CheckoutStepLayout.vue')
)
r('CountrySelector', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_CountrySelector.vue')
)
r('DeliveryOptionCardContent-PickupPoint', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_DeliveryOptionCardContent-PickupPoint.vue')
)
r('DeliveryOptionCardContent-Shipping', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_DeliveryOptionCardContent-Shipping.vue')
)
r('DeliveryOptionCardContent', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/common/comp_DeliveryOptionCardContent.vue')
)
r('CheckoutStep-confirm', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/steps/comp_CheckoutStep-confirm.vue')
)
r('CheckoutStep-contact', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/steps/comp_CheckoutStep-contact.vue')
)
r('CheckoutStep-delivery', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/steps/comp_CheckoutStep-delivery.vue')
)
r('CheckoutStep-discount', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/steps/comp_CheckoutStep-discount.vue')
)
r('CheckoutStep-init', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/steps/comp_CheckoutStep-init.vue')
)
r('CheckoutStep-payment', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/steps/comp_CheckoutStep-payment.vue')
)
r('CheckoutStep-signin', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/steps/comp_CheckoutStep-signin.vue')
)
r('CheckoutConfirmCard-contact', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/confirm-cards/comp_CheckoutConfirmCard-contact.vue')
)
r('CheckoutConfirmCard-delivery', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/confirm-cards/comp_CheckoutConfirmCard-delivery.vue')
)
r('CheckoutConfirmCard-discount', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/confirm-cards/comp_CheckoutConfirmCard-discount.vue')
)
r('CheckoutConfirmCard-payment', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/confirm-cards/comp_CheckoutConfirmCard-payment.vue')
)
r('Autocomplete', () => import(/* webpackChunkName: "checkout" */ '@/vuetify-common/comp_Autocomplete.vue'))
r('DatePickerDialog', () => import(/* webpackChunkName: "checkout" */ '@/vuetify-common/comp_DatePickerDialog.vue'))
r('MagicButton', () => import(/* webpackChunkName: "checkout" */ '@/vuetify-common/comp_MagicButton.vue'))
r('Select', () => import(/* webpackChunkName: "checkout" */ '@/vuetify-common/comp_Select.vue'))
r('TextArea', () => import(/* webpackChunkName: "checkout" */ '@/vuetify-common/comp_TextArea.vue'))
r('ValidationMessage', () => import(/* webpackChunkName: "checkout" */ '@/vuetify-common/comp_ValidationMessage.vue'))
r('DefaultProducts', () =>
	import(/* webpackChunkName: "shoplist" */ '@/v-shop/shop-list-view/comp_DefaultProducts.vue')
)
r('DescriptionExpandable', () =>
	import(/* webpackChunkName: "shoplist" */ '@/v-shop/shop-list-view/comp_DescriptionExpandable.vue')
)
r('ShopFilters', () => import(/* webpackChunkName: "shoplist" */ '@/v-shop/shop-list-view/comp_ShopFilters.vue'))
r('ShopFiltersGroup', () =>
	import(/* webpackChunkName: "shoplist" */ '@/v-shop/shop-list-view/comp_ShopFiltersGroup.vue')
)
r('ShopFiltersRemovalChips', () =>
	import(/* webpackChunkName: "shoplist" */ '@/v-shop/shop-list-view/comp_ShopFiltersRemovalChips.vue')
)
r('UpsellProductCard-CenterAligned', () =>
	import(/* webpackChunkName: "product" */ '@/v-shop/product-upsells-view/comp_UpsellProductCard-CenterAligned.vue')
)
r('UpsellProductCard-LeftAligned', () =>
	import(/* webpackChunkName: "product" */ '@/v-shop/product-upsells-view/comp_UpsellProductCard-LeftAligned.vue')
)
r('UpsellProductCard-Shadow', () =>
	import(/* webpackChunkName: "product" */ '@/v-shop/product-upsells-view/comp_UpsellProductCard-Shadow.vue')
)
r('ChangePassword', () => import(/* webpackChunkName: "account" */ '@/v-shop/account/comp_ChangePassword.vue'))
r('ContactData', () => import(/* webpackChunkName: "account" */ '@/v-shop/account/comp_ContactData.vue'))
r('OrderDigitalItemsCard', () =>
	import(/* webpackChunkName: "account" */ '@/v-shop/account/comp_OrderDigitalItemsCard.vue')
)
r('OrderListCard', () => import(/* webpackChunkName: "account" */ '@/v-shop/account/comp_OrderListCard.vue'))
r('UserData', () => import(/* webpackChunkName: "account" */ '@/v-shop/account/comp_UserData.vue'))
r('UserpanelDrawer', () => import(/* webpackChunkName: "account" */ '@/v-shop/account/comp_UserpanelDrawer.vue'))
r('UserpanelLayout', () => import(/* webpackChunkName: "account" */ '@/v-shop/account/comp_UserpanelLayout.vue'))
r('NotFoundPage', () => import(/* webpackChunkName: "notfoundpage" */ '@/v-shop/not-found-page/comp_NotFoundPage.vue'))
r('CreditCard-OperatorResolver-FirstData', () =>
	import(/* webpackChunkName: "creditCard" */ '@/v-shop/comp_CreditCard-OperatorResolver-FirstData.vue')
)
r('CreditCard-CheckoutConfirmButton', () =>
	import(/* webpackChunkName: "creiditCard" */ '@/v-shop/comp_CreditCard-CheckoutConfirmButton.vue')
)
r('CreditCard-Payment-CheckoutConfirmCardContent', () =>
	import(/* webpackChunkName: "creiditCard" */ '@/v-shop/comp_CreditCard-Payment-CheckoutConfirmCardContent.vue')
)
r('CreditCard-Payment-CheckoutOptions', () =>
	import(/* webpackChunkName: "creiditCard" */ '@/v-shop/comp_CreditCard-Payment-CheckoutOptions.vue')
)
r('CreditCard-Payment-InstructionsCardContent', () =>
	import(/* webpackChunkName: "creiditCard" */ '@/v-shop/comp_CreditCard-Payment-InstructionsCardContent.vue')
)
r('CreditCardApp', () => import(/* webpackChunkName: "creiditCard" */ '@/v-shop/comp_CreditCardApp.vue'))
r('CreditCardCheckoutOptionContent', () =>
	import(/* webpackChunkName: "creiditCard" */ '@/v-shop/comp_CreditCardCheckoutOptionContent.vue')
)
r('CreditCardNumberForm', () => import(/* webpackChunkName: "creiditCard" */ '@/v-shop/comp_CreditCardNumberForm.vue'))
r('CreditCardPlanBox', () => import(/* webpackChunkName: "creiditCard" */ '@/v-shop/comp_CreditCardPlanBox.vue'))
r('CreditCardPlanSelector', () =>
	import(/* webpackChunkName: "creiditCard" */ '@/v-shop/comp_CreditCardPlanSelector.vue')
)
r('CreditCardTransactionDetail', () =>
	import(/* webpackChunkName: "creiditCard" */ '@/v-shop/comp_CreditCardTransactionDetail.vue')
)
r('Cash-Payment-CheckoutConfirmCardContent', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/comp_Cash-Payment-CheckoutConfirmCardContent.vue')
)
r('Cash-Payment-CheckoutOptions', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/comp_Cash-Payment-CheckoutOptions.vue')
)
r('CashCardContent', () => import(/* webpackChunkName: "checkout" */ '@/v-shop/comp_CashCardContent.vue'))
r('WireTransfer-Payment-CheckoutConfirmCardContent', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/comp_WireTransfer-Payment-CheckoutConfirmCardContent.vue')
)
r('WireTransfer-Payment-CheckoutOptions', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/comp_WireTransfer-Payment-CheckoutOptions.vue')
)
r('WireTransfer-Payment-InstructionsCardContent', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/comp_WireTransfer-Payment-InstructionsCardContent.vue')
)
r('WireTransferCardContent', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/comp_WireTransferCardContent.vue')
)
r('MercadoPago-Payment-CheckoutConfirmCardContent', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/comp_MercadoPago-Payment-CheckoutConfirmCardContent.vue')
)
r('MercadoPago-Payment-CheckoutOptions', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/comp_MercadoPago-Payment-CheckoutOptions.vue')
)
r('MercadoPago-Payment-InstructionsCardContent', () =>
	import(/* webpackChunkName: "checkout" */ '@/v-shop/comp_MercadoPago-Payment-InstructionsCardContent.vue')
)
r('MercadoPagoCardContent', () => import(/* webpackChunkName: "checkout" */ '@/v-shop/comp_MercadoPagoCardContent.vue'))

export const views = {
	HowTo: () => import(/* webpackChunkName: "others" */ '@/v-shop/others/view_HowTo.vue'),
	Info: () => import(/* webpackChunkName: "others" */ '@/v-shop/others/view_Info.vue'),
	TermsCondition: () => import(/* webpackChunkName: "others" */ '@/v-shop/others/view_TermsCondition.vue'),
	Home: require('@/v-shop/view_Home.vue').default,
	ProductPage: require('@/v-shop/view_ProductPage.vue').default,
	DynamicPage: () => import(/* webpackChunkName: "dpc" */ '@/v-shop/dynamic-pages-components/view_DynamicPage.vue'),
	Checkout: () => import(/* webpackChunkName: "checkout" */ '@/v-shop/checkout/view_Checkout.vue'),
	ShopList: () => import(/* webpackChunkName: "shoplist" */ '@/v-shop/shop-list-view/view_ShopList.vue'),
	ProductUpsells: () =>
		import(/* webpackChunkName: "product" */ '@/v-shop/product-upsells-view/view_ProductUpsells.vue'),
	UserAccount: () => import(/* webpackChunkName: "account" */ '@/v-shop/account/view_UserAccount.vue'),
	UserFavorites: () => import(/* webpackChunkName: "account" */ '@/v-shop/account/view_UserFavorites.vue'),
	UserOrderDetail: () => import(/* webpackChunkName: "account" */ '@/v-shop/account/view_UserOrderDetail.vue'),
	UserOrders: () => import(/* webpackChunkName: "account" */ '@/v-shop/account/view_UserOrders.vue'),
	RepentanceForm: () => import(/* webpackChunkName: "repentance" */ '@/v-shop/repentance/view_RepentanceForm.vue'),
	CreditCardPayment: () => import(/* webpackChunkName: "creiditCard" */ '@/v-shop/view_CreditCardPayment.vue'),
}

/* Routes */
export const routesProviders = [
	require('../../../shop-payment-credit-card/vue-src/v-shop/routes_credit-card.js').default,
	require('./routes/routes_account.js').default,
	require('./routes/routes_catalog.js').default,
	require('./routes/routes_checkout.js').default,
	require('./routes/routes_dynamicPages.js').default,
	require('../../../v-shop-dynamic-home/vue-src/v-shop/routes_home.js').default,
	require('../../../../project/vue-src/v-shop/others/routes_others.js').default,
]

/* Stores */
export const storesProviders = [require('./stores/store_cart.js').default, require('./stores/store_shop.js').default]

/* Plugins */
export const pluginsRunners = [
	require('../../../admin/vue-src/media/plugin_media.js').default,
	require('../../../site-analytics/vue-src/analytics/plugin_analytics.js').default,
	require('./plugins/plugin_app-component.js').default,
	require('./plugins/plugin_base-css-vars.js').default,
	require('./plugins/plugin_filters.js').default,
	require('./plugins/plugin_lang.js').default,
	require('./plugins/plugin_shop-analytics.js').default,
	require('./plugins/plugin_shop-api.js').default,
	require('./plugins/plugin_shop.js').default,
	require('./plugins/plugin_user-wishlist.js').default,
	require('./plugins/plugin_vuetify.js').default,
]

/* Styles */
require('./styles/styles___reset.scss')
require('./styles/styles__base.scss')
require('./styles/styles__fonts.scss')
require('./styles/styles__mdi-icons.scss')
require('./styles/styles_ssr-carousel.scss')
require('./styles/styles_v-checkbox.scss')
