<script>
import { get } from 'vuex-pathify'
export default {
	metaInfo() {
		const { siteName, separator, metaDescription } = this.$srv('SitePageTitle', {})
		return {
			titleTemplate: (title) => {
				if (title) {
					return siteName ? `${title} ${separator} ${siteName}` : title
				} else {
					return siteName || ''
				}
			},
			meta: [{ vmid: 'd', name: 'description', content: metaDescription || '' }],
		}
	},
	provide() {
		return {
			getAppMessageDialog: () => this.$refs.appMessageDialog,
		}
	},
	computed: {
		appComponent: get('shop/appComponent'),
	},
}
</script>

<template>
	<v-app class="shop-app base">
		<AppRouteLoadingBar />
		<component :is="appComponent" id="app" />
		<ClientSideOnly>
			<AppMessageDialog ref="appMessageDialog" />
		</ClientSideOnly>
	</v-app>
</template>
