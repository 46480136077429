<script>
export default {
	props: ['currentZone', 'ProductPage'],
	hookZone: ['after--images', 'bottom-inside'],
	showHook(currentZone, ProductPage) {
		return !ProductPage.product.info.templateKey
	},
	computed: {
		show() {
			return (
				(this.$b.d && this.currentZone == 'after--images') ||
				(this.$b.mt && this.currentZone == 'bottom-inside')
			)
		},
		description() {
			return this.ProductPage.product.info
		},
		attrs() {
			return this.ProductPage.product.attrs || []
		},
	},
}
</script>

<template>
	<div v-if="show" class="mx-n3 px-3" style="border-top: 1px solid #eee">
		<ProductPage-Description :templateData="description" class="pa-3" />
		<ProductPage-Attributes :attributes="attrs" class="pa-3" />
	</div>
</template>
