<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],

	hooksKey: 'ProductCard',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div
		class="h100"
		:class="{
			'product-card-left-aligned-small-wrapper-mobile': $b.mt,
			'product-card-left-aligned-small-wrapper': $b.d,
		}"
		v-if="verticalCard"
	>
		<v-hover v-slot="{ hover }">
			<v-card
				@mouseover="firstImageHover = true"
				:hover="!outOfStock ? true : false"
				outlined
				@click="cardClick"
				:ripple="false"
				:class="$bem('product-card-left-aligned-small')"
				class="h100 d-flex flex-column product-card rounded-md overflow-hidden justify-space-between base"
			>
				<div :class="$bem('__image-cont mx-2')" v-if="!outOfStock">
					<hook zone="image">
						<Media
							cover
							:class="$bem('__main-image')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1"
							:imgClass="{
								'product-card-left-aligned-small__image--smallWidth': $b.d,
								'product-card-left-aligned-small__image--smallWidth-mobile': $b.mt,
							}"
						/>
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont')">
							<Media
								cover
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
								:imgClass="{
									'product-card-left-aligned-small__image--smallWidth': $b.d,
									'product-card-left-aligned-small__image--smallWidth-mobile': $b.mt,
								}"
							/>
						</div>
					</hook>

					<hook zone="offer">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>

					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
				</div>

				<div :class="$bem('__image-cont', 'mx-2 no-stock')" v-else>
					<hook zone="image">
						<Media
							:class="$bem('__main-image')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1"
							:imgClass="{ smallWidth: $b.d, 'smallWidth-mobile': $b.mt }"
						/>
					</hook>

					<hook zone="offer">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" />
					</hook>
					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
				</div>

				<hook zone="price" :class="{ 'no-stock': outOfStock }">
					<PriceText
						:amount="price"
						:currency="currency"
						:class="$bem('__price')"
						class="price font-2 font-weight-bold line-clamp-1 mt-5 px-2"
					/>
				</hook>

				<div
					class="d-flex align-center px-2"
					v-if="prevPrice"
					:class="[$bem('__prev-price'), { 'no-stock': outOfStock }, { 'aling-baseline': $b.mt }]"
				>
					<hook zone="prevPrice">
						<del class="grey--text">
							<PriceText
								:amount="prevPrice"
								:currency="currency"
								:currencyMargin="0"
								priceClass="grey--text font-0"
							/>
						</del>
					</hook>

					<hook zone="discount">
						<span
							v-if="discountPct > 0"
							:class="$bem('__discount')"
							class="d-inline-block discount font-0 ml-2"
						>
							{{ discountPct }}% OFF
						</span>
					</hook>
				</div>

				<hook zone="attrs&financingTags" :class="{ 'no-stock': outOfStock }">
					<div
						v-if="financingTagText"
						:class="[
							$bem('__financing'),
							{ 'my-2': !discountPct && !hasTextTag, 'mt-2': !discountPct && hasTextTag },
						]"
						class="font-0 line-clamp-1 px-2 font-weight-bold primary text-start"
					>
						{{ financingTagText }}
					</div>
				</hook>

				<v-spacer :class="{ 'product-card-left-aligned-small__spacer--flex-grow': !discountPct }" />

				<router-link class="card-anchor header" :to="productRoute">
					<hook zone="name" :class="{ 'no-stock': outOfStock }">
						<div
							:class="$bem('__name')"
							class="line-clamp-2 font-1 header mb-2 px-2 font-weight-regular"
						>
							{{ product.name }}
						</div>
					</hook>
				</router-link>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD -->
	<div class="w100" v-else>
		<v-hover v-slot="{ hover }">
			<v-card
				@mouseover="firstImageHover = true"
				:hover="!outOfStock ? true : false"
				outlined
				@click="cardClick"
				:ripple="false"
				:class="$bem('product-card-left-aligned-small-horizontal')"
				class="d-flex overflow-hidden base product-card py-2"
			>
				<div v-if="!outOfStock" :class="$bem('__image-cont', 'col col-5 pa-0')">
					<hook zone="imageHorizontal" class="h100">
						<Media :class="$bem('__main-image', 'px-1 h100')" :src="mainImage" width="100%" />
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont', 'px-1')">
							<Media
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '', 'h100']"
							/>
						</div>
					</hook>
					<hook zone="offerHorizontal">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>
					<hook zone="event-iconHorizontal">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="30px" />
					</hook>
				</div>

				<div
					:class="$bem('__image-cont', 'no-stock col col-5 pa-0 d-flex flex-column justify-center')"
					v-else
				>
					<hook zone="imageHorizontal">
						<Media
							:class="$bem('__main-image', 'px-1')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1"
						/>
					</hook>
					<hook zone="offerHorizontal">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>
					<hook zone="event-iconHorizontal">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="30px" />
					</hook>
				</div>

				<div
					class="col col-7 py-0 pl-1 d-flex flex-column justify-center"
					:class="{ 'no-stock': outOfStock }"
				>
					<hook zone="priceHorizontal">
						<PriceText
							:amount="price"
							:currency="currency"
							:class="$bem('__price')"
							class="price font-6 line-clamp-1 px-2"
						/>
					</hook>

					<div
						class="d-flex align-center pb-2 line-clamp-1 px-2"
						:class="$bem('__prev-price')"
						v-if="prevPrice"
					>
						<hook zone="prevPriceHorizontal">
							<del class="grey--text">
								<PriceText
									:amount="prevPrice"
									:currency="currency"
									:currencyMargin="0"
									priceClass="grey--text font-2"
								/>
							</del>
						</hook>

						<hook zone="discountHorizontal">
							<span
								:class="$bem('__discount')"
								class="d-inline-block discount font-2"
								v-if="discountPct > 0"
							>
								{{ discountPct }}% OFF
							</span>
						</hook>
					</div>

					<hook zone="attrs&financingTagsHorizontal" :class="{ 'no-stock': outOfStock }">
						<div
							:class="[
								$bem('__financing', 'font-0 line-clamp-1 mx-2 font-weight-bold primary py-1'),
								{ 'mt-2': !discountPct },
							]"
							v-if="financingTagText"
						>
							{{ financingTagText }}
						</div>
					</hook>

					<router-link class="card-anchor header" :to="productRoute">
						<hook zone="nameHorizontal" :class="{ 'no-stock': outOfStock }">
							<div
								:class="$bem('__name')"
								class="line-clamp-2 font-1 px-2 header font-weight-regular text-start"
							>
								{{ product.name }}
							</div>
						</hook>
					</router-link>
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF HORIZONTAL CARD -->
</template>

<style lang="scss" scoped>
.product-card-left-aligned-small-wrapper {
	min-height: 270px;
	width: 180px;
	min-width: 100px;
}

.product-card-left-aligned-small-wrapper-mobile {
	width: 170px;
}

.product-card-left-aligned-small {
	position: relative;
	cursor: pointer;
	border: 1px solid #eaeaea;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__offer {
		position: absolute;
		bottom: 0;
	}

	&__event-icon {
		position: absolute;
		bottom: 10%;
		left: 0;
		width: 25px;
	}

	&__spacer--flex-grow {
		flex-grow: 0.75 !important;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}
}

.product-card-left-aligned-small-horizontal {
	height: 200px;

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__main-image {
		left: 50%;
		transform: translateX(-50%);
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__event-icon {
		width: 25px;
		position: absolute;
		top: 7px;
		left: 6px;
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__offer {
		position: absolute;
		bottom: 0;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}
}

.no-stock {
	opacity: 0.4;
}

a.card-anchor:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 2;
	left: 0;
}

::v-deep .product-card-left-aligned-small__image--smallWidth-mobile {
	max-width: 170px;
	max-height: 170px;
	left: 50% !important;
	transform: translateX(-50%) !important;
}

::v-deep .product-card-left-aligned-small__image--smallWidth {
	max-width: 180px;
	max-height: 180px;
	left: 50% !important;
	transform: translateX(-50%) !important;
}
</style>
