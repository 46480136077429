<script>
import { sync, get } from 'vuex-pathify'

export default {
	computed: {
		showWhatsappChat: sync('shop/showWhatsappChat'),
		getWhatsappConfig: get('shop/getWhatsappConfig'),
		hasAgents() {
			return !!this.getWhatsappConfig?.length
		},
	},
}
</script>

<template>
	<div v-if="showWhatsappChat">
		<UrlMatch v-for="(data, n) in getWhatsappConfig.config" :data="data" :key="n">
			<transition name="fade">
				<div class="chat px-1" :class="[!$b.m && data.position]" v-if="data.agents && data.agents.length">
					<div class="chat__header d-flex align-center justify-space-between px-6 py-3">
						<div class="d-flex align-center font-2">
							<v-icon large color="white" class="mr-2">mdi-whatsapp</v-icon>WhatsApp
						</div>
						<div>
							<v-btn fab small depressed color="rgba(0,0,0,.4)" @click="showWhatsappChat = false">
								<v-icon color="white"> mdi-close </v-icon>
							</v-btn>
						</div>
					</div>
					<div class="chat__content px-3 py-1">
						<div class="chat__message pa-5 ma-3" style="white-space: pre-line">
							{{ data.message }}
						</div>
						<SafeLink
							class="chat__agents d-flex align-center justify-space-between pr-6 my-2"
							v-for="({ name, avatar, phone }, n) in data.agents"
							:key="n"
							:to="`https://api.whatsapp.com/send/?phone=${phone}&text&type=phone_number&app_absent=0`"
						>
							<Media class="chat__avatar" :src="avatar" width="60" height="60" img-class="round-img" />
							<div>Hablar con {{ name }}</div>
							<v-icon large color="white">mdi-send-outline</v-icon>
						</SafeLink>
					</div>
				</div>
			</transition>
		</UrlMatch>
	</div>
</template>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}

::v-deep .round-img {
	border-radius: 50%;
}

.chat {
	position: fixed;
	z-index: 3;
}

.left {
	left: 20px;
}

.right {
	right: 20px;
}

.chat {
	--link: white;
	bottom: 10px;
	max-width: 400px;
	width: 100%;
	border-radius: 15px;

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		left: 50%;
		transform: translate(-50%);
	}

	&__header {
		border-radius: 15px 15px 0 0;
		background-color: #00b398;
		color: white;
		display: flex;
	}

	&__content {
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
		background: #fff linear-gradient(0deg, #00b3980a, #00b3980a);
		border-radius: 0 0 15px 15px;
	}

	&__message {
		border-radius: 32px;
		background: #fff;
		color: #333;
		filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.3));

		&::before {
			content: '';
			display: block;
			position: absolute;
			bottom: 20px;
			left: -15px;
			width: 17px;
			height: 25px;
			background: inherit;
			clip-path: polygon(100% 19%, 0 20%, 100% 65%);
		}
	}

	&__agents {
		background: #00b398;
		color: white;
		border-radius: 60px;
		height: 60px;
		transition: background 0.2s linear;

		&:hover {
			background: #32e5ca;
		}
	}

	&__avatar {
		&::before {
			content: '';
			display: block;
			position: absolute;
			bottom: 4px;
			left: 4px;
			z-index: 1;
			width: calc(var(60px) / 5);
			height: calc(var(60px) / 5);
			min-width: 10px;
			min-height: 10px;
			border: 2px solid #fff;
			border-radius: 50%;
			background-color: #25d366;
			box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.24);
		}
	}
}
</style>
