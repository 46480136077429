import isEqual from 'lodash/isEqual'

export default (modelType = [String, Number, Array], defaultValue = undefined) => {
	if (!Array.isArray(modelType)) modelType = [modelType]
	const props = { value: modelType }
	const isNumeric = modelType.includes(Number)
	const canBeMultiple = modelType.includes(Array)
	if (canBeMultiple) props.multiple = Boolean

	return {
		props,
		model: {
			prop: 'value',
			event: 'updateModel',
		},
		data() {
			return {
				iValue: defaultValue === undefined ? null : defaultValue,
			}
		},
		watch: {
			value: {
				deep: true,
				handler(value) {
					value = this.normalizeModelValue(value)
					if (!isEqual(value, this.iValue)) {
						this.iValue = value
					}
				},
			},
			iValue: {
				deep: true,
				handler(value) {
					this.$emit('updateModel', value)
				},
			},
		},
		methods: {
			normalizeModelValue(v) {
				if (canBeMultiple && this.multiple) {
					if (v === undefined || v === null || v === '') return []
					if (!Array.isArray(v)) v = [v]
					return v.map((v) => (isNumeric ? Number(v) : v))
				} else {
					if (v === undefined || v === null || v === '')
						return defaultValue === undefined ? null : defaultValue
					return isNumeric ? Number(v) : v
				}
			},
		},
		created() {
			this.iValue = this.normalizeModelValue(this.value)
		},
	}
}
