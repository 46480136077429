export default (srv, window) => {
	let id = srv('Analytics.gaId')

	let secondaryIds = srv('Analytics.gaSecondaryIds') || []
	if (!id) return null

	let s = document.createElement('script')
	s.async = true
	s.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
	document.body.appendChild(s)
	window.dataLayer = window.dataLayer || []
	window.gtag = function () {
		window.dataLayer.push(arguments)
	}
	window.gtag('js', new Date())
	window.gtag('set', {
		custom_map: {
			dimension1: 'device_type',
			dimension2: 'site_context',
		},
	})

	let configParams = {}
	configParams.send_page_view = false

	switch (srv('deviceType')) {
		case 'phone':
			configParams.device_type = 'mobile'
			break
		case 'tablet':
			configParams.device_type = 'tablet'
			break
		default:
			configParams.device_type = 'desktop'
			break
	}

	let contextName = srv('contextName')
	if (contextName) configParams.site_context = contextName

	if (srv('Analytics.gaDebugViewEnabled')) {
		configParams.debug_mode = true
	}

	window.gtag('config', id, configParams)
	for (let secId of secondaryIds) {
		window.gtag('config', secId, configParams)
	}

	return window.gtag
}
