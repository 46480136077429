<template>
	<ul
		class="d-flex justify-center justify-md-start align-center pa-0 list-style-none flex-wrap"
		:class="$b({ smAndDown: true }) && 'flex-wrap'"
	>
		<li v-for="(cert, i) in items" :key="i">
			<SafeLink class="white--text d-flex pa-1" :to="cert.link">
				<Media :src="cert.image" as="img" style="width: auto; height: 60px" />
			</SafeLink>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'comp_FooterCertificates',
	props: {
		items: Array,
	},
}
</script>

<style scoped lang="scss">
.list-style-none {
	list-style: none;
}
</style>
