<template>
	<div class="pa-3" fluid v-if="$b.d && showTopAppBar">
		<v-row justify="space-between" class="secondary">
			<v-col cols="auto">
				<v-icon class="mx-3 secondary"> {{ icon }}</v-icon>
				<span class="font-1 secondary">{{ message }}</span>
			</v-col>
			<v-col cols="auto" v-if="links.length" class="pr-2 d-flex justify-end">
				<SafeLink class="mr-2 secondary" v-for="(link, i) in links" :to="link.url" :key="i">
					| {{ link.text }}
				</SafeLink>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import appbarMixin from '@/v-shop/appbarMixin'

export default {
	hookZone: ['top'],
	mixins: [appbarMixin],
	data() {
		return {
			showTopAppBar: this.$srv('AppBarConfig.showTopNavbar', false),
			message: this.$srv('appbarTopMessage.message', ''),
			icon: this.$srv('appbarTopMessage.icon', ''),
			links: this.$srv('appbarTopMessage.links', []),
		}
	},
}
</script>
