<script>
export default {
	lang: 'login',
	name: 'LoginForm',
	data() {
		return {
			loginForm: {},
			loginError: false,
			loading: false,
			showPassword: false,
		}
	},
	methods: {
		submitLogin() {
			this.loading = true
			this.loginError = false
			this.$shopApi.post({
				url: '/user/signin',
				data: {
					email: this.loginForm.email,
					password: this.loginForm.password,
				},
				loading: (v) => (this.loading = v),
				// onMessage: null,
				done: ({ data, success }) => {
					if (success) {
						this.loginForm = {}
						let { loginMethod, user } = data
						this.$store.set('shop/user', user)
						this.$store.set('shop/loginDrawer', false)
						this.$eventer().trigger('user:signin', { loginMethod, user })
					} else {
						this.loginError = true
					}
				},
			})
		},
		openForgot() {
			this.$emit('open-form', 'forgot')
		},
		openSignup() {
			this.$emit('open-form', 'signup')
		},
	},
}
</script>

<template>
	<div class="base">
		<ThirdPartyLoginsButtons />
		<div class="py-4">
			<div class="font-weight-bold pb-4">{{ 'Con tu e-mail' | lang }}</div>
			<v-form @submit.prevent="submitLogin">
				<v-row>
					<v-col cols="12">
						<TextField v-model="loginForm.email" name="email" :label="$lang('E-mail')" />
					</v-col>
					<v-col cols="12">
						<TextField
							v-model="loginForm.password"
							:label="$lang('Contraseña')"
							name="password"
							:type="showPassword ? 'text' : 'password'"
							:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							@click:append="showPassword = !showPassword"
						/>
					</v-col>
					<v-col cols="12" v-if="loginError">
						<b class="error--text">{{ 'El usuario o contraseña son incorrectos' | lang }}</b>
					</v-col>
					<v-col cols="12" class="d-flex align-center flex-wrap">
						<Button :loading="loading" color="success" type="submit">
							{{ 'Ingresar' | lang }}
						</Button>
						<v-spacer />
						<Button text small @click="openForgot" color="link">
							{{ 'Olvidé mi contraseña' | lang }}
						</Button>
					</v-col>
				</v-row>
			</v-form>
		</div>
		<div class="py-4 text-center">
			<div class="font-weight-bold pb-4">{{ '¿No tienes cuenta?' | lang }}</div>
			<div>
				<Button color="link" @click="openSignup">
					{{ 'Crear Nueva Cuenta' | lang }}
				</Button>
			</div>
		</div>
	</div>
</template>
