<template>
	<div>
		<div v-if="option.toAddress">
			{{ address }}
		</div>
		<div v-if="hasOpeningHours">
			<div v-for="(h, n) in openingHours" :key="n">
				{{ h }}
			</div>
		</div>
		<div>
			{{ option.data.email }}
			{{ option.data.phone }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		option: Object,
	},
	computed: {
		openingHours() {
			return this.option.data.openingHours
		},
		hasOpeningHours() {
			return !!this.openingHours
		},
		address() {
			const toAddress = this.option.toAddress
			return toAddress.street + ' ' + toAddress.streetNumber + ', ' + toAddress.city
		},
	},
}
</script>

<style></style>
