<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],

	props: {
		position: String,
	},

	hooksKey: 'ProductCard',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div class="pb-2 h100" v-if="verticalCard">
		<v-hover v-slot="{ hover }">
			<v-card
				:hover="!outOfStock ? true : false"
				flat
				@click="cardClick"
				:ripple="false"
				:class="[
					$bem('product-card-left-aligned-with-timer-vertical'),
					{
						'product-card-left-aligned-with-timer-vertical__positionRight': position == 'right',
						'product-card-left-aligned-with-timer-vertical__positionLeft': position == 'left',
					},
				]"
				class="h100 d-flex flex-column overflow-hidden base product-card"
				@mouseover="firstImageHover = true"
			>
				<div :class="$bem('__image-cont')" v-if="!outOfStock">
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
						<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont')">
							<Media
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
							/>
						</div>
					</hook>

					<hook zone="event-icon">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
					</hook>

					<hook zone="offer" v-if="!timerProduct">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>
				</div>

				<div :class="$bem('__image-cont', 'mx-4 no-stock')" v-else>
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
					</hook>

					<hook zone="event-icon">
						<CollectionEventIcon :product="product" :class="[$bem('__event-icon'), 'ml-2']" />
					</hook>

					<hook zone="offer" v-if="!timerProduct">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>
				</div>

				<hook zone="price" :class="{ 'no-stock': outOfStock }">
					<PriceText
						:amount="price"
						:currency="currency"
						:class="[$bem('__price'), { 'no-stock': outOfStock }, timerProduct ? 'font-6' : 'font-7']"
						class="price mt-6 line-clamp-1 px-4"
					/>
				</hook>

				<div
					class="d-flex align-center pb-2 line-clamp-1 px-4"
					:class="[$bem('__prev-price'), { 'no-stock': outOfStock }]"
					v-if="prevPrice"
				>
					<hook zone="prevPrice">
						<del class="grey--text">
							<PriceText
								:amount="prevPrice"
								:currency="currency"
								:currencyMargin="0"
								:priceClass="timerProduct ? 'font-2 grey--text' : 'font-3 grey--text'"
							/>
						</del>
					</hook>

					<hook zone="discount">
						<span
							:class="[$bem('__discount'), timerProduct ? 'font-2' : 'font-3']"
							class="d-inline-block discount"
							v-if="discountPct > 0"
						>
							{{ discountPct }}% OFF
						</span>
					</hook>
				</div>

				<hook zone="attrs&financingTags" :class="{ 'no-stock': outOfStock }">
					<div
						v-if="financingTagText"
						:class="[
							$bem('__financing'),
							{ 'my-2': !discountPct && !hasTextTag, 'mt-2': !discountPct && hasTextTag },
							timerProduct ? 'font-0' : 'font-1',
						]"
						class="line-clamp-1 px-4 font-weight-bold primary text-start"
					>
						{{ financingTagText }}
					</div>
				</hook>

				<v-spacer />

				<router-link class="card-anchor header" :to="productRoute">
					<hook zone="name" :class="{ 'no-stock': outOfStock }">
						<div
							:class="[$bem('__name'), timerProduct ? 'font-1' : 'font-2']"
							class="line-clamp-2 px-4 header font-weight-regular text-start"
						>
							{{ product.name }}
						</div>
					</hook>
				</router-link>

				<hook zone="brand" v-if="brand && !timerProduct" :class="{ 'no-stock': outOfStock }">
					<div :class="$bem('__brand')" class="font-0 line-clamp-1 px-4 mb-4">
						{{ brand }}
					</div>
				</hook>

				<div class="pa-3">
					<hook zone="btn">
						<v-btn class="cta w100 rounded-md" :disabled="buttonText == 'Sin stock'">
							{{ buttonText | lang }}
						</v-btn>
					</hook>
				</div>

				<hook zone="offerTimerProduct" :class="{ 'no-stock': outOfStock }" v-if="timerProduct">
					<div :class="[$bem('__offerTimerProduct'), 'base pt-1 mt-5']">
						<LimitedTimeOfferCountdown :limitedTimeOffer="limitedTimeOffer" timerCard class="mt-3" />
						<div class="mt-2 pb-2">
							<p
								class="text-uppercase text-center line-clamp-3 mb-0 font-weight-medium py-1 px-2 rounded font-0"
							>
								{{ limitedTimeOfferDate }}
							</p>
						</div>
					</div>
				</hook>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD FOR MOBILE -->
	<div class="w100" v-else>
		<v-card
			flat
			@click="cardClick"
			:ripple="false"
			:class="$bem('product-card-left-aligned-with-timer-horizontal')"
			class="d-flex overflow-hidden base product-card pt-2 flex-column"
		>
			<div class="d-flex overflow-hidden py-2">
				<div :class="[$bem('__image-cont', 'col col-5 pa-0'), { 'no-stock': outOfStock }]">
					<hook zone="imageHorizontal">
						<Media
							:class="$bem('__main-image', 'px-1')"
							:src="mainImage"
							:width="$b.m ? '100%' : '200px'"
							:maxHeight="$b.t ? '200px' : ''"
						/>
					</hook>

					<hook zone="offerHorizontal" v-if="!timerProduct">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>

					<hook zone="event-iconHorizontal" v-if="$b.m">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="30px" />
					</hook>
				</div>

				<div
					class="col col-7 py-0 pl-1 d-flex flex-column justify-center"
					:class="{ 'no-stock': outOfStock }"
				>
					<hook zone="priceHorizontal">
						<PriceText
							:amount="price"
							:currency="currency"
							:class="[$bem('__price'), timerProduct ? 'font-6' : 'font-7']"
							class="price line-clamp-1 px-2"
						/>
					</hook>

					<div
						class="d-flex align-center pb-2 line-clamp-1 px-2"
						:class="$bem('__prev-price')"
						v-if="prevPrice"
					>
						<hook zone="prevPriceHorizontal">
							<del class="grey--text">
								<PriceText
									:amount="prevPrice"
									:currency="currency"
									:currencyMargin="0"
									:priceClass="timerProduct ? 'font-2 grey--text' : 'font-3 grey--text'"
								/>
							</del>
						</hook>

						<hook zone="discountHorizontal">
							<span
								:class="[$bem('__discount'), timerProduct ? 'font-2' : 'font-3']"
								class="d-inline-block discount ml-1"
								v-if="discountPct > 0"
							>
								{{ discountPct }}% OFF
							</span>
						</hook>
					</div>

					<hook zone="attrs&financingTagsHorizontal" :class="{ 'no-stock': outOfStock }">
						<div
							:class="[
								$bem('__financing', 'line-clamp-1 mx-2 font-weight-bold primary py-1'),
								{ 'mt-2': !discountPct },
								timerProduct ? 'font-0' : 'font-1',
							]"
							v-if="financingTagText"
						>
							{{ financingTagText }}
						</div>
					</hook>

					<router-link class="card-anchor header" :to="productRoute">
						<hook zone="nameHorizontal">
							<div
								:class="$bem('__name')"
								class="line-clamp-2 font-1 px-2 header font-weight-regular text-start"
							>
								{{ product.name }}
							</div>
						</hook>
					</router-link>

					<hook zone="brandHorizontal" v-if="brand && !timerProduct">
						<div :class="$bem('__brand')" class="font-0 line-clamp-1 px-2 mb-4">
							{{ brand }}
						</div>
					</hook>

					<hook zone="event-iconHorizontal" v-if="$b.t">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="40px" />
					</hook>
				</div>
			</div>

			<div class="px-2 pb-4">
				<hook zone="btnHorizontal">
					<v-btn class="cta w100 rounded-md" :disabled="buttonText == 'Sin stock'" small>
						{{ buttonText | lang }}
					</v-btn>
				</hook>
			</div>

			<hook zone="offerTimerProductHorizontal" v-if="timerProduct">
				<div :class="[$bem('__offerTimerProduct'), 'base pt-1']">
					<LimitedTimeOfferCountdown :limitedTimeOffer="limitedTimeOffer" timerCard class="mt-2" />
					<div class="mt-2">
						<p
							class="text-uppercase line-clamp-3 mb-0 font-weight-medium pt-1 pb-2 px-2 rounded font-0 text-center"
						>
							{{ limitedTimeOfferDate }}
						</p>
					</div>
				</div>
			</hook>
		</v-card>
	</div>
	<!-- END OF HORIZONTAL CARD FOR MOBILE -->
</template>

<style lang="scss" scoped>
.product-card-left-aligned-with-timer-vertical {
	position: relative;
	cursor: pointer;
	justify-content: space-between;
	// border-bottom: 15px solid var(--secondary);

	// &__timerProduct {
	// 	border-bottom: transparent;
	// }

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__event-icon {
		position: absolute;
		bottom: -7px;
		left: 10px;
		width: 40px;
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__offerTimerProduct {
		border-top: 2px solid var(--offertext);
	}

	&__offer {
		position: absolute;
		bottom: 0;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}

	&__positionRight {
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		// border-left: 6px solid var(--secondary);
	}

	&__positionLeft {
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		// border-right: 6px solid var(--secondary);
	}
}

.product-card-left-aligned-with-timer-horizontal {
	// border-left: 4px solid var(--secondary);

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__main-image {
		left: 50%;
		transform: translateX(-50%);
	}

	&__event-icon {
		position: absolute;
	}

	@media (min-width: 601px) {
		&__event-icon {
			width: 40px;
			top: 20px;
			right: 20px;
		}
	}

	@media (max-width: 600px) {
		&__event-icon {
			width: 30px;
			top: -10px;
			left: 10px;
		}
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__offerTimerProduct {
		border-top: 2px solid var(--offertext);
	}

	&__offer {
		position: absolute;
		bottom: 0;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}
}

.no-stock {
	opacity: 0.4;
}

a.card-anchor:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 2;
	left: 0;
}
</style>
