<template>
	<div class="categories-list primary pa-0 rounded">
		<div v-for="({ category: { name, children, id } }, i) of items" :key="id">
			<router-link :to="''" :key="i">
				<v-hover v-slot="{ hover }">
					<div
						class="categories-list__item pa-2 pl-4 d-flex justify-space-between"
						:class="{
							'rounded-bl rounded-br': i === items.length - 1,
							'rounded-tl rounded-tr': i === 0,
							'on-hover': hover || hoverSubmenu === id,
						}"
						@mouseover="$emit('mouseover', id)"
						:input-value="id === hoverSubmenu"
					>
						<div class="categories-list__title font-weight-bold">
							{{ name }}
						</div>
						<v-icon
							v-show="children.length > 0"
							size="small"
							:color="hover || hoverSubmenu === id ? $vars.basetext : $vars.primarytext"
							class="pr-2"
						>
							mdi-chevron-right
						</v-icon>
					</div>
				</v-hover>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		items: Array,
		hoverSubmenu: [Number, Boolean],
	},
	inject: {
		appbar: {
			default: null,
		},
	},
	computed: {
		isRound() {
			return this.appbar?.config.type === 'round'
		},
		classes() {
			return this.isRound ? '' : 'rounded'
		},
	},
}
</script>

<style lang="scss" scoped>
.categories-list {
	height: 498px;
	&__item {
		min-height: 30px;
		color: var(--primarytext);
	}

	&__title {
		font-size: 14px;
	}
}

.v-list-item__title {
	color: unset !important;
}

.on-hover {
	background-color: var(--base);
	color: var(--basetext) !important;
}
</style>
