<script>
export default {
	props: {
		hideIcons: Boolean,
		noBgItem: Boolean,
		dense: Boolean,
	},
	data() {
		return {
			menuItems: this.$srv('menuLinks'),
			show: this.$srv('AppBarConfig.showLinks'),
		}
	},
}
</script>

<template>
	<div v-if="show && menuItems.length">
		<v-menu
			bottom
			open-on-hover
			offset-y
			:disabled="!item.children"
			v-for="(item, index) of menuItems"
			:key="index"
			class="px-0"
			min-width="200px"
			attach="#appbar-menu"
		>
			<v-list :color="$vars.primary">
				<v-list-item class="primary" link v-for="subitem of item.children" :key="subitem.id">
					<SafeLink :to="subitem.link" class="w100">
						<v-list-item-title>
							{{ subitem.name }}
						</v-list-item-title>
					</SafeLink>
				</v-list-item>
			</v-list>
			<template #activator="{ on, attrs }">
				<SafeLink :to="item.link" class="d-inline-block h100 d-flex">
					<v-btn
						v-bind="attrs"
						v-on="on"
						class="h100 primary"
						:style="[
							{ background: noBgItem && 'transparent!important' },
							{ padding: dense && '0 3px' },
						]"
						text
						depressed
						small
					>
						<v-icon v-if="!hideIcons" :color="$vars.primarytext">{{ item.icon }}</v-icon>
						<span class="ml-2 font-1" :class="dense && 'text-transform-none'">
							{{ item.name }}
						</span>
						<span v-if="!!item.children">
							<v-icon :color="$vars.primarytext"> mdi-chevron-down </v-icon>
						</span>
					</v-btn>
				</SafeLink>
			</template>
		</v-menu>
	</div>
</template>

<style scoped lang="scss">
.text-transform-none {
	text-transform: none;
}
</style>
